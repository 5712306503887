import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { ThemeConstants } from 'src/app/@espire/shared/config/theme-constant';
import { RespuestaGenerica } from 'src/app/generales/RespuestaGenerica';
import { ServiciosRutas } from 'src/app/generales/ServiciosRutas';
import { AuthGuard } from 'src/app/guards/AuthGuard';
import { Lista_proveedores_modelo_response } from 'src/model/Catalogos/Proveedores';
import { ServiceGenerico } from 'src/services/service-generico.service';

@Component({
  selector: 'app-listado-proveedores',
  templateUrl: './listado-proveedores.component.html',
  styleUrls: ['./listado-proveedores.component.css'],
  providers: [ServiceGenerico]
})
export class ListadoProveedoresComponent implements OnInit {
  @ViewChild(DataTableDirective, { static: false })
  dtElementH: DataTableDirective;
  dtOptionsProv: DataTables.Settings = {};
  dtTriggerH: Subject<any> = new Subject();
  isDtInitializedH: boolean = false;

  @Output() campoHabilitado = new EventEmitter<number>();

  private modelo_configuracion: ServiciosRutas;
  public operacionRespuesta: RespuestaGenerica;
  listaProveedores: Lista_proveedores_modelo_response[];
  esEditable: boolean;
  // listadoTarifa: number = 0;
  funcionalidad: string;
  _bndEsEditable: boolean;


  constructor(
    private auth: AuthGuard,
    private themeConstantsH: ThemeConstants,
    private services: ServiceGenerico,
    private router: Router,
    private modalService: NgbModal,
    private fb: FormBuilder,
    private sanitizer: DomSanitizer,
  ) {
    this.modelo_configuracion = new ServiciosRutas();
    this.operacionRespuesta = new RespuestaGenerica();
  }

  ngOnInit() {
    this.inicializaTabla();
    this.obtenerProveedoresH();
  }

  inicializaTabla() {
    this.dtOptionsProv = this.themeConstantsH.dtOptionsProv;

  }

  renderTablaH() {
    if (!this.isDtInitializedH) {
      if (this.dtElementH && this.dtElementH.dtInstance) {
        this.dtElementH.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
          this.dtTriggerH.next();
        });
      } else {
        this.dtTriggerH.next();
      }
      this.isDtInitializedH = true;
    }
  }

  obtenerProveedoresH() {
    this.operacionRespuesta.EstaEjecutando = true;
    //Tipo proveedor 1 Hospedaje
    this.services
      .HttpGet(
        this.modelo_configuracion.serviciosOperaciones + "/Proveedores/GetListaProveedores?p_idTipoProveedor=1")
      .subscribe(
        (tempdate) => {
          if (tempdate) {
            this.listaProveedores = [] = tempdate.response as Lista_proveedores_modelo_response[];
            this.renderTablaH();
          } else {
            this.listaProveedores = [];
            this.renderTablaH();
          }
          this.operacionRespuesta.EstaEjecutando = false;
        },
        async (err) => {
          this.operacionRespuesta.EstaEjecutando = false;
        }
      );
  }

  listadoTarifas(_idProveedor: number, tipo: string) {
    this.campoHabilitado.emit(_idProveedor);
  }


}
