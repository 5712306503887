import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { ThemeConstants } from 'src/app/@espire/shared/config/theme-constant';
import { RespuestaGenerica } from 'src/app/generales/RespuestaGenerica';
import { ServiciosRutas } from 'src/app/generales/ServiciosRutas';
import { AuthGuard } from 'src/app/guards/AuthGuard';
import { ModuloModalMensajeComponent } from 'src/app/shared/modulo-modal-mensaje/modulo-modal-mensaje.component';
import { Lista_usuarios_sistema_modelo_response } from 'src/model/Catalogos/Colaboradores';
import { Cupon_hospedaje_modelo_response, Lista_cupones_hospedaje_modelo_response, Lista_idioma_modelo_response, Lista_proveedores_modelo_response, Lista_temp_servicios_modelo_response, Lista_temp_servicios_pdf_modelo_response, Lista_tipo_habitacion_modelo_response, Lista_tipo_servicio_modelo_response, Lista_tipo_transporte_modelo_response } from 'src/model/Catalogos/Cupones';
import { ServiceGenerico } from 'src/services/service-generico.service';
import * as html2pdf from 'html2pdf.js';
import { AuthIdentity } from 'src/app/guards/AuthIdentity';

@Component({
  selector: 'app-transportadora',
  templateUrl: './transportadora.component.html',
  styleUrls: ['./transportadora.component.css'],
  providers: [ServiceGenerico]
})
export class TransportadoraComponent implements OnInit {
  @ViewChild("modalAddCouponTransportadora", { static: false }) modalAddCouponTransportadora: TemplateRef<any>;
  @ViewChild("contentDesactivar", { static: false }) ModalDesactivar: TemplateRef<any>;
  @ViewChild("contentInhabilitar", { static: false }) ModalInhabilitar: TemplateRef<any>;
  @ViewChild("contentActivar", { static: false }) ModalActivar: TemplateRef<any>;
  @ViewChild("contentAgregarServicioTransportadora", { static: false }) ModalAgregarTransportadora: TemplateRef<any>;
  @ViewChild("contentAutorizar", { static: false }) ModalAutorizar: TemplateRef<any>;
  @ViewChild("contentCuponPDF", { static: false }) ModalCuponPDF: TemplateRef<any>;
  modalrefMsg: NgbModalRef;
  modalAgregarCuponTransportadora: NgbModalRef;
  modalEliminarCupon: NgbModalRef;
  modalInhabilitarCupon: NgbModalRef;
  modalActivarCupon: NgbModalRef;
  modalAgregarServicioTransportadora: NgbModalRef;
  modalAutorizarCupon: NgbModalRef;
  modalCuponPDF: NgbModalRef;

  formGroupAgregarCuponTransportadora: FormGroup;
  formGroupAgregarServicioTransportadora: FormGroup;

  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  isDtInitialized: boolean = false;
  public archivos: any = [];
  public usuarioId: string = "";

  private modelo_configuracion: ServiciosRutas;
  listaCupones: Lista_cupones_hospedaje_modelo_response[];
  Colaborador: Cupon_hospedaje_modelo_response;
  public operacionRespuesta: RespuestaGenerica;
  esEditable: boolean = true;
  funcionalidad: string;
  _idCatCupon: number;
  private _nombreCup: any;
  listaProveedores: Lista_proveedores_modelo_response[];
  listaTipoHabitacion: Lista_tipo_habitacion_modelo_response[];
  listaUsuarios: Lista_usuarios_sistema_modelo_response[];
  fechaEntrada: Date;
  fechaSalida: Date;
  pdfCupon: Cupon_hospedaje_modelo_response;
  listaUsuariosRealiza: Lista_usuarios_sistema_modelo_response[];
  listaUsuariosRevisa: Lista_usuarios_sistema_modelo_response[];
  listaUsuariosAutoriza: Lista_usuarios_sistema_modelo_response[];
  idUsuario: number;
  idUsuarioElabora: number = 0;
  idUsuarioReviso: number = 0;
  idUsuarioAutorizo: number = 0;
  puedeEditar: boolean = false;
  numbreAutorizo: string;
  listaTipotransporte: Lista_tipo_transporte_modelo_response[];
  listaTiposervicio: Lista_tipo_servicio_modelo_response[];
  listaIdioma: Lista_idioma_modelo_response[];
  listServiciosTempTransportadora: Lista_temp_servicios_modelo_response[] = [];
  accionServicio: string = 'Nuevo';
  _index: number;
  listServiciosTemppdf: Lista_temp_servicios_pdf_modelo_response[];
  selectedCupones: string[];
  activarAutorizado: boolean = false; 

  filteredProveedores: Lista_proveedores_modelo_response[] = [];
  searchText = '';

  constructor(
    private auth: AuthGuard,
    private themeConstants: ThemeConstants,
    private services: ServiceGenerico,
    private router: Router,
    private modalService: NgbModal,
    private fb: FormBuilder,
    private sanitizer: DomSanitizer,
  ) {
    this.modelo_configuracion = new ServiciosRutas();
    this.operacionRespuesta = new RespuestaGenerica();
  }

  ngOnInit() {
    this.inicializaTabla();
    this.obtenerCupones();
    this.inicializarFormAgregarCupon();
    this.inicializarFormAgregarServicio();
    this.getListaProveedores();
    this.getListaTipoTransporte();
    this.getListaTipoServicio();
    this.getListaIdioma();
    this.obtenerUsuarios();

    var usuarioActual = AuthIdentity.ObtenerUsuarioSesion();
    this.idUsuario = usuarioActual.IDUsuario;
  }

  inicializaTabla() {
    this.dtOptions = this.themeConstants.dtOptions;
    this.dtTrigger = new Subject();
  }

  renderTabla() {
    if ("dtInstance" in this.dtElement) {
      this.dtElement.dtInstance.then((instancia: DataTables.Api) => {
        instancia.destroy();
        this.dtTrigger.next();
      });
    } else {
      this.dtTrigger.next();
    }
  }

  validateTransportadora(control) {
    if (control.value === 0) {
      return { 'invalidValue': true };
    }
    return null;
  }

  obtenerCupones() {
    this.operacionRespuesta.EstaEjecutando = true;
    this.services
      .HttpGet(
        this.modelo_configuracion.serviciosOperaciones + "/Cupones/GetListaCupones?p_opcion=2")
      .subscribe(
        (tempdate) => {
          if (tempdate) {
            this.listaCupones = [] = tempdate.response as Lista_cupones_hospedaje_modelo_response[];
            this.renderTabla();
          } else {
            this.listaCupones = [];
            this.renderTabla();
          }
          this.operacionRespuesta.EstaEjecutando = false;
        },
        async (err) => {
          this.operacionRespuesta.EstaEjecutando = false;
        }
      );
  }

  inicializarFormAgregarCupon() {
    this.formGroupAgregarCuponTransportadora = this.fb.group({
      p_IDCupon: [0],
      p_File: ["", Validators.required],
      p_FechaRegistro: [""],
      p_NombrePax: ["", Validators.required],
      p_NumAdulto: [0],
      p_NumMenores: [0],
      p_NumBebes: [0],
      p_Comentarios: [""],
      p_Elaboro: [0, Validators.required],
      p_Reviso: [0],
      p_Autorizo: [0],
      p_IDProveedor: [0, this.validateTransportadora],
      p_Cortesias: [""],
      searchText: ''
    });

  }
  inicializarFormAgregarServicio() {
    this.formGroupAgregarServicioTransportadora = this.fb.group({
      p_Fecha: [""],
      p_Tipo: [0],
      p_TipoServicio: [0],
      p_Idioma: [0],
      p_Servicio: [""],
      p_ComentarioServicio: [""],
    });

  }

  getListaProveedores() {
    this.operacionRespuesta.EstaEjecutando = true;
    this.services
      .HttpGet(
        this.modelo_configuracion.serviciosOperaciones + "/Generales/GetListaProveedoresCorto?p_tipoProveedor=2")
      .subscribe(
        (tempdate) => {
          if (tempdate) {
            this.listaProveedores = tempdate.response as Lista_proveedores_modelo_response[];
          }
          this.operacionRespuesta.EstaEjecutando = false;
        },
        async (err) => {
          this.operacionRespuesta.EstaEjecutando = false;
        }
      );
  }

  filterProveedores(): void {
    this.searchText = this.formGroupAgregarCuponTransportadora.get('searchText').value;
    this.filteredProveedores = this.listaProveedores.filter(proveedor => proveedor && proveedor.nombre && proveedor.nombre.toLowerCase().includes(this.searchText.toLowerCase()));
    }
  

  clearSearch(): void {
    this.searchText = '';
    this.formGroupAgregarCuponTransportadora.get('searchText').setValue('');
    this.filteredProveedores = this.listaProveedores;
  }


  getListaTipoTransporte() {
    this.operacionRespuesta.EstaEjecutando = true;
    this.services
      .HttpGet(
        this.modelo_configuracion.serviciosOperaciones + "/Catalogos/GetListaGeneralCorto?p_catalogo=TIPOTRANSPORTE&p_idcatalogo=1")
      .subscribe(
        (tempdate) => {
          if (tempdate) {
            this.listaTipotransporte = tempdate.response as Lista_tipo_transporte_modelo_response[];
          }
          this.operacionRespuesta.EstaEjecutando = false;
        },
        async (err) => {
          this.operacionRespuesta.EstaEjecutando = false;
        }
      );
  }

  getListaTipoServicio() {
    this.operacionRespuesta.EstaEjecutando = true;
    this.services
      .HttpGet(
        this.modelo_configuracion.serviciosOperaciones + "/Catalogos/GetListaGeneralCorto?p_catalogo=TIPOSERVICIO&p_idcatalogo=1")
      .subscribe(
        (tempdate) => {
          if (tempdate) {
            this.listaTiposervicio = tempdate.response as Lista_tipo_servicio_modelo_response[];
          }
          this.operacionRespuesta.EstaEjecutando = false;
        },
        async (err) => {
          this.operacionRespuesta.EstaEjecutando = false;
        }
      );
  }

  getListaIdioma() {
    this.operacionRespuesta.EstaEjecutando = true;
    this.services
      .HttpGet(
        this.modelo_configuracion.serviciosOperaciones + "/Catalogos/GetListaGeneralCorto?p_catalogo=IDIOMA&p_idcatalogo=1")
      .subscribe(
        (tempdate) => {
          if (tempdate) {
            this.listaIdioma = tempdate.response as Lista_idioma_modelo_response[];
          }
          this.operacionRespuesta.EstaEjecutando = false;
        },
        async (err) => {
          this.operacionRespuesta.EstaEjecutando = false;
        }
      );
  }


  obtenerUsuarios() {
    this.operacionRespuesta.EstaEjecutando = true;
    this.services
      .HttpGet(
        this.modelo_configuracion.serviciosOperaciones + "/Generales/GetListaUsuariosCorto?p_opcion=1")
      .subscribe(
        (tempdate) => {
          if (tempdate) {
            this.listaUsuariosRealiza = [] = tempdate.response as Lista_usuarios_sistema_modelo_response[];
            tempdate.response.forEach(_usuario => {
              if (_usuario.idUsuario === this.idUsuario) {
                this.idUsuarioElabora = _usuario.idUsuario;
              }
            });

          } else {
            this.listaUsuariosRealiza = [];
          }
          this.operacionRespuesta.EstaEjecutando = false;
        },
        async (err) => {
          this.operacionRespuesta.EstaEjecutando = false;
        }
      );

    this.services
      .HttpGet(
        this.modelo_configuracion.serviciosOperaciones + "/Generales/GetListaUsuariosCorto?p_opcion=2")
      .subscribe(
        (tempdate) => {
          if (tempdate) {
            this.listaUsuariosRevisa = [] = tempdate.response as Lista_usuarios_sistema_modelo_response[];
            tempdate.response.forEach(_usuario => {
              if (_usuario.idUsuario === this.idUsuario) {
                this.idUsuarioReviso = _usuario.idUsuario;
              }
            });
          } else {
            this.listaUsuariosRevisa = [];
          }
          this.operacionRespuesta.EstaEjecutando = false;
        },
        async (err) => {
          this.operacionRespuesta.EstaEjecutando = false;
        }
      );

    this.services
      .HttpGet(
        this.modelo_configuracion.serviciosOperaciones + "/Generales/GetListaUsuariosCorto?p_opcion=3")
      .subscribe(
        (tempdate) => {
          if (tempdate) {
            this.listaUsuariosAutoriza = [] = tempdate.response as Lista_usuarios_sistema_modelo_response[];
            tempdate.response.forEach(_usuario => {
              if (_usuario.idUsuario === this.idUsuario) {
                this.idUsuarioAutorizo = _usuario.idUsuario;
                this.numbreAutorizo = _usuario.nombre;
                this.puedeEditar = true;
              }
            });
          } else {
            this.listaUsuariosAutoriza = [];
          }
          this.operacionRespuesta.EstaEjecutando = false;
        },
        async (err) => {
          this.operacionRespuesta.EstaEjecutando = false;
        }
      );
  }
  agregarCuponTransportadora() {
    this.funcionalidad = 'Nuevo';
    this._idCatCupon = 0;
    this.listServiciosTempTransportadora = [];

    this.openModalFormCuponTransportadora(true);
  }

  GuardarCuponTransportadora() {
    this.operacionRespuesta.EstaEjecutando = true;
    let model = this.formGroupAgregarCuponTransportadora.value;
    model.p_idCatCupon = this._idCatCupon;
    model.p_servicios =  this.listServiciosTempTransportadora;

    if (this.funcionalidad == 'Nuevo') {
      this.services.HttpPost(model, this.modelo_configuracion.serviciosOperaciones + "/Cupones/PostCuponTransporte")
        .subscribe(
          (tempdate) => {
            if (tempdate.response[0].success == 1) {
              this.openMensajes(tempdate.response[0].msg, false, '');
              this.obtenerCupones();
              this.modalAgregarCuponTransportadora.close();
            } else {
              this.openMensajes(tempdate.response[0].msg, true, '');
            }
            this.operacionRespuesta.EstaEjecutando = false;
          },
          async (err) => {
            this.openMensajes("No se pudo realizar la acción", true, '');
            this.operacionRespuesta.EstaEjecutando = false;
          }
        );
    } else {
      this.services.HttpPut(model, this.modelo_configuracion.serviciosOperaciones + "/Cupones/PutCuponTransporte")
        .subscribe(
          (tempdate) => {
            if (tempdate.response[0].success == 1) {
              this.openMensajes(tempdate.response[0].msg, false, '');
              this.obtenerCupones();
              this.modalAgregarCuponTransportadora.close();
            } else {
              this.openMensajes(tempdate.response[0].msg, true, '');
            }
            this.operacionRespuesta.EstaEjecutando = false;
          },
          async (err) => {
            this.openMensajes("No se pudo realizar la acción", true, '');
            this.operacionRespuesta.EstaEjecutando = false;
          }
        );
    }
  }

  guardarServicioTransporadora() {

    const nuevoServicio: Lista_temp_servicios_modelo_response = {
      p_IDTransportadoraServicio: 0,
      p_Fecha: this.formGroupAgregarServicioTransportadora.value.p_Fecha,
      p_IDTipoTransporte: this.formGroupAgregarServicioTransportadora.value.p_Tipo,
      p_IDTipoServicio: this.formGroupAgregarServicioTransportadora.value.p_TipoServicio,
      p_IDIdioma: this.formGroupAgregarServicioTransportadora.value.p_Idioma,
      p_Servicio: this.formGroupAgregarServicioTransportadora.value.p_Servicio,
      p_Comentario: this.formGroupAgregarServicioTransportadora.value.p_ComentarioServicio
    };
    
    this.listServiciosTempTransportadora.push(nuevoServicio);

    this.formGroupAgregarServicioTransportadora.reset({

      p_Fecha: "",
      p_Tipo: 0,
      p_TipoServicio: 0,
      p_Idioma: 0,
      p_Servicio: "",
      p_ComentarioServicio: "",
 
    });

    this.modalAgregarServicioTransportadora.close();

  }

  editarServicioTransporadora(index: number){
    this.accionServicio = 'Editar';
    this._index = index;
    this.modalAgregarServicioTransportadora = this.modalService.open(this.ModalAgregarTransportadora, { ariaLabelledBy: 'modal-basic-title', backdrop: 'static', size: 'md'  });

            this.formGroupAgregarServicioTransportadora.value.p_Fecha = this.listServiciosTempTransportadora[index].p_Fecha;
            const fechaCortaS = this.formGroupAgregarServicioTransportadora.value.p_Fecha.split('T')[0];
            this.formGroupAgregarServicioTransportadora.value.p_Fecha = fechaCortaS;
            this.formGroupAgregarServicioTransportadora.value.p_Tipo = this.listServiciosTempTransportadora[index].p_IDTipoTransporte;
            this.formGroupAgregarServicioTransportadora.value.p_TipoServicio = this.listServiciosTempTransportadora[index].p_IDTipoServicio;
            this.formGroupAgregarServicioTransportadora.value.p_Idioma = this.listServiciosTempTransportadora[index].p_IDIdioma;
            this.formGroupAgregarServicioTransportadora.value.p_Servicio = this.listServiciosTempTransportadora[index].p_Servicio;
            this.formGroupAgregarServicioTransportadora.value.p_ComentarioServicio = this.listServiciosTempTransportadora[index].p_Comentario;

            this.formGroupAgregarServicioTransportadora.patchValue(this.formGroupAgregarServicioTransportadora.value);
            
  }

  _editarServicioTransporadora() {
    const fechaCortaS = this.formGroupAgregarServicioTransportadora.value.p_Fecha.split('T')[0];
    this.listServiciosTempTransportadora[this._index].p_Fecha = fechaCortaS;
    this.listServiciosTempTransportadora[this._index].p_IDTipoTransporte = this.formGroupAgregarServicioTransportadora.value.p_Tipo;
    this.listServiciosTempTransportadora[this._index].p_IDTipoServicio = this.formGroupAgregarServicioTransportadora.value.p_TipoServicio;
    this.listServiciosTempTransportadora[this._index].p_IDIdioma = this.formGroupAgregarServicioTransportadora.value.p_Idioma;
    this.listServiciosTempTransportadora[this._index].p_Servicio = this.formGroupAgregarServicioTransportadora.value.p_Servicio;
    this.listServiciosTempTransportadora[this._index].p_Comentario = this.formGroupAgregarServicioTransportadora.value.p_ComentarioServicio;

    this.formGroupAgregarServicioTransportadora.reset({

      p_Fecha: "",
      p_Tipo: 0,
      p_TipoServicio: 0,
      p_Idioma: 0,
      p_Servicio: "",
      p_ComentarioServicio: "",
 
    });

    this.modalAgregarServicioTransportadora.close();

  }

  elimnarServicioTransportadora(index){
    this.listServiciosTempTransportadora.splice(index, 1);
  }

  verDetalleCuponTransportadora(idCatCupon) {
    this.esEditable = false;
    this.openModalFormCuponTransportadora(false);
    this.operacionRespuesta.EstaEjecutando = true;
    this.services.HttpGet(this.modelo_configuracion.serviciosOperaciones + "/Cupones/GetCupon_x_id_Transporte?p_idcupon=" + idCatCupon)
      .subscribe(
        (tempdate) => {
          if (tempdate) {
            this.Colaborador = tempdate.response as Cupon_hospedaje_modelo_response;
            this.formGroupAgregarCuponTransportadora.value.p_IDCupon = this.Colaborador[0].idCupon;
            const fechaCorta = this.Colaborador[0].fechaRegistro.split('T')[0];
            this.formGroupAgregarCuponTransportadora.value.p_FechaRegistro = fechaCorta;
            this.formGroupAgregarCuponTransportadora.value.p_IDTipoProveedor = this.Colaborador[0].idTipoProveedor;
            this.formGroupAgregarCuponTransportadora.value.p_File = this.Colaborador[0].file;
            this.formGroupAgregarCuponTransportadora.value.p_NombrePax = this.Colaborador[0].nombrePax;
            this.formGroupAgregarCuponTransportadora.value.p_NumAdulto = this.Colaborador[0].numAdulto;
            this.formGroupAgregarCuponTransportadora.value.p_NumMenores = this.Colaborador[0].numMenores;
            this.formGroupAgregarCuponTransportadora.value.p_NumBebes = this.Colaborador[0].numBebes;
            this.formGroupAgregarCuponTransportadora.value.p_IDProveedor = this.Colaborador[0].idProveedor;
            this.formGroupAgregarCuponTransportadora.value.p_Cortesias = this.Colaborador[0].cortesias;
            this.formGroupAgregarCuponTransportadora.value.p_Comentarios = this.Colaborador[0].comentarios;
            this.formGroupAgregarCuponTransportadora.value.p_Elaboro = this.Colaborador[0].elaboro;
            this.formGroupAgregarCuponTransportadora.value.p_Reviso = this.Colaborador[0].reviso;
            this.formGroupAgregarCuponTransportadora.value.p_Autorizo = this.Colaborador[0].autorizo;

            this.formGroupAgregarCuponTransportadora.patchValue(this.formGroupAgregarCuponTransportadora.value);
            
          } else {
            this.listaCupones = [];
          }
          this.operacionRespuesta.EstaEjecutando = false;
        },
        async (err) => {
          this.operacionRespuesta.EstaEjecutando = false;
        }
      );

      this.services.HttpGet(this.modelo_configuracion.serviciosOperaciones + "/Cupones/GetCuponServicios?p_idcupon=" + idCatCupon + "&p_opcion=2")
      .subscribe(
        (tempdate) => {
          if (tempdate) {
            this.listServiciosTempTransportadora = tempdate.response as Lista_temp_servicios_modelo_response[];           
          } else {
            this.listServiciosTempTransportadora = [];
          }
          this.operacionRespuesta.EstaEjecutando = false;
        },
        async (err) => {
          this.operacionRespuesta.EstaEjecutando = false;
        }
      );
    }
    editarCuponTransportadora(idCatCupon) {
    this.funcionalidad = 'Editar';
    this._idCatCupon = idCatCupon;
    this.openModalFormCuponTransportadora(true);
    this.operacionRespuesta.EstaEjecutando = true;
    this.services.HttpGet(this.modelo_configuracion.serviciosOperaciones + "/Cupones/GetCupon_x_id_Transporte?p_idcupon=" + idCatCupon)
      .subscribe(
        (tempdate) => {
          if (tempdate) {
            this.Colaborador = tempdate.response as Cupon_hospedaje_modelo_response;
            this.formGroupAgregarCuponTransportadora.value.p_IDCupon = this.Colaborador[0].idCupon;
            const fechaCorta = this.Colaborador[0].fechaRegistro.split('T')[0];
            this.formGroupAgregarCuponTransportadora.value.p_FechaRegistro = fechaCorta;
            this.formGroupAgregarCuponTransportadora.value.p_IDTipoProveedor = this.Colaborador[0].idTipoProveedor;
            this.formGroupAgregarCuponTransportadora.value.p_File = this.Colaborador[0].file;
            this.formGroupAgregarCuponTransportadora.value.p_NombrePax = this.Colaborador[0].nombrePax;
            this.formGroupAgregarCuponTransportadora.value.p_NumAdulto = this.Colaborador[0].numAdulto;
            this.formGroupAgregarCuponTransportadora.value.p_NumMenores = this.Colaborador[0].numMenores;
            this.formGroupAgregarCuponTransportadora.value.p_NumBebes = this.Colaborador[0].numBebes;
            this.formGroupAgregarCuponTransportadora.value.p_IDProveedor = this.Colaborador[0].idProveedor;
            this.formGroupAgregarCuponTransportadora.value.p_Cortesias = this.Colaborador[0].cortesias;
            this.formGroupAgregarCuponTransportadora.value.p_Comentarios = this.Colaborador[0].comentarios;
            this.formGroupAgregarCuponTransportadora.value.p_Elaboro = this.Colaborador[0].elaboro;
            this.formGroupAgregarCuponTransportadora.value.p_Reviso = this.Colaborador[0].reviso;
            this.formGroupAgregarCuponTransportadora.value.p_Autorizo = this.Colaborador[0].autorizo;

            this.formGroupAgregarCuponTransportadora.patchValue(this.formGroupAgregarCuponTransportadora.value);
          } else {
            this.listaCupones = [];
          }
          this.operacionRespuesta.EstaEjecutando = false;
        },
        async (err) => {
          this.operacionRespuesta.EstaEjecutando = false;
        }
      );

      this.services.HttpGet(this.modelo_configuracion.serviciosOperaciones + "/Cupones/GetCuponServicios?p_idcupon=" + idCatCupon + "&p_opcion=2")
      .subscribe(
        (tempdate) => {
          if (tempdate) {
            this.listServiciosTempTransportadora = tempdate.response as Lista_temp_servicios_modelo_response[];           
          

          } else {
            this.listServiciosTempTransportadora = [];
          }
          this.operacionRespuesta.EstaEjecutando = false;
        },
        async (err) => {
          this.operacionRespuesta.EstaEjecutando = false;
        }
      );
  }
  eliminarCupon(idCatCupon) {
    this.operacionRespuesta.EstaEjecutando = true;
    this.services.HttpDelete(this.modelo_configuracion.serviciosOperaciones + "/Cupones/DeleteCupon?p_idcupon=" + idCatCupon)
      .subscribe(
        (tempdate) => {
          if (tempdate.response[0].success == 1) {
            this.openMensajes(tempdate.response[0].msg, false, '');
            this.obtenerCupones();
            this.modalEliminarCupon.close();
          } else {
            this.openMensajes(tempdate.response[0].msg, true, '');
          }
          this.operacionRespuesta.EstaEjecutando = false;
        },
        async (err) => {
          this.openMensajes("No se pudo realizar la acción", true, '');
          this.operacionRespuesta.EstaEjecutando = false;
        }
      );
  }

  inhabilitarCupon(idCatCupon) {
    this.operacionRespuesta.EstaEjecutando = true;
    this.services.HttpDelete(this.modelo_configuracion.serviciosOperaciones + "/Cupones/InhabilitarCupon?p_idcupon=" + idCatCupon)
      .subscribe(
        (tempdate) => {
          if (tempdate.response[0].success == 1) {
            this.openMensajes(tempdate.response[0].msg, false, '');
            this.obtenerCupones();
            this.modalInhabilitarCupon.close();
          } else {
            this.openMensajes(tempdate.response[0].msg, true, '');
          }
          this.operacionRespuesta.EstaEjecutando = false;
        },
        async (err) => {
          this.openMensajes("No se pudo realizar la acción", true, '');
          this.operacionRespuesta.EstaEjecutando = false;
        }
      );
  }

  ActivarCupon(idCatCupon) {
    this.operacionRespuesta.EstaEjecutando = true;
    this.services.HttpDelete(this.modelo_configuracion.serviciosOperaciones + "/Cupones/ActivarCupon?p_idcupon=" + idCatCupon)
      .subscribe(
        (tempdate) => {
          if (tempdate.response[0].success == 1) {
            this.openMensajes(tempdate.response[0].msg, false, '');
            this.obtenerCupones();
            this.modalActivarCupon.close();
          } else {
            this.openMensajes(tempdate.response[0].msg, true, '');
          }
          this.operacionRespuesta.EstaEjecutando = false;
        },
        async (err) => {
          this.openMensajes("No se pudo realizar la acción", true, '');
          this.operacionRespuesta.EstaEjecutando = false;
        }
      );
  }

  autorizarCupon(idCatCupon) {
    this.operacionRespuesta.EstaEjecutando = true;
    this.services.HttpDelete(this.modelo_configuracion.serviciosOperaciones + "/Cupones/AutorizarCupon?p_idcupon=" + idCatCupon + "&p_idUsuario=" + this.idUsuarioAutorizo)
      .subscribe(
        (tempdate) => {
          if (tempdate.response[0].success == 1) {
            this.openMensajes(tempdate.response[0].msg, false, '');
            this.obtenerCupones();
            this.modalAutorizarCupon.close();
          } else {
            this.openMensajes(tempdate.response[0].msg, true, '');
          }
          this.operacionRespuesta.EstaEjecutando = false;
        },
        async (err) => {
          this.openMensajes("No se pudo realizar la acción", true, '');
          this.operacionRespuesta.EstaEjecutando = false;
        }
      );
  }

  openModalFormCuponTransportadora(esEditable: boolean) {
    this.esEditable = esEditable;
    this.formGroupAgregarCuponTransportadora.reset({

      p_IDCupon: 0,
      p_File: "",
      p_FechaRegistro: "",
      p_NombrePax: "",
      p_NumAdulto: 0,
      p_NumMenores: 0,
      p_NumBebes: 0,
      p_Comentarios: "",
      p_Elaboro: this.idUsuarioElabora,
      p_Reviso: this.idUsuarioReviso,
      p_Autorizo: this.idUsuarioAutorizo,
      p_IDProveedor: 0,
      p_Cortesias: "",
 
    });

    this.modalAgregarCuponTransportadora = this.modalService.open(this.modalAddCouponTransportadora, { ariaLabelledBy: 'modal-basic-title', backdrop: 'static', size: 'lg' });
    $("#_MAPComida").hide();
    $("#_MAPCena").hide();
  }
  openEliminar(idCatCupon, nombreCat) {
    this._idCatCupon = idCatCupon;
    this._nombreCup = nombreCat;
    this.modalEliminarCupon = this.modalService.open(this.ModalDesactivar, { ariaLabelledBy: 'modal-basic-title' });
  }

  openInhabilitar(idCatCupon, nombreCat) {
    this._idCatCupon = idCatCupon;
    this._nombreCup = nombreCat;
    this.modalInhabilitarCupon = this.modalService.open(this.ModalInhabilitar, { ariaLabelledBy: 'modal-basic-title' });
  }

  openActivar(idCatCupon, nombreCat) {
    this._idCatCupon = idCatCupon;
    this._nombreCup = nombreCat;
    this.modalActivarCupon = this.modalService.open(this.ModalActivar, { ariaLabelledBy: 'modal-basic-title' });
  }

  openAutorizarCupon(idCatCupon, opcion) {
    this._idCatCupon = idCatCupon;
    if (opcion == true) {
      this._nombreCup = 'autorizar el cupón ' + idCatCupon;
    } else {
      this._nombreCup = 'quitar la autorización el cupón ' + idCatCupon;
    }

    this.modalAutorizarCupon = this.modalService.open(this.ModalAutorizar, { ariaLabelledBy: 'modal-basic-title' });
  }

  openAgregarServicio() {
    this.accionServicio = 'Nuevo';
    this.modalAgregarServicioTransportadora = this.modalService.open(this.ModalAgregarTransportadora, { ariaLabelledBy: 'modal-basic-title', backdrop: 'static', size: 'md'  });
  }
  openCuponPDFTransporte(idCatCupon, nombreCat) {
    this.operacionRespuesta.EstaEjecutando = true;
    this.services.HttpGet(this.modelo_configuracion.serviciosOperaciones + "/Cupones/GetCupon_x_id_Transporte?p_idcupon=" + idCatCupon)
      .subscribe(
        (tempdate) => {
          if (tempdate) {
            this.pdfCupon = tempdate.response[0] as Cupon_hospedaje_modelo_response;

          } else {
            this.listaCupones = [];
          }
          this.operacionRespuesta.EstaEjecutando = false;
        },
        async (err) => {
          this.operacionRespuesta.EstaEjecutando = false;
        }
      );

      this.services.HttpGet(this.modelo_configuracion.serviciosOperaciones + "/Cupones/GetCuponServiciosPdf?p_idcupon=" + idCatCupon + "&p_opcion=2")
      .subscribe(
        (tempdate) => {
          if (tempdate) {
            this.listServiciosTemppdf = tempdate.response as Lista_temp_servicios_pdf_modelo_response[];           
          } else {
            this.listServiciosTemppdf = [];
          }
          this.operacionRespuesta.EstaEjecutando = false;
        },
        async (err) => {
          this.operacionRespuesta.EstaEjecutando = false;
        }
      );

    this._idCatCupon = idCatCupon;
    this._nombreCup = nombreCat;
    this.modalCuponPDF = this.modalService.open(this.ModalCuponPDF, { ariaLabelledBy: 'modal-basic-title', backdrop: 'static', size: 'lg' });
  }

  generarPDF(_idCatCupon) {
    const options = {
      filename: 'cupon_' + _idCatCupon + '.pdf',
      image: { type: 'jpeg', quality: 1.0 }, // Establece una calidad de imagen máxima (rango: 0.0 - 1.0)
      html2canvas: { scale: 2 }, // Configuración de rendering de HTML2Canvas
      jsPDF: { orientation: 'portrait' }
    };

    const element = document.getElementById('htmlPrint');

    html2pdf()
      .set(options)
      .from(element)
      .save();
  }

  imprimirPDF() {
    const modalContent = document.getElementById('htmlPrint');
    if (modalContent) {
      const ventanaImpresion = window.open('', '_blank');
      const estilos = Array.from(document.getElementsByTagName('link'));
      const estilosHTML = estilos.map((estilo) => estilo.outerHTML).join('');

      ventanaImpresion.document.write(`<html><head><title>Imprimir Cupón</title>${estilosHTML}</head><body><div class="row m-4"><div class="col-md-12"><div class="card px-2">`);
      ventanaImpresion.document.write(modalContent.innerHTML);
      ventanaImpresion.document.write('</div></div></div></body></html><script>window.print(); </script>');

    }
  }

  cerrarModalPDF() {
    this.modalCuponPDF.close();
  }
  openMensajes(Mensaje: string, Error: boolean, titulo: string) {
    this.modalrefMsg = this.modalService.open(ModuloModalMensajeComponent, { ariaLabelledBy: "modal-basic-title" });
    this.modalrefMsg.componentInstance.mensajesExito = [];
    this.modalrefMsg.componentInstance.mensajesError = [];
    this.modalrefMsg.componentInstance.mensajeTitulo = 'Cupón Transporte';

    if (Error) {
      this.modalrefMsg.componentInstance.showErrors = true;
      this.modalrefMsg.componentInstance.mensajesError.push(Mensaje);
    } else {
      this.modalrefMsg.componentInstance.showExitos = true;
      this.modalrefMsg.componentInstance.mensajesExito.push(Mensaje);
    }
  }

  onCheckboxChangeCuponSelect(event: any, item: any) {
    const checkboxValue = item.idCupon; // Utiliza un identificador único del elemento
  
    if (!this.selectedCupones) {
      this.selectedCupones = [];
    }
  
    if (event.target.checked) {
      this.selectedCupones.push(checkboxValue);
    } else {
      this.selectedCupones = this.selectedCupones.filter(selectedItem => selectedItem !== checkboxValue);
    }

    if (this.selectedCupones.length > 0) {
      this.activarAutorizado = true;
    } else {
      this.activarAutorizado = false;
    }
  }
  

  ActivarCuponMasivo() {
    this.operacionRespuesta.EstaEjecutando = true;

    let model = {
      p_idcupon: this.selectedCupones,
      p_idUsuario: this.idUsuario
    };

    this.services.HttpPost(model, this.modelo_configuracion.serviciosOperaciones + "/Cupones/ActivarCuponMasivo")
    .subscribe(
      (tempdate) => {
        if (tempdate.response[0].success == 1) {
             this.obtenerCupones();
        } 
        this.operacionRespuesta.EstaEjecutando = false;
      },
      async (err) => {
        this.openMensajes("No se pudo realizar la acción", true, '');
        this.operacionRespuesta.EstaEjecutando = false;
      }
    );
  }

}

