import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { ThemeConstants } from 'src/app/@espire/shared/config/theme-constant';
import { RespuestaGenerica } from 'src/app/generales/RespuestaGenerica';
import { ServiciosRutas } from 'src/app/generales/ServiciosRutas';
import { AuthGuard } from 'src/app/guards/AuthGuard';
import { ModuloModalMensajeComponent } from 'src/app/shared/modulo-modal-mensaje/modulo-modal-mensaje.component';
import { Lista_usuarios_sistema_modelo_response } from 'src/model/Catalogos/Colaboradores';
import { Cupon_hospedaje_modelo_response, Lista_cupones_hospedaje_modelo_response, Lista_idioma_modelo_response, Lista_proveedores_modelo_response, Lista_temp_servicios_modelo_response, Lista_temp_servicios_pdf_modelo_response, Lista_tipo_habitacion_modelo_response, Lista_tipo_servicio_modelo_response, Lista_tipo_transporte_modelo_response } from 'src/model/Catalogos/Cupones';
import { ServiceGenerico } from 'src/services/service-generico.service';
import * as html2pdf from 'html2pdf.js';
import { AuthIdentity } from 'src/app/guards/AuthIdentity';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';

@Component({
  selector: 'app-reportes',
  templateUrl: './reportes.component.html',
  styleUrls: ['./reportes.component.css'],
  providers: [ServiceGenerico]
})
export class ReportesComponent implements OnInit {
  formGroupConsultaCupon: FormGroup;
  fechaActualFormateada:string;

  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  isDtInitialized: boolean = false;

  private modelo_configuracion: ServiciosRutas;
  listaCupones: Lista_cupones_hospedaje_modelo_response[];
  Colaborador: Cupon_hospedaje_modelo_response;
  public operacionRespuesta: RespuestaGenerica;
  esEditable: boolean = true;
  funcionalidad: string;
  _idCatCupon: number;
  private _nombreCup: any;
  listaProveedores: Lista_proveedores_modelo_response[];
  listaTipoHabitacion: Lista_tipo_habitacion_modelo_response[];
  listaUsuarios: Lista_usuarios_sistema_modelo_response[];
  fechaEntrada: Date;
  fechaSalida: Date;
  pdfCupon: Cupon_hospedaje_modelo_response;
  listaUsuariosRealiza: Lista_usuarios_sistema_modelo_response[];
  listaUsuariosRevisa: Lista_usuarios_sistema_modelo_response[];
  listaUsuariosAutoriza: Lista_usuarios_sistema_modelo_response[];
  idUsuario: number;
  idUsuarioElabora: number = 0;
  idUsuarioReviso: number = 0;
  idUsuarioAutorizo: number = 0;
  puedeEditar: boolean = false;
  numbreAutorizo: string;
  listaTipotransporte: Lista_tipo_transporte_modelo_response[];
  listaTiposervicio: Lista_tipo_servicio_modelo_response[];
  listaIdioma: Lista_idioma_modelo_response[];
  listServiciosTempTransportadora: Lista_temp_servicios_modelo_response[] = [];
  accionServicio: string = 'Nuevo';
  _index: number;
  listServiciosTemppdf: Lista_temp_servicios_pdf_modelo_response[];
  CuponPDFTrans: any;



  constructor(    
    private auth: AuthGuard,
    private themeConstants: ThemeConstants,
    private services: ServiceGenerico,
    private router: Router,
    private modalService: NgbModal,
    private fb: FormBuilder,
    private sanitizer: DomSanitizer,
    ) {
      this.modelo_configuracion = new ServiciosRutas();
      this.operacionRespuesta = new RespuestaGenerica(); 

    const fechaActual = new Date();
    const anio = fechaActual.getFullYear();
    const mes = this.addLeadingZero(fechaActual.getMonth() + 1);
    const dia = this.addLeadingZero(fechaActual.getDate());

    this.fechaActualFormateada = `${anio}-${mes}-${dia}`;

     }

     private addLeadingZero(value: number): string {
      return value < 10 ? `0${value}` : value.toString();
    }

  ngOnInit() {
    this.inicializarFormConsultaCupon();
    this.obtenerUsuarios();
    this.getListaProveedores(4);
    this.obtenerCupones();
    this.inicializaTabla();
  }

  inicializaTabla() {
    this.dtOptions = this.themeConstants.dtOptions;
    this.dtTrigger = new Subject();
  }
  renderTabla() {
    if ("dtInstance" in this.dtElement) {
      this.dtElement.dtInstance.then((instancia: DataTables.Api) => {
        instancia.destroy();
        this.dtTrigger.next();
      });
    } else {
      this.dtTrigger.next();
    }
  }

  inicializarFormConsultaCupon() {
    this.formGroupConsultaCupon = this.fb.group({
      p_Fecha_inicio: [this.fechaActualFormateada],
      p_Fecha_fin: [this.fechaActualFormateada],
      p_TipoCupon: [4],
      p_Proveedor: [0],
      p_Elabora: [0],
      p_Solicita: [0],
      p_Autoriza: [0],
      p_Estado: [3],
      // p_FechaEntrada: [this.fechaActualFormateada],
      // p_FechaSalida: [this.fechaActualFormateada]
    });
  }

  obtenerUsuarios() {
    this.operacionRespuesta.EstaEjecutando = true;
    this.services
      .HttpGet(
        this.modelo_configuracion.serviciosOperaciones + "/Generales/GetListaUsuariosCorto?p_opcion=1")
      .subscribe(
        (tempdate) => {
          if (tempdate) {
            this.listaUsuariosRealiza = [] = tempdate.response as Lista_usuarios_sistema_modelo_response[];
            tempdate.response.forEach(_usuario => {
              if (_usuario.idUsuario === this.idUsuario) {
                this.idUsuarioElabora = _usuario.idUsuario;
              }
            });
  
          } else {
            this.listaUsuariosRealiza = [];
          }
          this.operacionRespuesta.EstaEjecutando = false;
        },
        async (err) => {
          this.operacionRespuesta.EstaEjecutando = false;
        }
      );
  
    this.services
      .HttpGet(
        this.modelo_configuracion.serviciosOperaciones + "/Generales/GetListaUsuariosCorto?p_opcion=2")
      .subscribe(
        (tempdate) => {
          if (tempdate) {
            this.listaUsuariosRevisa = [] = tempdate.response as Lista_usuarios_sistema_modelo_response[];
            tempdate.response.forEach(_usuario => {
              if (_usuario.idUsuario === this.idUsuario) {
                this.idUsuarioReviso = _usuario.idUsuario;
              }
            });
          } else {
            this.listaUsuariosRevisa = [];
          }
          this.operacionRespuesta.EstaEjecutando = false;
        },
        async (err) => {
          this.operacionRespuesta.EstaEjecutando = false;
        }
      );
  
    this.services
      .HttpGet(
        this.modelo_configuracion.serviciosOperaciones + "/Generales/GetListaUsuariosCorto?p_opcion=3")
      .subscribe(
        (tempdate) => {
          if (tempdate) {
            this.listaUsuariosAutoriza = [] = tempdate.response as Lista_usuarios_sistema_modelo_response[];
            tempdate.response.forEach(_usuario => {
              if (_usuario.idUsuario === this.idUsuario) {
                this.idUsuarioAutorizo = _usuario.idUsuario;
                this.numbreAutorizo = _usuario.nombre;
                this.puedeEditar = true;
              }
            });
          } else {
            this.listaUsuariosAutoriza = [];
          }
          this.operacionRespuesta.EstaEjecutando = false;
        },
        async (err) => {
          this.operacionRespuesta.EstaEjecutando = false;
        }
      );
  }

  obtenerCupones() {
    this.operacionRespuesta.EstaEjecutando = true;
    this.services
      .HttpGet(
        this.modelo_configuracion.serviciosOperaciones + "/Cupones/GetListaCuponesReporte?p_Fecha_inicio="+ this.formGroupConsultaCupon.value.p_Fecha_inicio +" 00:00:00&p_Fecha_fin="+ this.formGroupConsultaCupon.value.p_Fecha_fin +" 23:59:59&p_TipoCupon="+ this.formGroupConsultaCupon.value.p_TipoCupon +"&p_Proveedor="+ this.formGroupConsultaCupon.value.p_Proveedor +"&p_Elabora="+ this.formGroupConsultaCupon.value.p_Elabora +"&p_Solicita="+ this.formGroupConsultaCupon.value.p_Solicita +"&p_Autoriza="+ this.formGroupConsultaCupon.value.p_Autoriza +"&p_Estado="+ this.formGroupConsultaCupon.value.p_Estado)
      .subscribe(
        (tempdate) => {
          if (tempdate) {
            this.listaCupones = [] = tempdate.response as Lista_cupones_hospedaje_modelo_response[];
            this.renderTabla();
          } else {
            this.listaCupones = [];
            this.renderTabla();
          }
          this.operacionRespuesta.EstaEjecutando = false;
        },
        async (err) => {
          this.operacionRespuesta.EstaEjecutando = false;
        }
      );
  }

  getListaProveedores(tipoProveedor) {
    this.operacionRespuesta.EstaEjecutando = true;
    this.services
      .HttpGet(
        this.modelo_configuracion.serviciosOperaciones + "/Generales/GetListaProveedoresCorto?p_tipoProveedor="+ tipoProveedor)
      .subscribe(
        (tempdate) => {
          if (tempdate) {
            this.listaProveedores = tempdate.response as Lista_proveedores_modelo_response[];
          }
          this.operacionRespuesta.EstaEjecutando = false;
        },
        async (err) => {
          this.operacionRespuesta.EstaEjecutando = false;
        }
      );
  }

  exportToExcel(): void {
    this.dtElement.dtInstance.then((instancia: DataTables.Api) => {
      instancia.page.len(-1).draw(); // -1 significa mostrar todos los registros

      const fechaHoraLegible = this.getFecha();
      const element = document.getElementById('cuponList');
      const worksheet: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
      const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
      const data: Blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
      FileSaver.saveAs(data, 'lista_cupones_' + fechaHoraLegible + '.xlsx');

      this.dtElement.dtInstance.then((instancia: DataTables.Api) => {
        instancia.page.len(10).draw(); // Vuelve a establecer la paginación a 10 registros por página
      });

    });

  }

  private getFecha() {
    const tiempoEnMilisegundos = new Date().getTime();

    const fechaYHora = new Date(tiempoEnMilisegundos);

    const anio = fechaYHora.getFullYear();
    const mes = fechaYHora.getMonth() + 1;
    const dia = fechaYHora.getDate();
    const horas = fechaYHora.getHours();
    const minutos = fechaYHora.getMinutes();
    const segundos = fechaYHora.getSeconds();

    const fechaHoraLegible = `${anio}_${mes}_${dia}_${horas}_${minutos}_${segundos}`;
    return fechaHoraLegible;

  }

  limpiarFormulario() {
    this.formGroupConsultaCupon.reset({ p_Fecha_inicio: this.fechaActualFormateada, p_Fecha_fin: this.fechaActualFormateada, p_TipoCupon: 4, 
    p_Proveedor: 0, p_Elabora: 0, p_Solicita: 0, p_Autoriza: 0, p_Estado: 3 });

    this.obtenerCupones();

  }

}
