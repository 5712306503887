import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { ThemeConstants } from 'src/app/@espire/shared/config/theme-constant';
import { RespuestaGenerica } from 'src/app/generales/RespuestaGenerica';
import { ServiciosRutas } from 'src/app/generales/ServiciosRutas';
import { AuthGuard } from 'src/app/guards/AuthGuard';
import { ModuloModalMensajeComponent } from 'src/app/shared/modulo-modal-mensaje/modulo-modal-mensaje.component';
import { Grupo_modelo_response, Lista_grupos_modelo_response } from 'src/model/Catalogos/Grupos';
import { ServiceGenerico } from 'src/services/service-generico.service';

@Component({
  selector: 'app-grupos',
  templateUrl: './grupos.component.html',
  styleUrls: ['./grupos.component.css'],
  providers: [ServiceGenerico]
})
export class GruposComponent implements OnInit {
  @ViewChild("modalAddGrupo", { static: false }) modalAddGrupo: TemplateRef<any>;
  @ViewChild("modalConfHorarioGrupo", { static: false }) modalConfHorarioGrupo: TemplateRef<any>;
  @ViewChild("contentDesactivar", { static: false }) ModalDesactivar: TemplateRef<any>;
  modalrefMsg: NgbModalRef;
  modalAgregarGrupo: NgbModalRef;
  modalEliminarGrupo: NgbModalRef;

  formGroupAgregarGrupo: FormGroup;

  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  isDtInitialized: boolean = false;
  public archivos: any = [];
  public usuarioId: string = "";

  private modelo_configuracion: ServiciosRutas;
  listaGrupos: Lista_grupos_modelo_response[];
  Grupo: Grupo_modelo_response;
  public operacionRespuesta: RespuestaGenerica;
  esEditable: boolean = true;
  funcionalidad: string;
  _idGrupo: number;
  private _nombreCat: any;

  constructor(
    private auth: AuthGuard,
    private themeConstants: ThemeConstants,
    private services: ServiceGenerico,
    private router: Router,
    private modalService: NgbModal,
    private fb: FormBuilder,
    private sanitizer: DomSanitizer,
  ) {
    this.modelo_configuracion = new ServiciosRutas();
    this.operacionRespuesta = new RespuestaGenerica();
  }

  ngOnInit() {
    this.inicializaTabla();
    this.obtenerGrupos();
    this.inicializarFormAgregarGrupo();

  }

  inicializaTabla() {
    this.dtOptions = this.themeConstants.dtOptions;
    this.dtTrigger = new Subject();
  }

  renderTabla() {
    if ("dtInstance" in this.dtElement) {
      this.dtElement.dtInstance.then((instancia: DataTables.Api) => {
        instancia.destroy();
        this.dtTrigger.next();
      });
    } else {
      this.dtTrigger.next();
    }
  }
  
  obtenerGrupos() {
    this.operacionRespuesta.EstaEjecutando = true;
    this.services
      .HttpGet(
        this.modelo_configuracion.serviciosOperaciones + "/Grupos/GetListaGrupos?p_estatus=1")
      .subscribe(
        (tempdate) => {
          if (tempdate) {
            this.listaGrupos = [] = tempdate.response as Lista_grupos_modelo_response[];
            this.renderTabla();
          } else {
            this.listaGrupos = [];
            this.renderTabla();
          }
          this.operacionRespuesta.EstaEjecutando = false;
        },
        async (err) => {
          this.operacionRespuesta.EstaEjecutando = false;
        }
      );
  }

  inicializarFormAgregarGrupo() {
    this.formGroupAgregarGrupo = this.fb.group({
      p_clave: ["", Validators.required], 
      p_nombre: ["", Validators.required], 
      p_descripcion: [""],
    });

  }

  agregarGrupo() {
    this.funcionalidad = 'Nuevo';
    this._idGrupo = 0;
    this.openModalFormGrupo(true);
  }

  GuardarGrupo() {
    this.operacionRespuesta.EstaEjecutando = true;
    let model = this.formGroupAgregarGrupo.value;
    model.p_idGrupo = this._idGrupo;

    if (this.funcionalidad == 'Nuevo') {
      this.services.HttpPost(model, this.modelo_configuracion.serviciosOperaciones + "/Grupos/PostGrupo")
        .subscribe(
          (tempdate) => {
            if (tempdate.response[0].success == 1) {
              this.openMensajes(tempdate.response[0].msg, false, '');
              this.obtenerGrupos();
              this.modalAgregarGrupo.close();
            } else {
              this.openMensajes(tempdate.response[0].msg, true, '');
            }
            this.operacionRespuesta.EstaEjecutando = false;
          },
          async (err) => {
            this.openMensajes("No se pudo realizar la acción", true, '');
            this.operacionRespuesta.EstaEjecutando = false;
          }
        );
    } else {
      this.services.HttpPut(model, this.modelo_configuracion.serviciosOperaciones + "/Grupos/PutGrupo")
        .subscribe(
          (tempdate) => {
            if (tempdate.response[0].success == 1) {
              this.openMensajes(tempdate.response[0].msg, false, '');
              this.obtenerGrupos();
              this.modalAgregarGrupo.close();
            } else {
              this.openMensajes(tempdate.response[0].msg, true, '');
            }
            this.operacionRespuesta.EstaEjecutando = false;
          },
          async (err) => {
            this.openMensajes("No se pudo realizar la acción", true, '');
            this.operacionRespuesta.EstaEjecutando = false;
          }
        );
    }
  }

  verDetalleGrupo(idGrupo) {
    this.esEditable = false;
    this.openModalFormGrupo(false);
    this.operacionRespuesta.EstaEjecutando = true;
    this.services.HttpGet(this.modelo_configuracion.serviciosOperaciones + "/Grupos/GetGrupo_x_id?p_idcatmaestro=" + idGrupo)
      .subscribe(
        (tempdate) => {
          if (tempdate) {
            this.Grupo = tempdate.response as Grupo_modelo_response;
            this.formGroupAgregarGrupo.value.p_clave = this.Grupo[0].clave;
            this.formGroupAgregarGrupo.value.p_nombre = this.Grupo[0].nombre;
            this.formGroupAgregarGrupo.value.p_descripcion = this.Grupo[0].descripcion;
            this.formGroupAgregarGrupo.patchValue(this.formGroupAgregarGrupo.value);
          } else {
            this.listaGrupos = [];
          }
          this.operacionRespuesta.EstaEjecutando = false;
        },
        async (err) => {
          this.operacionRespuesta.EstaEjecutando = false;
        }
      );
  }

  editarGrupo(idGrupo) {
    this.funcionalidad = 'Editar';
    this._idGrupo = idGrupo;
    this.openModalFormGrupo(true);
    this.operacionRespuesta.EstaEjecutando = true;
    this.services.HttpGet(this.modelo_configuracion.serviciosOperaciones + "/Grupos/GetGrupo_x_id?p_idcatmaestro=" + idGrupo)
      .subscribe(
        (tempdate) => {
          if (tempdate) {
            this.Grupo = tempdate.response as Grupo_modelo_response;
            this.formGroupAgregarGrupo.value.p_clave = this.Grupo[0].clave;
            this.formGroupAgregarGrupo.value.p_nombre = this.Grupo[0].nombre;
            this.formGroupAgregarGrupo.value.p_descripcion = this.Grupo[0].descripcion;
            this.formGroupAgregarGrupo.patchValue(this.formGroupAgregarGrupo.value);
          } else {
            this.listaGrupos = [];
          }
          this.operacionRespuesta.EstaEjecutando = false;
        },
        async (err) => {
          this.operacionRespuesta.EstaEjecutando = false;
        }
      );
  }

  eliminarGrupo(idGrupo) {
    this.operacionRespuesta.EstaEjecutando = true;
    this.services.HttpDelete(this.modelo_configuracion.serviciosOperaciones + "/Grupos/DeleteGrupo?p_idcatmaestro=" + idGrupo)
      .subscribe(
        (tempdate) => {
          if (tempdate.response[0].success == 1) {
            this.openMensajes(tempdate.response[0].msg, false, '');
            this.obtenerGrupos();
            this.modalEliminarGrupo.close();
          } else {
            this.openMensajes(tempdate.response[0].msg, true, '');
          }
          this.operacionRespuesta.EstaEjecutando = false;
        },
        async (err) => {
          this.openMensajes("No se pudo realizar la acción", true, '');
          this.operacionRespuesta.EstaEjecutando = false;
        }
      );
  }
  openModalFormGrupo(esEditable: boolean) {
    this.esEditable = esEditable;
    this.formGroupAgregarGrupo.reset({ p_clave: '', p_nombre: '', p_descripcion: '' });

    this.modalAgregarGrupo = this.modalService.open(this.modalAddGrupo, { ariaLabelledBy: 'modal-basic-title', backdrop: 'static', size: 'lg' });
  }


  openDesactivar(idGrupo, nombreGrupo) {
    this._idGrupo = idGrupo;
    this._nombreCat = nombreGrupo;
    this.modalEliminarGrupo = this.modalService.open(this.ModalDesactivar, { ariaLabelledBy: 'modal-basic-title' });
  }

  openMensajes(Mensaje: string, Error: boolean, titulo: string) {
    this.modalrefMsg = this.modalService.open(ModuloModalMensajeComponent, { ariaLabelledBy: "modal-basic-title" });
    this.modalrefMsg.componentInstance.mensajesExito = [];
    this.modalrefMsg.componentInstance.mensajesError = [];
    this.modalrefMsg.componentInstance.mensajeTitulo = 'Grupo';

    if (Error) {
      this.modalrefMsg.componentInstance.showErrors = true;
      this.modalrefMsg.componentInstance.mensajesError.push(Mensaje);
    } else {
      this.modalrefMsg.componentInstance.showExitos = true;
      this.modalrefMsg.componentInstance.mensajesExito.push(Mensaje);
    }
  }
}
