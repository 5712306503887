import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { ThemeConstants } from 'src/app/@espire/shared/config/theme-constant';
import { RespuestaGenerica } from 'src/app/generales/RespuestaGenerica';
import { ServiciosRutas } from 'src/app/generales/ServiciosRutas';
import { AuthGuard } from 'src/app/guards/AuthGuard';
import { Alimento, AlimentosIndividuales, Tarifario_modelo_response } from 'src/model/Catalogos/Tarifa';
import { ServiceGenerico } from 'src/services/service-generico.service';
import * as XLSX from 'xlsx-js-style';
import * as FileSaver from 'file-saver';
import { Lista_catalogo_corto_modelo_response, Lista_general_corto_modelo_response } from 'src/model/Catalogos/Catalogos';
import { Lista_proveedores_modelo_response } from 'src/model/Catalogos/Proveedores';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet; charset=UTF-8';
const EXCEL_EXT = '.xlsx';

@Component({
  selector: 'app-generartarifariohosp',
  templateUrl: './generartarifariohosp.component.html',
  styleUrls: ['./generartarifariohosp.component.css'],
  providers: [ServiceGenerico]
})
export class GenerartarifariohospComponent implements OnInit {
  private modelo_configuracion: ServiciosRutas;
  public operacionRespuesta: RespuestaGenerica;
  fechaActualFormateada: string;
  formGroupConsultaTarifa: FormGroup;

  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  isDtInitialized: boolean = false;

  listaTarifario: Tarifario_modelo_response[];
  categorias: string[];
  tipos: string[];
  valores: string[];
  listaEdos: Lista_general_corto_modelo_response[];
  listaMun: Lista_general_corto_modelo_response[];
  listaCatalogos: Lista_general_corto_modelo_response[];
  listaProveedores: Lista_proveedores_modelo_response[];
  listaCatalogosFU: Lista_catalogo_corto_modelo_response[];
  showFU: boolean = false;

  constructor(
    private auth: AuthGuard,
    private themeConstants: ThemeConstants,
    private services: ServiceGenerico,
    private router: Router,
    private modalService: NgbModal,
    private fb: FormBuilder,
    private sanitizer: DomSanitizer,
  ) {
    this.modelo_configuracion = new ServiciosRutas();
    this.operacionRespuesta = new RespuestaGenerica();
    const fechaActual = new Date();
    const anio = fechaActual.getFullYear();
    const mes = this.addLeadingZero(fechaActual.getMonth() + 1);
    const dia = this.addLeadingZero(fechaActual.getDate());
    this.fechaActualFormateada = `${anio}-${mes}-${dia}`;

  }

  private addLeadingZero(value: number): string {
    return value < 10 ? `0${value}` : value.toString();
  }

  ngOnInit() {
    this.inicializarFormConsultaTarifas();
    this.inicializaTabla();
    // this.obtenerTarifario();
    this.obtenerCatalogosCortos('CC', 0);
    this.getListaProveedores(4);
    // catalogo categoría
    this.obtenerCatalogosGenericos(34);

  }


  inicializarFormConsultaTarifas() {
    this.formGroupConsultaTarifa = this.fb.group({
      p_Fecha_v_inicio: [this.fechaActualFormateada],
      p_Fecha_v_fin: [this.fechaActualFormateada],
      p_Fecha_r_inicio: [this.fechaActualFormateada],
      p_Fecha_r_fin: [this.fechaActualFormateada],
      p_TipoCambio: [17],
      p_Pais: [0],
      p_Estado: [0],
      p_Ciudad: [0],
      p_Categoria: [0],
      p_Proveedor: [0],
      p_MonedaSalida: [72],
      p_fu: [0]
    });
  }


  inicializaTabla() {
    this.dtOptions = this.themeConstants.dtOptions;
    this.dtTrigger = new Subject();
  }
  renderTabla() {
    if ("dtInstance" in this.dtElement) {
      this.dtElement.dtInstance.then((instancia: DataTables.Api) => {
        instancia.destroy();
        this.dtTrigger.next();
      });
    } else {
      this.dtTrigger.next();
    }
  }

  obtenerTarifario() {
    this.operacionRespuesta.EstaEjecutando = true;

    const _p_IncluirNP = document.querySelector('input[id="p_IncluirNP"]') as HTMLInputElement;
    var p_IncluirNP = 1;
    if (_p_IncluirNP.checked) {
      p_IncluirNP = 1;
    } else {
      p_IncluirNP = 0;
    }

    const _p_incluir_imp = document.querySelector('input[id="p_incluir_imp"]') as HTMLInputElement;
    var p_incluir_imp = 1;
    if (_p_incluir_imp.checked) {
      p_incluir_imp = 1;
    } else {
      p_incluir_imp = 0;
    }

    const _p_incluir_fu = document.querySelector('input[id="p_incluir_fu"]') as HTMLInputElement;
    var p_incluir_fu = 1;
    if (_p_incluir_fu.checked) {
      p_incluir_fu = 1;
    } else {
      p_incluir_fu = 0;
    }

    this.services
      .HttpGet(
        this.modelo_configuracion.serviciosOperaciones + "/Tarifas/GetTarifarioHospedaje?p_idProveedor=" + this.formGroupConsultaTarifa.value.p_Proveedor +
        "&p_Pais="+ this.formGroupConsultaTarifa.value.p_Pais +"&p_Estado="+ this.formGroupConsultaTarifa.value.p_Estado +"&p_Municipio="+ this.formGroupConsultaTarifa.value.p_Ciudad +
        "&p_Categoria=" + this.formGroupConsultaTarifa.value.p_Categoria + "&p_VigenciaDesde="+ this.formGroupConsultaTarifa.value.p_Fecha_v_inicio +
        "&p_VigenciaHasta=" + this.formGroupConsultaTarifa.value.p_Fecha_v_fin + "&bnd=" + this.formGroupConsultaTarifa.value.p_MonedaSalida + 
        "&p_tipo_cambio=" + this.formGroupConsultaTarifa.value.p_TipoCambio + "&p_IncluirNP=" + p_IncluirNP +
        "&p_incluir_imp=" + p_incluir_imp + "&p_incluir_fu=" + p_incluir_fu + "&p_fu=" + this.formGroupConsultaTarifa.value.p_fu)
      .subscribe(
        (tempdate) => {
          if (tempdate) {
            this.listaTarifario = [] = tempdate.response as Tarifario_modelo_response[];
            this.renderTabla();
          } else {
            this.listaTarifario = [];
            this.renderTabla();
          }
          this.operacionRespuesta.EstaEjecutando = false;
        },
        async (err) => {
          this.operacionRespuesta.EstaEjecutando = false;
        }
      );
  }

  parseAlimentos(alimentosStr: string): Alimento[] {
    const alimentosObj = JSON.parse(alimentosStr);
    return alimentosObj.Alimentos;
  }
  parseAlimentosIndividuales(alimentosStrInd: string): AlimentosIndividuales[] {
    const alimentosIndObj = JSON.parse(alimentosStrInd);
    return alimentosIndObj.AlimentosIndividuales;
  }


  private getFecha() {
    const tiempoEnMilisegundos = new Date().getTime();

    const fechaYHora = new Date(tiempoEnMilisegundos);

    const anio = fechaYHora.getFullYear();
    const mes = fechaYHora.getMonth() + 1;
    const dia = fechaYHora.getDate();
    const horas = fechaYHora.getHours();
    const minutos = fechaYHora.getMinutes();
    const segundos = fechaYHora.getSeconds();

    // const fechaHoraLegible = `${anio}/${mes}/${dia}_${horas}_${minutos}_${segundos}`;
    const fechaHoraLegible = `${anio}/${mes}/${dia}`;
    return fechaHoraLegible;

  }

  limpiarFormulario() {
    this.formGroupConsultaTarifa.reset({
      p_Fecha_v_inicio: this.fechaActualFormateada, p_Fecha_v_fin: this.fechaActualFormateada,
      p_Fecha_r_inicio: this.fechaActualFormateada, p_Fecha_r_fin: this.fechaActualFormateada, p_TipoCambio: 0,
      p_Pais: 0, p_Estado: 0, p_Ciudad: 0, p_Categoria: 0, p_Proveedor: 0, p_MonedaSalida: 72
    });

    this.obtenerTarifario();

  }

  getFechaTitulo() {
    const fecha = new Date(this.getFecha()); // Convierte la fecha en un objeto Date
    const dia = fecha.getDate().toString().padStart(2, '0'); // Obtiene el día con formato de dos dígitos
    const mes = (fecha.getMonth() + 1).toString().padStart(2, '0'); // Obtiene el mes (agrega 1 ya que los meses comienzan en 0) con formato de dos dígitos
    const anio = fecha.getFullYear(); // Obtiene el año
    const fechaFormateada = `${dia}/${mes}/${anio}`; // Crea la fecha en el formato deseado
  
    return fechaFormateada;
  }

  createDateObjectWithAdjustedDay(date) {
    const adjustedDate = new Date(date);
    adjustedDate.setDate(adjustedDate.getDate() + 1);
    return adjustedDate;
}
  
exportToExcelGeneral(json: any[]): void {
    this.operacionRespuesta.EstaEjecutando = true;

    json = this.listaTarifario

    const fechaHoraLegible = this.getFecha();




    var worksheet = XLSX.utils.aoa_to_sheet([["KETZALTOUR S.A. DE C.V. - HOTEL FIT'S CONFIDENTIAL TARIFF BROCHURE (actual tax included) " + this.getFechaTitulo()]]);
    worksheet['!merges'] = [{ s: { c: 0, r: 0 }, e: { c: 30, r: 0 } }];

    worksheet['A1'].s = {
      fill: {
        patternType: "solid",
        fgColor: { rgb: "BCE292" },
        bgColor: { rgb: "BCE292" }
      },
      font: {
        name: 'Arial',
        sz: 14,
        color: { rgb: "#FF000000" },
        bold: true,
        italic: false,
        underline: false
      },
      border: {
        top: { style: "thin", color: { auto: 1 } },
        right: { style: "thin", color: { auto: 1 } },
        bottom: { style: "thin", color: { auto: 1 } },
        left: { style: "thin", color: { auto: 1 } }
      },
      alignment: {
        horizontal: 'center',
        vertical: 'center',
        wrapText: true
      }
    };

    worksheet["!cols"] = [{ wch: 20 }, { wch: 20 }, { wch: 60 }, { wch: 40 }, { wch: 10 }, { wch: 25 }, { wch: 15 }, { wch: 15 }, { wch: 15 }, { wch: 20 }, 
                          { wch: 10 }, { wch: 30 }, { wch: 15 }, { wch: 15 }, { wch: 15 }, { wch: 15 }, { wch: 15 }, { wch: 10 }, { wch: 10 }, { wch: 10 }, 
                          { wch: 10 }, { wch: 10 }, { wch: 10 }, { wch: 10 }, { wch: 10 }, { wch: 10 }, { wch: 10 }, { wch: 10 }, { wch: 20 }, { wch: 20 }, 
                          { wch: 20 }];


    XLSX.utils.sheet_add_aoa(worksheet, [["País"]], { origin: "A2" });                          //1
    XLSX.utils.sheet_add_aoa(worksheet, [["Ciudad"]], { origin: "B2" });                        //3
    XLSX.utils.sheet_add_aoa(worksheet, [["Dirección"]], { origin: "C2" });                     //4
    XLSX.utils.sheet_add_aoa(worksheet, [["Hotel"]], { origin: "D2" });                         //5
    XLSX.utils.sheet_add_aoa(worksheet, [["Categoría"]], { origin: "E2" });                     //6
    XLSX.utils.sheet_add_aoa(worksheet, [["Políticas de Cancelación"]], { origin: "F2" });      //7
    XLSX.utils.sheet_add_aoa(worksheet, [["Vigencia desde"]], { origin: "G2" });                //8
    XLSX.utils.sheet_add_aoa(worksheet, [["Vigencia hasta"]], { origin: "H2" });                //9
    XLSX.utils.sheet_add_aoa(worksheet, [["DÍas aplica"]], { origin: "I2" });                   //10
    XLSX.utils.sheet_add_aoa(worksheet, [["Tipo de habitación"]], { origin: "J2" });            //11
    XLSX.utils.sheet_add_aoa(worksheet, [["Tipo de Tarifa"]], { origin: "K2" });                //12
    XLSX.utils.sheet_add_aoa(worksheet, [["Grupo"]], { origin: "L2" });             //13
    XLSX.utils.sheet_add_aoa(worksheet, [["Moneda de Pago"]], { origin: "M2" });              //14
    XLSX.utils.sheet_add_aoa(worksheet, [["Plan Alimentos"]], { origin: "N2" });                         //15
    XLSX.utils.sheet_add_aoa(worksheet, [["SGL"]], { origin: "O2" });                //16
    XLSX.utils.sheet_add_aoa(worksheet, [["DBL"]], { origin: "P2" });                //17
    XLSX.utils.sheet_add_aoa(worksheet, [["TPL"]], { origin: "Q2" });                           //18
    XLSX.utils.sheet_add_aoa(worksheet, [["CDP"]], { origin: "R2" });                           //19
    XLSX.utils.sheet_add_aoa(worksheet, [["Tarifa menor"]], { origin: "S2" });                           //20
    XLSX.utils.sheet_add_aoa(worksheet, [["Edad menor desde"]], { origin: "T2" });                           //21
    XLSX.utils.sheet_add_aoa(worksheet, [["Edad menor hasta"]], { origin: "U2" });                  //22
    XLSX.utils.sheet_add_aoa(worksheet, [["Bell Boys"]], { origin: "V2" });                    //29
    XLSX.utils.sheet_add_aoa(worksheet, [["Camarista"]], { origin: "W2" });                    //30
    XLSX.utils.sheet_add_aoa(worksheet, [["Impuesto"]], { origin: "X2" });                      //24
    XLSX.utils.sheet_add_aoa(worksheet, [["Alimento Individual"]], { origin: "Y2" });           //25
    XLSX.utils.sheet_add_aoa(worksheet, [["Costo"]], { origin: "Z2" });                         //26
    XLSX.utils.sheet_add_aoa(worksheet, [["Costo menor"]], { origin: "AA2" });                  //27
    XLSX.utils.sheet_add_aoa(worksheet, [["Impuesto y Propina"]], { origin: "AB2" });           //28
    XLSX.utils.sheet_add_aoa(worksheet, [["Incluye"]], { origin: "AC2" });                      //31
    XLSX.utils.sheet_add_aoa(worksheet, [["Excepto"]], { origin: "AD2" });                      //32
    XLSX.utils.sheet_add_aoa(worksheet, [["Aplica"]], { origin: "AE2" });                       //33

    const styleHeader = {
      fill: {
        patternType: "solid",
        fgColor: { rgb: "BCE292" },
        bgColor: { rgb: "BCE292" }
      },
      font: {
        name: 'Arial',
        sz: 9,
        color: { rgb: "#FF000000" },
        bold: true,
        italic: false,
        underline: false
      },
      border: {
        top: { style: "thin", color: { auto: 1 } },
        right: { style: "thin", color: { auto: 1 } },
        bottom: { style: "thin", color: { auto: 1 } },
        left: { style: "thin", color: { auto: 1 } }
      },
      alignment: {
        horizontal: 'center',
        vertical: 'center',
        wrapText: true
      }
    };

    worksheet['A2'].s = styleHeader;
    worksheet['B2'].s = styleHeader;
    worksheet['C2'].s = styleHeader;
    worksheet['D2'].s = styleHeader;
    worksheet['E2'].s = styleHeader;
    worksheet['F2'].s = styleHeader;
    worksheet['G2'].s = styleHeader;
    worksheet['H2'].s = styleHeader;
    worksheet['I2'].s = styleHeader;
    worksheet['J2'].s = styleHeader;
    worksheet['K2'].s = styleHeader;
    worksheet['L2'].s = styleHeader;
    worksheet['M2'].s = styleHeader;
    worksheet['N2'].s = styleHeader;
    worksheet['O2'].s = styleHeader;
    worksheet['P2'].s = styleHeader;
    worksheet['Q2'].s = styleHeader;
    worksheet['R2'].s = styleHeader;
    worksheet['S2'].s = styleHeader;
    worksheet['T2'].s = styleHeader;
    worksheet['U2'].s = styleHeader;
    worksheet['V2'].s = styleHeader;
    worksheet['W2'].s = styleHeader;
    worksheet['X2'].s = styleHeader;
    worksheet['Y2'].s = styleHeader;
    worksheet['Z2'].s = styleHeader;
    worksheet['AA2'].s = styleHeader;
    worksheet['AB2'].s = styleHeader;
    worksheet['AC2'].s = styleHeader;
    worksheet['AD2'].s = styleHeader;
    worksheet['AE2'].s = styleHeader;

    var styleBody = {
    };

    styleBody = {
      font: {
        name: 'Arial',
        sz: 9,
        color: { rgb: "#FF000000" },
        bold: false,
        italic: false,
        underline: false
      },
      border: {
        top: { style: "thin", color: { auto: 1 } },
      },
      alignment: {
        horizontal: 'center',
        vertical: 'center',
        wrapText: true
      }
    };


    var styleBodyNoTopBorder = {
      font: {
        name: 'Arial',
        sz: 9,
        color: { rgb: "#FF000000" },
        bold: false,
        italic: false,
        underline: false
      },
      alignment: {
        horizontal: 'center',
        vertical: 'center',
        wrapText: true
      }
    };
    this.operacionRespuesta.EstaEjecutando = true;

    var ii =  3;
    var jj = 0; // fila donde debe iniciar

    for (var i = 0; i <= this.listaTarifario.length - 1; i++) {

      var _direccion = this.listaTarifario[i].direccion;
      var _pais = this.listaTarifario[i].pais;
      var _ciudad = this.listaTarifario[i].ciudad;
      var _hotel = this.listaTarifario[i].nombre;
      var _categoria = this.listaTarifario[i].categoria;
      var _politicas = this.listaTarifario[i].politicas;

      if (i > 0) {
        if (this.listaTarifario[i].direccion === this.listaTarifario[i - 1].direccion) {
          _direccion = "";
          _pais = "";
          _ciudad = "";
          _hotel = "";
          _categoria = "";

          styleBody = {
            font: {
              name: 'Arial',
              sz: 9,
              color: { rgb: "#FF000000" },
              bold: false,
              italic: false,
              underline: false
            },
            alignment: {
              horizontal: 'center',
              vertical: 'center',
              wrapText: true
            }
          };

        } else {
          styleBody = {
            font: {
              name: 'Arial',
              sz: 9,
              color: { rgb: "#FF000000" },
              bold: false,
              italic: false,
              underline: false
            },
            border: {
              top: { style: "thin", color: { auto: 1 } },
            },
            alignment: {
              horizontal: 'center',
              vertical: 'center',
              wrapText: true
            }
          };
        }

        if (this.listaTarifario[i].politicas === this.listaTarifario[i - 1].politicas) {
          _politicas = "";
        }
      }

      XLSX.utils.sheet_add_aoa(worksheet, [[_pais]], { origin: "A" + ii });
      XLSX.utils.sheet_add_aoa(worksheet, [[_ciudad]], { origin: "B" + ii });
      XLSX.utils.sheet_add_aoa(worksheet, [[_direccion]], { origin: "C" + ii });
      XLSX.utils.sheet_add_aoa(worksheet, [[_hotel]], { origin: "D" + ii });
      XLSX.utils.sheet_add_aoa(worksheet, [[_categoria]], { origin: "E" + ii });
      XLSX.utils.sheet_add_aoa(worksheet, [[_politicas]], { origin: "F" + ii });
      XLSX.utils.sheet_add_aoa(worksheet, [[{t: 'd', v: this.createDateObjectWithAdjustedDay(this.listaTarifario[i].vigenciaDesde), z: 'dd/MM/yyyy' }]], { origin: "G" + ii });
      XLSX.utils.sheet_add_aoa(worksheet, [[{t: 'd', v: this.createDateObjectWithAdjustedDay(this.listaTarifario[i].vigenciaHasta), z: 'dd/MM/yyyy' }]], { origin: "H" + ii });
      XLSX.utils.sheet_add_aoa(worksheet, [[this.listaTarifario[i].diasAplica]], { origin: "I" + ii });
      XLSX.utils.sheet_add_aoa(worksheet, [[this.listaTarifario[i].tipoHabitacion]], { origin: "J" + ii });
      XLSX.utils.sheet_add_aoa(worksheet, [[this.listaTarifario[i].tipoTarifa]], { origin: "K" + ii });
      XLSX.utils.sheet_add_aoa(worksheet, [[{v: this.listaTarifario[i].txtGrupo }]], { origin: "L" + ii });
      XLSX.utils.sheet_add_aoa(worksheet, [[{v: this.listaTarifario[i].monedaTxt }]], { origin: "M" + ii });

      worksheet["A" + ii].s = styleBody;
      worksheet["B" + ii].s = styleBody;
      worksheet["C" + ii].s = styleBody;
      worksheet["D" + ii].s = styleBody;
      worksheet["E" + ii].s = styleBody;
      worksheet["F" + ii].s = styleBody;
      worksheet["G" + ii].s = styleBody;
      worksheet["H" + ii].s = styleBody;
      worksheet["I" + ii].s = styleBody;
      worksheet["J" + ii].s = styleBody;
      worksheet["K" + ii].s = styleBody;
      worksheet["L" + ii].s = styleBody;
      worksheet["M" + ii].s = styleBody;

      const _alimentos = JSON.parse(this.listaTarifario[i].alimentos);
      const _alimentosIndividuales = JSON.parse(this.listaTarifario[i].alimentosIndividuales);

      var ll = 0;
      var mm = 0;
      var merge = 0;
  
      XLSX.utils.sheet_add_aoa(worksheet, [[{v: this.listaTarifario[i].menoresEdadD.toString() }]], { origin: "T" + ii });
      worksheet["T" + ii].s = styleBody; 
      XLSX.utils.sheet_add_aoa(worksheet, [[{v: this.listaTarifario[i].menoresEdadH }]], { origin: "U" + ii });
      worksheet["U" + ii].s = styleBody; 
      XLSX.utils.sheet_add_aoa(worksheet, [[{v: this.listaTarifario[i].bell}]], { origin: "V" + ii });
      worksheet["V" + ii].s = styleBody; 
      XLSX.utils.sheet_add_aoa(worksheet, [[{v: this.listaTarifario[i].maid}]], { origin: "W" + ii });
      worksheet["W" + ii].s = styleBody; 
      XLSX.utils.sheet_add_aoa(worksheet, [[{v: this.listaTarifario[i].impuestos}]], { origin: "X" + ii });
      worksheet["X" + ii].s = styleBody; 
      
      XLSX.utils.sheet_add_aoa(worksheet, [[{v: this.listaTarifario[i].impuesto_Propina}]], { origin: "AB" + ii });
      worksheet["AB" + ii].s = styleBody;
      XLSX.utils.sheet_add_aoa(worksheet, [[this.listaTarifario[i].incluye]], { origin: "AC" + ii });
      worksheet["AC" + ii].s = styleBody;
      XLSX.utils.sheet_add_aoa(worksheet, [[this.listaTarifario[i].excepto]], { origin: "AD" + ii });
      worksheet["AD" + ii].s = styleBody;
      XLSX.utils.sheet_add_aoa(worksheet, [[this.listaTarifario[i].aplica]], { origin: "AE" + ii });
      worksheet["AE" + ii].s = styleBody;


      if (this.listaTarifario[i].conteo == 8) {
        // continue;
        XLSX.utils.sheet_add_aoa(worksheet, [['']], { origin: "N" + ii });
        XLSX.utils.sheet_add_aoa(worksheet, [[{ t: 'n', v: 0.00, z: '$ #,##0.00' }]], { origin: "O" + ii });
        XLSX.utils.sheet_add_aoa(worksheet, [[{ t: 'n', v: 0.00, z: '$ #,##0.00' }]], { origin: "P" + ii });
        XLSX.utils.sheet_add_aoa(worksheet, [[{ t: 'n', v: 0.00, z: '$ #,##0.00' }]], { origin: "Q" + ii });
        XLSX.utils.sheet_add_aoa(worksheet, [[{ t: 'n', v: 0.00, z: '$ #,##0.00' }]], { origin: "R" + ii });
        XLSX.utils.sheet_add_aoa(worksheet, [[{ t: 'n', v: 0.00, z: '$ #,##0.00' }]], { origin: "S" + ii });

        worksheet["N" + ii].s = styleBody;
        worksheet["O" + ii].s = styleBody;
        worksheet["P" + ii].s = styleBody;
        worksheet["Q" + ii].s = styleBody;
        worksheet["R" + ii].s = styleBody;
        worksheet["S" + ii].s = styleBody;

        XLSX.utils.sheet_add_aoa(worksheet, [['']], { origin: "Y" + ii });
        XLSX.utils.sheet_add_aoa(worksheet, [['']], { origin: "Z" + ii });
        XLSX.utils.sheet_add_aoa(worksheet, [['']], { origin: "AA" + ii });

        worksheet["Y" + ii].s = styleBody;
        worksheet["Z" + ii].s = styleBody;
        worksheet["AA" + ii].s = styleBody;

        ii = ii + 1;
      } 
      else 
      {
        if (Array.isArray(_alimentos.Alimentos) || Array.isArray(_alimentosIndividuales.AlimentosIndividuales)) {
          jj = ii;
          for (var j = 0; j <= _alimentos.Alimentos.length - 1; j++) {
            if (_alimentos.Alimentos[j].SGL != 0 || _alimentos.Alimentos[j].DBL != 0 || _alimentos.Alimentos[j].TPL != 0 || _alimentos.Alimentos[j].CDP != 0 || _alimentos.Alimentos[j].TarifaCDH != 0 || _alimentos.Alimentos[j].RackRate != 0) {
    
              XLSX.utils.sheet_add_aoa(worksheet, [[_alimentos.Alimentos[j].TipoAlimento]], { origin: "N" + jj });
              XLSX.utils.sheet_add_aoa(worksheet, [[{ t: 'n', v: _alimentos.Alimentos[j].SGL, z: '$ #,##0.00' }]], { origin: "O" + jj });
              XLSX.utils.sheet_add_aoa(worksheet, [[{ t: 'n', v: _alimentos.Alimentos[j].DBL, z: '$ #,##0.00' }]], { origin: "P" + jj });
              XLSX.utils.sheet_add_aoa(worksheet, [[{ t: 'n', v: _alimentos.Alimentos[j].TPL, z: '$ #,##0.00' }]], { origin: "Q" + jj });
              XLSX.utils.sheet_add_aoa(worksheet, [[{ t: 'n', v: _alimentos.Alimentos[j].CDP, z: '$ #,##0.00' }]], { origin: "R" + jj });
              XLSX.utils.sheet_add_aoa(worksheet, [[{ t: 'n', v: _alimentos.Alimentos[j].TarifaCDH, z: '$ #,##0.00' }]], { origin: "S" + jj });
    
              worksheet["N" + jj].s = styleBody;
              worksheet["O" + jj].s = styleBody;
              worksheet["P" + jj].s = styleBody;
              worksheet["Q" + jj].s = styleBody;
              worksheet["R" + jj].s = styleBody;
              worksheet["S" + jj].s = styleBody;
              
              jj += 1;
              
              ll +=1; 
            }
          }
  
          jj = ii;
        let _bndPrimer = false;
        let _bndVacioAI = false;
          for (var k = 0; k <= _alimentosIndividuales.AlimentosIndividuales.length - 1; k++) {
            if (_alimentosIndividuales.AlimentosIndividuales[k].Costo != 0 || _alimentosIndividuales.AlimentosIndividuales[k].Menor != 0) {
  
              XLSX.utils.sheet_add_aoa(worksheet, [[_alimentosIndividuales.AlimentosIndividuales[k].TipoAlimentoIndividual]], { origin: "Y" + jj });
              XLSX.utils.sheet_add_aoa(worksheet, [[{ t: 'n', v: _alimentosIndividuales.AlimentosIndividuales[k].Costo, z: '$ #,##0.00' }]], { origin: "Z" + jj });
              XLSX.utils.sheet_add_aoa(worksheet, [[{ t: 'n', v: _alimentosIndividuales.AlimentosIndividuales[k].Menor, z: '$ #,##0.00' }]], { origin: "AA" + jj });
  
                if (_bndPrimer == false) {
                  worksheet["Y" + jj].s = styleBody;
                  worksheet["Z" + jj].s = styleBody;
                  worksheet["AA" + jj].s = styleBody; 
                  _bndPrimer = true;
   
                } else {
                  worksheet["Y" + jj].s = styleBodyNoTopBorder;
                  worksheet["Z" + jj].s = styleBodyNoTopBorder;
                  worksheet["AA" + jj].s = styleBodyNoTopBorder; 
                }


              jj += 1;
              
              mm +=1;
              _bndVacioAI = false;

            } else {
              _bndVacioAI = true;
            }

          }

          if (_bndVacioAI == true) {
            XLSX.utils.sheet_add_aoa(worksheet, [['']], { origin: "Y" + jj });
            XLSX.utils.sheet_add_aoa(worksheet, [['']], { origin: "Z" + jj });
            XLSX.utils.sheet_add_aoa(worksheet, [['']], { origin: "AA" + jj });
    
            worksheet["Y" + jj].s = styleBody;
            worksheet["Z" + jj].s = styleBody;
            worksheet["AA" + jj].s = styleBody;
          }
            
  
          if (ll >= mm) {
            ii += ll;
            merge = ll;
          } else {
            ii += mm;
            merge = mm;
          }
  
  
        } else {
          ii += 1;
          jj += 1;
  
        }
      }

    }


    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };

    workbook.Sheets['data']['!protect'] = {
      password: 'Ketzaltour**', // Opcional: establece una contraseña si lo deseas
      formatCells: false, // Bloquea el formato de celdas
      formatColumns: false, // Bloquea el formato de columnas
      formatRows: false, // Bloquea el formato de filas
    };

    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.operacionRespuesta.EstaEjecutando = false;

    this.saveAsExcel(excelBuffer, 'tarifario_' + fechaHoraLegible);
  }
  

  exportToExcelCliente(json: any[]): void {
    this.operacionRespuesta.EstaEjecutando = true;

    json = this.listaTarifario

    const fechaHoraLegible = this.getFecha();

    var worksheet = XLSX.utils.aoa_to_sheet([["KETZALTOUR S.A. DE C.V. - HOTEL FIT'S CONFIDENTIAL TARIFF BROCHURE IN USD " + this.getFechaTitulo()]]);
    worksheet['!merges'] = [{ s: { c: 0, r: 0 }, e: { c: 22, r: 0 } }];

    worksheet['A1'].s = {
      fill: {
        patternType: "solid",
        fgColor: { rgb: "BCE292" },
        bgColor: { rgb: "BCE292" }
      },
      font: {
        name: 'Arial',
        sz: 14,
        color: { rgb: "#FF000000" },
        bold: true,
        italic: false,
        underline: false
      },
      border: {
        top: { style: "thin", color: { auto: 1 } },
        right: { style: "thin", color: { auto: 1 } },
        bottom: { style: "thin", color: { auto: 1 } },
        left: { style: "thin", color: { auto: 1 } }
      },
      alignment: {
        horizontal: 'center',
        vertical: 'center',
        wrapText: true
      }
    };

    
    worksheet["!cols"] = [{ wch: 20 }, { wch: 20 }, { wch: 40 }, { wch: 10 }, { wch: 25 }, { wch: 15 }, { wch: 15 }, { wch: 15 }, { wch: 20 }, 
                          { wch: 10 }, { wch: 15 }, { wch: 15 }, { wch: 15 }, { wch: 15 }, { wch: 10 }, { wch: 10 }, { wch: 10 }, { wch: 10 }, 
                          { wch: 10 }, { wch: 10 }, { wch: 20 }, { wch: 20 }, { wch: 20 }];

    XLSX.utils.sheet_add_aoa(worksheet, [["COUNTRY"]], { origin: "A2" });                          //1
    XLSX.utils.sheet_add_aoa(worksheet, [["CITY"]], { origin: "B2" });                        //3
    XLSX.utils.sheet_add_aoa(worksheet, [["HOTEL"]], { origin: "C2" });                         //5
    XLSX.utils.sheet_add_aoa(worksheet, [["CATGORY"]], { origin: "D2" });                     //6
    XLSX.utils.sheet_add_aoa(worksheet, [["CANCELLATION POLICIES"]], { origin: "E2" });      //7
    XLSX.utils.sheet_add_aoa(worksheet, [["FROM"]], { origin: "F2" });                //8
    XLSX.utils.sheet_add_aoa(worksheet, [["UNTIL"]], { origin: "G2" });                //9
    XLSX.utils.sheet_add_aoa(worksheet, [["DAYS OF THE WEEK"]], { origin: "H2" });                   //10
    XLSX.utils.sheet_add_aoa(worksheet, [["TYPE OF ROOM"]], { origin: "I2" });            //11
    XLSX.utils.sheet_add_aoa(worksheet, [["INDIVIDUAL OR GROUP"]], { origin: "J2" });                //12
    XLSX.utils.sheet_add_aoa(worksheet, [["MEAL PLAN"]], { origin: "K2" });                         //15
    XLSX.utils.sheet_add_aoa(worksheet, [["SGL"]], { origin: "L2" });                //16
    XLSX.utils.sheet_add_aoa(worksheet, [["DBL"]], { origin: "M2" });                //17
    XLSX.utils.sheet_add_aoa(worksheet, [["TPL"]], { origin: "N2" });                           //18
    XLSX.utils.sheet_add_aoa(worksheet, [["CHD RATE"]], { origin: "O2" });                           //20
    XLSX.utils.sheet_add_aoa(worksheet, [["CHILD AGE FROM"]], { origin: "P2" });                           //21
    XLSX.utils.sheet_add_aoa(worksheet, [["CHILD AGE UNTIL"]], { origin: "Q2" });                  //22
    XLSX.utils.sheet_add_aoa(worksheet, [["INDIVIDUAL MEAL"]], { origin: "R2" });           //25
    XLSX.utils.sheet_add_aoa(worksheet, [["MEAL COST"]], { origin: "S2" });                         //26
    XLSX.utils.sheet_add_aoa(worksheet, [["MEAL COST CHD"]], { origin: "T2" });                  //27
    XLSX.utils.sheet_add_aoa(worksheet, [["WHAT INCL AND OTHER INFO"]], { origin: "U2" });                      //31
    XLSX.utils.sheet_add_aoa(worksheet, [["EXCEPT"]], { origin: "V2" });                      //32
    XLSX.utils.sheet_add_aoa(worksheet, [["MIN STAY, RESORT FEE, DYNAMIC RATES"]], { origin: "W2" });                       //33

    const styleHeader = {
      fill: {
        patternType: "solid",
        fgColor: { rgb: "BCE292" },
        bgColor: { rgb: "BCE292" }
      },
      font: {
        name: 'Arial',
        sz: 9,
        color: { rgb: "#FF000000" },
        bold: true,
        italic: false,
        underline: false
      },
      border: {
        top: { style: "thin", color: { auto: 1 } },
        right: { style: "thin", color: { auto: 1 } },
        bottom: { style: "thin", color: { auto: 1 } },
        left: { style: "thin", color: { auto: 1 } }
      },
      alignment: {
        horizontal: 'center',
        vertical: 'center',
        wrapText: true
      }
    };

    worksheet['A2'].s = styleHeader;
    worksheet['B2'].s = styleHeader;
    worksheet['C2'].s = styleHeader;
    worksheet['D2'].s = styleHeader;
    worksheet['E2'].s = styleHeader;
    worksheet['F2'].s = styleHeader;
    worksheet['G2'].s = styleHeader;
    worksheet['H2'].s = styleHeader;
    worksheet['I2'].s = styleHeader;
    worksheet['J2'].s = styleHeader;
    worksheet['K2'].s = styleHeader;
    worksheet['L2'].s = styleHeader;
    worksheet['M2'].s = styleHeader;
    worksheet['N2'].s = styleHeader;
    worksheet['O2'].s = styleHeader;
    worksheet['P2'].s = styleHeader;
    worksheet['Q2'].s = styleHeader;
    worksheet['R2'].s = styleHeader;
    worksheet['S2'].s = styleHeader;
    worksheet['T2'].s = styleHeader;
    worksheet['U2'].s = styleHeader;
    worksheet['V2'].s = styleHeader;
    worksheet['W2'].s = styleHeader;

    var styleBody = {
    };

    styleBody = {
      font: {
        name: 'Arial',
        sz: 9,
        color: { rgb: "#FF000000" },
        bold: false,
        italic: false,
        underline: false
      },
      border: {
        top: { style: "thin", color: { auto: 1 } },
      },
      alignment: {
        horizontal: 'center',
        vertical: 'center',
        wrapText: true
      }
    };


    var styleBodyNoTopBorder = {
      font: {
        name: 'Arial',
        sz: 9,
        color: { rgb: "#FF000000" },
        bold: false,
        italic: false,
        underline: false
      },
      alignment: {
        horizontal: 'center',
        vertical: 'center',
        wrapText: true
      }
    };
    this.operacionRespuesta.EstaEjecutando = true;

    var ii =  3;
    var jj = 0; // fila donde debe iniciar

    for (var i = 0; i <= this.listaTarifario.length - 1; i++) {

      var _direccion = this.listaTarifario[i].direccion;
      var _pais = this.listaTarifario[i].pais;
      var _ciudad = this.listaTarifario[i].ciudad;
      var _hotel = this.listaTarifario[i].nombre;
      var _categoria = this.listaTarifario[i].categoria;
      var _politicas = this.listaTarifario[i].politicas;

      if (i > 0) {
        if (this.listaTarifario[i].direccion === this.listaTarifario[i - 1].direccion) {
          _direccion = "";
          _pais = "";
          _ciudad = "";
          _hotel = "";
          _categoria = "";

          styleBody = {
            font: {
              name: 'Arial',
              sz: 9,
              color: { rgb: "#FF000000" },
              bold: false,
              italic: false,
              underline: false
            },
            alignment: {
              horizontal: 'center',
              vertical: 'center',
              wrapText: true
            }
          };

        } else {
          styleBody = {
            font: {
              name: 'Arial',
              sz: 9,
              color: { rgb: "#FF000000" },
              bold: false,
              italic: false,
              underline: false
            },
            border: {
              top: { style: "thin", color: { auto: 1 } },
            },
            alignment: {
              horizontal: 'center',
              vertical: 'center',
              wrapText: true
            }
          };
        }

        if (this.listaTarifario[i].politicas === this.listaTarifario[i - 1].politicas) {
          _politicas = "";
        }
      }

      XLSX.utils.sheet_add_aoa(worksheet, [[_pais]], { origin: "A" + ii });
      worksheet["A" + ii].s = styleBody;
      XLSX.utils.sheet_add_aoa(worksheet, [[_ciudad]], { origin: "B" + ii });
      worksheet["B" + ii].s = styleBody;
      XLSX.utils.sheet_add_aoa(worksheet, [[_hotel]], { origin: "C" + ii });
      worksheet["C" + ii].s = styleBody;
      XLSX.utils.sheet_add_aoa(worksheet, [[_categoria]], { origin: "D" + ii });
      worksheet["D" + ii].s = styleBody;
      XLSX.utils.sheet_add_aoa(worksheet, [[_politicas]], { origin: "E" + ii });
      worksheet["E" + ii].s = styleBody;
      XLSX.utils.sheet_add_aoa(worksheet, [[{t: 'd', v: this.createDateObjectWithAdjustedDay(this.listaTarifario[i].vigenciaDesde), z: 'dd/MM/yyyy' }]], { origin: "F" + ii });
      worksheet["F" + ii].s = styleBody;
      XLSX.utils.sheet_add_aoa(worksheet, [[{t: 'd', v: this.createDateObjectWithAdjustedDay(this.listaTarifario[i].vigenciaHasta), z: 'dd/MM/yyyy' }]], { origin: "G" + ii });
      worksheet["G" + ii].s = styleBody;
      XLSX.utils.sheet_add_aoa(worksheet, [[this.listaTarifario[i].diasAplica]], { origin: "H" + ii });
      worksheet["H" + ii].s = styleBody;
      XLSX.utils.sheet_add_aoa(worksheet, [[this.listaTarifario[i].tipoHabitacion]], { origin: "I" + ii });
      worksheet["I" + ii].s = styleBody;
      XLSX.utils.sheet_add_aoa(worksheet, [[this.listaTarifario[i].tipoTarifa]], { origin: "J" + ii });
      worksheet["J" + ii].s = styleBody;

      const _alimentos = JSON.parse(this.listaTarifario[i].alimentos);
      const _alimentosIndividuales = JSON.parse(this.listaTarifario[i].alimentosIndividuales);

      var ll = 0;
      var mm = 0;
      var merge = 0;
  
      XLSX.utils.sheet_add_aoa(worksheet, [[{v: this.listaTarifario[i].menoresEdadD.toString() }]], { origin: "P" + ii });
      worksheet["P" + ii].s = styleBody; 
      XLSX.utils.sheet_add_aoa(worksheet, [[{v: this.listaTarifario[i].menoresEdadH }]], { origin: "Q" + ii });
      worksheet["Q" + ii].s = styleBody; 
    
      XLSX.utils.sheet_add_aoa(worksheet, [[this.listaTarifario[i].incluye]], { origin: "U" + ii });
      worksheet["U" + ii].s = styleBody;
      XLSX.utils.sheet_add_aoa(worksheet, [[this.listaTarifario[i].excepto]], { origin: "V" + ii });
      worksheet["V" + ii].s = styleBody;
      XLSX.utils.sheet_add_aoa(worksheet, [[this.listaTarifario[i].aplica]], { origin: "W" + ii });
      worksheet["W" + ii].s = styleBody;


      if (this.listaTarifario[i].conteo == 8) {
        // continue;
        XLSX.utils.sheet_add_aoa(worksheet, [['']], { origin: "K" + ii }); //N
        worksheet["K" + ii].s = styleBody;
        XLSX.utils.sheet_add_aoa(worksheet, [[{ t: 'n', v: 0.00, z: '$ #,##0.00' }]], { origin: "L" + ii });
        worksheet["L" + ii].s = styleBody;
        XLSX.utils.sheet_add_aoa(worksheet, [[{ t: 'n', v: 0.00, z: '$ #,##0.00' }]], { origin: "M" + ii });
        worksheet["M" + ii].s = styleBody;
        XLSX.utils.sheet_add_aoa(worksheet, [[{ t: 'n', v: 0.00, z: '$ #,##0.00' }]], { origin: "N" + ii });
        worksheet["N" + ii].s = styleBody;
        XLSX.utils.sheet_add_aoa(worksheet, [[{ t: 'n', v: 0.00, z: '$ #,##0.00' }]], { origin: "O" + ii });
        worksheet["O" + ii].s = styleBody;

        XLSX.utils.sheet_add_aoa(worksheet, [['']], { origin: "R" + ii });
        worksheet["R" + ii].s = styleBody;
        XLSX.utils.sheet_add_aoa(worksheet, [['']], { origin: "S" + ii });
        worksheet["S" + ii].s = styleBody;
        XLSX.utils.sheet_add_aoa(worksheet, [['']], { origin: "T" + ii });
        worksheet["T" + ii].s = styleBody;


        ii = ii + 1;
      } 
      else 
      {
        if (Array.isArray(_alimentos.Alimentos) || Array.isArray(_alimentosIndividuales.AlimentosIndividuales)) {
          jj = ii;
          for (var j = 0; j <= _alimentos.Alimentos.length - 1; j++) {
            if (_alimentos.Alimentos[j].SGL != 0 || _alimentos.Alimentos[j].DBL != 0 || _alimentos.Alimentos[j].TPL != 0 || _alimentos.Alimentos[j].CDP != 0 || _alimentos.Alimentos[j].TarifaCDH != 0 || _alimentos.Alimentos[j].RackRate != 0) {
    
              XLSX.utils.sheet_add_aoa(worksheet, [[_alimentos.Alimentos[j].TipoAlimento]], { origin: "K" + jj });
              worksheet["K" + jj].s = styleBody;
              XLSX.utils.sheet_add_aoa(worksheet, [[{ t: 'n', v: _alimentos.Alimentos[j].SGL, z: '$ #,##0.00' }]], { origin: "L" + jj });
              worksheet["L" + jj].s = styleBody;
              XLSX.utils.sheet_add_aoa(worksheet, [[{ t: 'n', v: _alimentos.Alimentos[j].DBL, z: '$ #,##0.00' }]], { origin: "M" + jj });
              worksheet["M" + jj].s = styleBody;
              XLSX.utils.sheet_add_aoa(worksheet, [[{ t: 'n', v: _alimentos.Alimentos[j].TPL, z: '$ #,##0.00' }]], { origin: "N" + jj });
              worksheet["N" + jj].s = styleBody;
              XLSX.utils.sheet_add_aoa(worksheet, [[{ t: 'n', v: _alimentos.Alimentos[j].TPL, z: '$ #,##0.00' }]], { origin: "O" + jj });
              worksheet["O" + jj].s = styleBody;
              
              jj += 1;
              
              ll +=1; 
            }
          }
  
          jj = ii;
        let _bndPrimer = false;
        let _bndVacioAI = false;
          for (var k = 0; k <= _alimentosIndividuales.AlimentosIndividuales.length - 1; k++) {
            if (_alimentosIndividuales.AlimentosIndividuales[k].Costo != 0 || _alimentosIndividuales.AlimentosIndividuales[k].Menor != 0) {
  
              XLSX.utils.sheet_add_aoa(worksheet, [[_alimentosIndividuales.AlimentosIndividuales[k].TipoAlimentoIndividual]], { origin: "R" + jj });
              XLSX.utils.sheet_add_aoa(worksheet, [[{ t: 'n', v: _alimentosIndividuales.AlimentosIndividuales[k].Costo, z: '$ #,##0.00' }]], { origin: "S" + jj });
              XLSX.utils.sheet_add_aoa(worksheet, [[{ t: 'n', v: _alimentosIndividuales.AlimentosIndividuales[k].Menor, z: '$ #,##0.00' }]], { origin: "T" + jj });
  
                if (_bndPrimer == false) {
                  worksheet["R" + jj].s = styleBody;
                  worksheet["S" + jj].s = styleBody;
                  worksheet["T" + jj].s = styleBody; 
                  _bndPrimer = true;
   
                } else {
                  worksheet["R" + jj].s = styleBodyNoTopBorder;
                  worksheet["S" + jj].s = styleBodyNoTopBorder;
                  worksheet["T" + jj].s = styleBodyNoTopBorder; 
                }


              jj += 1;
              
              mm +=1;
              _bndVacioAI = false;

            } else {
              _bndVacioAI = true;
            }

          }

          if (_bndVacioAI == true) {
            XLSX.utils.sheet_add_aoa(worksheet, [['']], { origin: "R" + jj });
            worksheet["R" + jj].s = styleBody;
            XLSX.utils.sheet_add_aoa(worksheet, [['']], { origin: "S" + jj });
            worksheet["S" + jj].s = styleBody;
            XLSX.utils.sheet_add_aoa(worksheet, [['']], { origin: "T" + jj });
            worksheet["T" + jj].s = styleBody;
          }
            
  
          if (ll >= mm) {
            ii += ll;
            merge = ll;
          } else {
            ii += mm;
            merge = mm;
          }
  
  
        } else {
          ii += 1;
          jj += 1;
  
        }
      }

    }


    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };


    workbook.Sheets['data']['!protect'] = {
      password: 'Ketzaltour**', // Opcional: establece una contraseña si lo deseas
      formatCells: false, // Bloquea el formato de celdas
      formatColumns: false, // Bloquea el formato de columnas
      formatRows: false, // Bloquea el formato de filas
    };

    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.operacionRespuesta.EstaEjecutando = false;

    this.saveAsExcel(excelBuffer, 'tarifario_' + fechaHoraLegible);
  }


  private saveAsExcel(buffer: any, fileName: string): void {
    this.operacionRespuesta.EstaEjecutando = false;
    
    const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXT)

  }



  getEdos(idPais :number) {
    this.obtenerCatalogosCortos('ESTADO', 0);
  }

  getMun(idEdo :number) {
    this.obtenerCatalogosCortos('MUNICIPIO', idEdo);
  }

  obtenerCatalogosCortos(p_catalogo, p_idcatologo) {
    this.operacionRespuesta.EstaEjecutando = true;
    this.services
      .HttpGet(
        this.modelo_configuracion.serviciosOperaciones + "/Catalogos/GetListaGeneralCorto?p_catalogo="+ p_catalogo +"&p_idcatalogo="+ p_idcatologo)
      .subscribe(
        (tempdate) => {
          if (tempdate) {

            switch (p_catalogo) {
              case 'ESTADO':
                this.listaEdos = [] = tempdate.response as Lista_general_corto_modelo_response[];
                break;
              case 'MUNICIPIO':
                this.listaMun = [] = tempdate.response as Lista_general_corto_modelo_response[];
                break;
                case 'CC':
                  this.listaCatalogos = [] = tempdate.response as Lista_general_corto_modelo_response[];
                  break;
              }

          }
          this.operacionRespuesta.EstaEjecutando = false;
        },
        async (err) => {
          this.operacionRespuesta.EstaEjecutando = false;
        }
      );
  }

  obtenerCatalogosGenericos(idCatMaestro) {
    this.operacionRespuesta.EstaEjecutando = true;
    this.services
      .HttpGet(
        this.modelo_configuracion.serviciosOperaciones + "/Catalogos/GetListaCatalogoCorto?p_idCatMaestro="+ idCatMaestro)
      .subscribe(
        (tempdate) => {
          if (tempdate) {
        
            switch (idCatMaestro) {
              case 34:
                this.listaCatalogosFU = [] = tempdate.response as Lista_catalogo_corto_modelo_response[];
                break;
            }
            
          } else {
            this.listaCatalogos = [];
          }
          this.operacionRespuesta.EstaEjecutando = false;
        },
        async (err) => {
          this.operacionRespuesta.EstaEjecutando = false;
        }
      );
  }

  onCheckboxChange(event: any) {
    const checkboxValue = event.target.value;
    if (event.target.checked) {
      this.showFU = true;
    } else {
      this.showFU = false;
    }
  }

  getListaProveedores(tipoProveedor) {
    this.operacionRespuesta.EstaEjecutando = true;
    this.services
      .HttpGet(
        this.modelo_configuracion.serviciosOperaciones + "/Generales/GetListaProveedoresCorto?p_tipoProveedor="+ tipoProveedor)
      .subscribe(
        (tempdate) => {
          if (tempdate) {
            this.listaProveedores = tempdate.response as Lista_proveedores_modelo_response[];
          }
          this.operacionRespuesta.EstaEjecutando = false;
        },
        async (err) => {
          this.operacionRespuesta.EstaEjecutando = false;
        }
      );
  }
}
