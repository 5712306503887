import { Component, OnInit } from '@angular/core';
import { AuthGuard } from '../guards/AuthGuard';
import { Router } from '@angular/router';
import { AuthService } from 'src/services/auth.service';
import { LocalStorageService } from 'src/services/local-storage.service';
import { AuthIdentity } from '../guards/AuthIdentity';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css'],
  providers: [AuthGuard],
})
export class MenuComponent implements OnInit {

  public isAuth: boolean;
  public banner:string;
  opcionMenu: number = 0;
  private _bndCatalogo: boolean;
  nombreUsuario: any;
  idUsuario: number;
  idPerfil: number;
  constructor(
    private auth: AuthGuard,
    private router: Router,
    private authService: AuthService,
    private localStorageService: LocalStorageService,
  ) { }

  ngOnInit() {
    this.isAuth = this.auth.canActivate();
    if (this.isAuth === false) {
        this.cerrarSesion();
    } else {
      this.authService.estatusActualDelUsuarioEnSesion$().subscribe((isAuth) => {
        this.isAuth = isAuth;
        this.abrirMenu('0');
        this._bndCatalogo = false;
      });
  
      var usuarioActual = AuthIdentity.ObtenerUsuarioSesion();
      this.nombreUsuario = usuarioActual.Nombre + ' ' + usuarioActual.APaterno + ' ' + usuarioActual.AMaterno;
      this.idUsuario = usuarioActual.IDUsuario;
      this.idPerfil = usuarioActual.IDPerfil;
    }
  }

  public abrirMenu(menu: string) {
    this.opcionMenu

    switch (menu) {
      case 'catMaestro':
        this.opcionMenu = 1;
        break;
      case 'catProveedores':
        this.opcionMenu = 2;
        break;
      case 'catPaises':
        this.opcionMenu = 3;
        break;
      case 'catEstados':
        this.opcionMenu = 4;
        break;
      case 'catCiudades':
        this.opcionMenu = 5;
        break;
      case 'catCategorias':
        this.opcionMenu = 6;
        break;
      case 'catClientes':
        this.opcionMenu = 7;
        break;
      case 'catGrupos':
        this.opcionMenu = 8;
        break;
      case 'capTarifa':
        this.opcionMenu = 9;
        break;
      case 'AutTarifaHosp':
        this.opcionMenu = 10;
        break;
      case 'AutTarifaTrans':
        this.opcionMenu = 11;
        break;
      case 'GenTarifarioHosp':
        this.opcionMenu = 12;
        break;
      case 'GenTarifarioTrans':
        this.opcionMenu = 13;
        break;
      case 'hospedaje':
        this.opcionMenu = 14;
        break;
      case 'transportadora':
        this.opcionMenu = 15;
        break;
      case 'otros':
        this.opcionMenu = 16;
        break;
      case 'reportes':
        this.opcionMenu = 17;
        break;
        case 'usuarios':
          this.opcionMenu = 18;
          break;
        case 'todos':
          this.opcionMenu = 19;
          break;
          case 'capTarifaTrans':
            this.opcionMenu = 20;
            break;
            case 'GenTarifarioTrans':
              this.opcionMenu = 21;
              break
      default:
        this.opcionMenu = 0; // Opcional: asignar un valor por defecto si no coincide con ninguna opción.
        break;
    }

    
  }

  public cerrarSesion(): void {
    this.auth.logOut();
    this.authService.EsEstaAutenticado(false);
    this.localStorageService.clearToken();

    this.router.navigate(["/login"]);
  }
}
