import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { ThemeConstants } from 'src/app/@espire/shared/config/theme-constant';
import { RespuestaGenerica } from 'src/app/generales/RespuestaGenerica';
import { ServiciosRutas } from 'src/app/generales/ServiciosRutas';
import { AuthGuard } from 'src/app/guards/AuthGuard';
import { ModuloModalMensajeComponent } from 'src/app/shared/modulo-modal-mensaje/modulo-modal-mensaje.component';
import { Lista_usuarios_sistema_modelo_response } from 'src/model/Catalogos/Colaboradores';
import { Cupon_hospedaje_modelo_response, Lista_cupones_hospedaje_modelo_response, Lista_proveedores_modelo_response, Lista_tipo_habitacion_modelo_response } from 'src/model/Catalogos/Cupones';
import { ServiceGenerico } from 'src/services/service-generico.service';
import * as html2pdf from 'html2pdf.js';
import { AuthIdentity } from 'src/app/guards/AuthIdentity';
import { retry } from 'rxjs/operators';
import { exit } from 'process';
import { each } from 'jquery';

@Component({
  selector: 'app-hospedaje',
  templateUrl: './hospedaje.component.html',
  styleUrls: ['./hospedaje.component.css'],
  providers: [ServiceGenerico]
})
export class HospedajeComponent implements OnInit {
  @ViewChild("modalAddCouponHospedaje", { static: false }) modalAddCouponHospedaje: TemplateRef<any>;
  @ViewChild("contentDesactivar", { static: false }) ModalDesactivar: TemplateRef<any>;
  @ViewChild("contentInhabilitar", { static: false }) ModalInhabilitar: TemplateRef<any>;
  @ViewChild("contentActivar", { static: false }) ModalActivar: TemplateRef<any>;
  @ViewChild("contentAutorizar", { static: false }) ModalAutorizar: TemplateRef<any>;
  @ViewChild("contentCuponPDF", { static: false }) ModalCuponPDFH: TemplateRef<any>;
  modalrefMsg: NgbModalRef;
  modalAgregarCuponHospedaje: NgbModalRef;
  modalEliminarCupon: NgbModalRef;
  modalInhabilitarCupon: NgbModalRef;
  modalActivarCupon: NgbModalRef;
  modalAutorizarCupon: NgbModalRef;
  modalCuponPDFH: NgbModalRef;

  formGroupAgregarCuponHospedaje: FormGroup;

  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  isDtInitialized: boolean = false;
  public archivos: any = [];
  public usuarioId: string = "";

  private modelo_configuracion: ServiciosRutas;
  listaCupones: Lista_cupones_hospedaje_modelo_response[];
  Colaborador: Cupon_hospedaje_modelo_response;
  public operacionRespuesta: RespuestaGenerica;
  esEditable: boolean = true;
  funcionalidad: string;
  _idCatCupon: number;
  private _nombreCup: any;
  listaProveedores: Lista_proveedores_modelo_response[];
  listaTipoHabitacion: Lista_tipo_habitacion_modelo_response[];
  listaUsuarios: Lista_usuarios_sistema_modelo_response[];
  fechaEntrada: Date;
  fechaSalida: Date;
  pdfCupon: Cupon_hospedaje_modelo_response;
  listaUsuariosRealiza: Lista_usuarios_sistema_modelo_response[];
  listaUsuariosRevisa: Lista_usuarios_sistema_modelo_response[];
  listaUsuariosAutoriza: Lista_usuarios_sistema_modelo_response[];
  idUsuario: number;
  idUsuarioElabora: number = 0;
  idUsuarioReviso: number = 0;
  idUsuarioAutorizo: number = 0;
  puedeEditar: boolean = false;
  numbreAutorizo: string;
  selectedCupones: string[];
  activarAutorizado: boolean = false; 
  // nombreUsuarioElabora: string;

  filteredProveedores: Lista_proveedores_modelo_response[] = [];
  searchText = '';



  constructor(
    private auth: AuthGuard,
    private themeConstants: ThemeConstants,
    private services: ServiceGenerico,
    private router: Router,
    private modalService: NgbModal,
    private fb: FormBuilder,
    private sanitizer: DomSanitizer,
  ) {
    this.modelo_configuracion = new ServiciosRutas();
    this.operacionRespuesta = new RespuestaGenerica();
  }

  ngOnInit() {
    this.inicializaTabla();
    this.obtenerCupones();
    this.inicializarFormAgregarCuponHospedaje();
    this.getListaProveedoresHospedaje();
    this.getListaTipoHabitacion();
    this.obtenerUsuarios();

    var usuarioActual = AuthIdentity.ObtenerUsuarioSesion();
    this.idUsuario = usuarioActual.IDUsuario;
  }

  inicializaTabla() {
    this.dtOptions = this.themeConstants.dtOptions;
    this.dtTrigger = new Subject();
  }

  renderTabla() {
    if ("dtInstance" in this.dtElement) {
      this.dtElement.dtInstance.then((instancia: DataTables.Api) => {
        instancia.destroy();
        this.dtTrigger.next();
      });
    } else {
      this.dtTrigger.next();
    }
  }

  validatehotel(control) {
    if (control.value === 0) {
      return { 'invalidValue': true };
    }
    return null;
  }

  obtenerCupones() {
    this.operacionRespuesta.EstaEjecutando = true;
    this.services
      .HttpGet(
        this.modelo_configuracion.serviciosOperaciones + "/Cupones/GetListaCupones?p_opcion=1")
      .subscribe(
        (tempdate) => {
          if (tempdate) {
            this.listaCupones = [] = tempdate.response as Lista_cupones_hospedaje_modelo_response[];
            this.renderTabla();
          } else {
            this.listaCupones = [];
            this.renderTabla();
          }
          this.operacionRespuesta.EstaEjecutando = false;
        },
        async (err) => {
          this.operacionRespuesta.EstaEjecutando = false;
        }
      );
  }

  inicializarFormAgregarCuponHospedaje() {
    this.formGroupAgregarCuponHospedaje = this.fb.group({
      p_IDCupon: [0],
      p_File: ["", Validators.required],
      p_FechaRegistro: [""],
      p_NombrePax: ["", Validators.required],
      p_NumAdulto: [0],
      p_NumMenores: [0],
      p_EdadMenores: [""],
      p_NumBebes: [0],
      p_Comentarios: [""],
      p_ComentariosKetzal: [""],
      p_Elaboro: [0, Validators.required],
      p_Reviso: [0],
      p_Autorizo: [0],
      p_IDProveedor: [0, this.validatehotel],
      p_FEntrada: [""],
      p_FSalida: [""],
      p_Noche: [0],
      p_IDTipoHabitacion: [0],
      p_SGL: [0],
      p_DBL: [0],
      p_TPL: [0],
      p_TWIN: [0],
      p_Otros: [""],
      p_totalPax: [0],
      p_AL: [false],
      p_AB: [false],
      p_FAP: [false],
      p_SAI: [false],
      p_BB: [false],
      p_BL: [false],
      p_BI: [false],
      p_MAPComida: [false],
      p_MAPCena: [false],
      p_CB: [false],
      p_EP: [false],
      p_DD: [false],
      p_MID: [false],
      p_LL: [false],
      p_ClaveHotel: [""],
      p_Camaristas: [false],
      p_BellBoys: [false],
      p_Meseros: [false],
      p_ImpuestoInc: [false],
      p_extrasPagadosPasajero: [false],
      searchText: '',
    });

  }

  getListaProveedoresHospedaje() {
    this.operacionRespuesta.EstaEjecutando = true;
    this.services
      .HttpGet(
        this.modelo_configuracion.serviciosOperaciones + "/Generales/GetListaProveedoresCorto?p_tipoProveedor=1")
      .subscribe(
        (tempdate) => {
          if (tempdate) {
            this.listaProveedores = tempdate.response as Lista_proveedores_modelo_response[];
          }
          this.operacionRespuesta.EstaEjecutando = false;
        },
        async (err) => {
          this.operacionRespuesta.EstaEjecutando = false;
        }
      );
  }

  filterProveedores(): void {
    this.searchText = this.formGroupAgregarCuponHospedaje.get('searchText').value;
    this.filteredProveedores = this.listaProveedores.filter(proveedor => proveedor && proveedor.nombre && proveedor.nombre.toLowerCase().includes(this.searchText.toLowerCase()));
    }
  

  clearSearch(): void {
    this.searchText = '';
    this.formGroupAgregarCuponHospedaje.get('searchText').setValue('');
    this.filteredProveedores = this.listaProveedores;
  }

  getListaTipoHabitacion() {
    this.operacionRespuesta.EstaEjecutando = true;
    this.services
      .HttpGet(
        this.modelo_configuracion.serviciosOperaciones + "/Catalogos/GetListaGeneralCorto?p_catalogo=TIPOHAB&p_idcatalogo=1")
      .subscribe(
        (tempdate) => {
          if (tempdate) {
            this.listaTipoHabitacion = tempdate.response as Lista_tipo_habitacion_modelo_response[];
          }
          this.operacionRespuesta.EstaEjecutando = false;
        },
        async (err) => {
          this.operacionRespuesta.EstaEjecutando = false;
        }
      );
  }
  obtenerUsuarios() {
    this.operacionRespuesta.EstaEjecutando = true;
    this.services
      .HttpGet(
        this.modelo_configuracion.serviciosOperaciones + "/Generales/GetListaUsuariosCorto?p_opcion=1")
      .subscribe(
        (tempdate) => {
          if (tempdate) {
            this.listaUsuariosRealiza = [] = tempdate.response as Lista_usuarios_sistema_modelo_response[];
            tempdate.response.forEach(_usuario => {
              if (_usuario.idUsuario === this.idUsuario) {
                this.idUsuarioElabora = _usuario.idUsuario;
              }
            });

          } else {
            this.listaUsuariosRealiza = [];
          }
          this.operacionRespuesta.EstaEjecutando = false;
        },
        async (err) => {
          this.operacionRespuesta.EstaEjecutando = false;
        }
      );

    this.services
      .HttpGet(
        this.modelo_configuracion.serviciosOperaciones + "/Generales/GetListaUsuariosCorto?p_opcion=2")
      .subscribe(
        (tempdate) => {
          if (tempdate) {
            this.listaUsuariosRevisa = [] = tempdate.response as Lista_usuarios_sistema_modelo_response[];
            tempdate.response.forEach(_usuario => {
              if (_usuario.idUsuario === this.idUsuario) {
                this.idUsuarioReviso = _usuario.idUsuario;
              }
            });
          } else {
            this.listaUsuariosRevisa = [];
          }
          this.operacionRespuesta.EstaEjecutando = false;
        },
        async (err) => {
          this.operacionRespuesta.EstaEjecutando = false;
        }
      );

    this.services
      .HttpGet(
        this.modelo_configuracion.serviciosOperaciones + "/Generales/GetListaUsuariosCorto?p_opcion=3")
      .subscribe(
        (tempdate) => {
          if (tempdate) {
            this.listaUsuariosAutoriza = [] = tempdate.response as Lista_usuarios_sistema_modelo_response[];
            tempdate.response.forEach(_usuario => {
              if (_usuario.idUsuario === this.idUsuario) {
                this.idUsuarioAutorizo = _usuario.idUsuario;
                this.numbreAutorizo = _usuario.nombre;
                this.puedeEditar = true;
              }
            });
          } else {
            this.listaUsuariosAutoriza = [];
          }
          this.operacionRespuesta.EstaEjecutando = false;
        },
        async (err) => {
          this.operacionRespuesta.EstaEjecutando = false;
        }
      );
  }
  agregarCuponHospedaje() {
    this.funcionalidad = 'Nuevo';
    this._idCatCupon = 0;
    this.openModalFormCuponHospedaje(true);
  }

  calcularSuma(): void {
    this.formGroupAgregarCuponHospedaje.value.p_totalPax = this.formGroupAgregarCuponHospedaje.value.p_NumAdulto + this.formGroupAgregarCuponHospedaje.value.p_NumMenores + this.formGroupAgregarCuponHospedaje.value.p_NumBebes;
    $('#p_totalPax').val(this.formGroupAgregarCuponHospedaje.value.p_totalPax);
    this.formGroupAgregarCuponHospedaje.get('p_totalPax').setValue(this.formGroupAgregarCuponHospedaje.value.p_totalPax);
  }

  calculaNoches(): void {
    this.fechaEntrada = new Date(this.formGroupAgregarCuponHospedaje.value.p_FEntrada);
    this.fechaSalida = new Date(this.formGroupAgregarCuponHospedaje.value.p_FSalida);

    const diferenciaMilisegundos = this.fechaSalida.getTime() - this.fechaEntrada.getTime();
    const noches = Math.floor(diferenciaMilisegundos / (1000 * 60 * 60 * 24));
    $('#p_Noche').val(noches);
    this.formGroupAgregarCuponHospedaje.get('p_Noche').setValue(noches);
  }

  onCheckboxChange(event: any) {
    const checkboxValue = event.target.value;

    if (event.target.checked) {
      // Agregar el valor al array de checkboxes seleccionados
      this.formGroupAgregarCuponHospedaje.get(checkboxValue).setValue(true);
    } else {
      // Eliminar el valor del array de checkboxes seleccionados
      this.formGroupAgregarCuponHospedaje.get(checkboxValue).setValue(false);
    }
  }
  onCheckboxChangeMAP(event: any) {
    const checkboxValue = event.target.value;

    if (event.target.checked) {
      $("#_MAPComida").show();
      $("#_MAPCena").show();
    } else {
      $("#_MAPComida").hide();
      $("#_MAPCena").hide();
      $('#p_MAPComida').prop('checked', false);
      $('#p_MAPCena').prop('checked', false);
      this.formGroupAgregarCuponHospedaje.get('p_MAPComida').setValue(false);
      this.formGroupAgregarCuponHospedaje.get('p_MAPCena').setValue(false);
    }

  }
  onCheckboxChangeTodos(event: any) {
    const checkboxValue = event.target.value;

    if (event.target.checked) {
      $('#p_Camaristas').prop('checked', true);
      $('#p_BellBoys').prop('checked', true);
      $('#p_Meseros').prop('checked', true);
      $('#p_ImpuestoInc').prop('checked', true);
      $('#p_extrasPagadosPasajero').prop('checked', true);
      this.formGroupAgregarCuponHospedaje.get('p_Camaristas').setValue(true);
      this.formGroupAgregarCuponHospedaje.get('p_BellBoys').setValue(true);
      this.formGroupAgregarCuponHospedaje.get('p_Meseros').setValue(true);
      this.formGroupAgregarCuponHospedaje.get('p_ImpuestoInc').setValue(true);
      this.formGroupAgregarCuponHospedaje.get('p_extrasPagadosPasajero').setValue(true);
    } else {
      $('#p_Camaristas').prop('checked', false);
      $('#p_BellBoys').prop('checked', false);
      $('#p_Meseros').prop('checked', false);
      $('#p_ImpuestoInc').prop('checked', false);
      $('#p_extrasPagadosPasajero').prop('checked', false);
      this.formGroupAgregarCuponHospedaje.get('p_Camaristas').setValue(false);
      this.formGroupAgregarCuponHospedaje.get('p_BellBoys').setValue(false);
      this.formGroupAgregarCuponHospedaje.get('p_Meseros').setValue(false);
      this.formGroupAgregarCuponHospedaje.get('p_ImpuestoInc').setValue(false);
      this.formGroupAgregarCuponHospedaje.get('p_extrasPagadosPasajero').setValue(false);
    }

  }


  GuardarCuponHospedaje() {
    this.operacionRespuesta.EstaEjecutando = true;
    let model = this.formGroupAgregarCuponHospedaje.value;
    model.p_idCatCupon = this._idCatCupon;

    if (this.funcionalidad == 'Nuevo') {
      this.services.HttpPost(model, this.modelo_configuracion.serviciosOperaciones + "/Cupones/PostCuponHospedaje")
        .subscribe(
          (tempdate) => {
            if (tempdate.response[0].success == 1) {
              this.openMensajes(tempdate.response[0].msg, false, '');
              this.obtenerCupones();
              this.modalAgregarCuponHospedaje.close();
            } else {
              this.openMensajes(tempdate.response[0].msg, true, '');
            }
            this.operacionRespuesta.EstaEjecutando = false;
          },
          async (err) => {
            this.openMensajes("No se pudo realizar la acción", true, '');
            this.operacionRespuesta.EstaEjecutando = false;
          }
        );
    } else {
      this.services.HttpPut(model, this.modelo_configuracion.serviciosOperaciones + "/Cupones/PutCuponHospedaje")
        .subscribe(
          (tempdate) => {
            if (tempdate.response[0].success == 1) {
              this.openMensajes(tempdate.response[0].msg, false, '');
              this.obtenerCupones();
              this.modalAgregarCuponHospedaje.close();
            } else {
              this.openMensajes(tempdate.response[0].msg, true, '');
            }
            this.operacionRespuesta.EstaEjecutando = false;
          },
          async (err) => {
            this.openMensajes("No se pudo realizar la acción", true, '');
            this.operacionRespuesta.EstaEjecutando = false;
          }
        );
    }
  }

  verDetalleCuponHospedaje(idCatCupon) {
    this.esEditable = false;
    this.openModalFormCuponHospedaje(false);
    this.operacionRespuesta.EstaEjecutando = true;
    this.services.HttpGet(this.modelo_configuracion.serviciosOperaciones + "/Cupones/GetCupon_x_id_Hospedaje?p_idcupon=" + idCatCupon)
      .subscribe(
        (tempdate) => {
          if (tempdate) {
            this.Colaborador = tempdate.response as Cupon_hospedaje_modelo_response;
            this.formGroupAgregarCuponHospedaje.value.p_IDCupon = this.Colaborador[0].idCupon;
            const fechaCorta = this.Colaborador[0].fechaRegistro.split('T')[0];
            this.formGroupAgregarCuponHospedaje.value.p_FechaRegistro = fechaCorta;
            this.formGroupAgregarCuponHospedaje.value.p_IDTipoProveedor = this.Colaborador[0].idTipoProveedor;
            this.formGroupAgregarCuponHospedaje.value.p_File = this.Colaborador[0].file;
            this.formGroupAgregarCuponHospedaje.value.p_NombrePax = this.Colaborador[0].nombrePax;
            this.formGroupAgregarCuponHospedaje.value.p_NumAdulto = this.Colaborador[0].numAdulto;
            this.formGroupAgregarCuponHospedaje.value.p_NumMenores = this.Colaborador[0].numMenores;
            this.formGroupAgregarCuponHospedaje.value.p_NumBebes = this.Colaborador[0].numBebes;
            this.formGroupAgregarCuponHospedaje.value.p_EdadMenores = this.Colaborador[0].edadMenores;
            this.formGroupAgregarCuponHospedaje.value.p_IDProveedor = this.Colaborador[0].idProveedor;
            const fEntradaCorta = this.Colaborador[0].fEntrada.split('T')[0];
            const fSalidaCorta = this.Colaborador[0].fSalida.split('T')[0];
            this.formGroupAgregarCuponHospedaje.value.p_FEntrada = fEntradaCorta;
            this.formGroupAgregarCuponHospedaje.value.p_FSalida = fSalidaCorta;
            this.formGroupAgregarCuponHospedaje.value.p_IDTipoHabitacion = this.Colaborador[0].idTipoHabitacion;
            this.formGroupAgregarCuponHospedaje.value.p_totalPax = this.Colaborador[0].totalPax;
            this.formGroupAgregarCuponHospedaje.value.p_Noche = this.Colaborador[0].noche;
            this.formGroupAgregarCuponHospedaje.value.p_SGL = this.Colaborador[0].sgl;
            this.formGroupAgregarCuponHospedaje.value.p_DBL = this.Colaborador[0].dbl;
            this.formGroupAgregarCuponHospedaje.value.p_TPL = this.Colaborador[0].tpl;
            this.formGroupAgregarCuponHospedaje.value.p_TWIN = this.Colaborador[0].twin;
            this.formGroupAgregarCuponHospedaje.value.p_Otros = this.Colaborador[0].otros;
            this.formGroupAgregarCuponHospedaje.value.p_AL = this.Colaborador[0].al;
            this.formGroupAgregarCuponHospedaje.value.p_AB = this.Colaborador[0].ab;
            this.formGroupAgregarCuponHospedaje.value.p_FAP = this.Colaborador[0].fap;
            this.formGroupAgregarCuponHospedaje.value.p_SAI = this.Colaborador[0].sai;
            this.formGroupAgregarCuponHospedaje.value.p_BB = this.Colaborador[0].bb;
            this.formGroupAgregarCuponHospedaje.value.p_BL = this.Colaborador[0].bl;
            this.formGroupAgregarCuponHospedaje.value.p_BI = this.Colaborador[0].bi;
            this.formGroupAgregarCuponHospedaje.value.p_MAPComida = this.Colaborador[0].mapComida;
            this.formGroupAgregarCuponHospedaje.value.p_MAPCena = this.Colaborador[0].mapCena;
            this.formGroupAgregarCuponHospedaje.value.p_CB = this.Colaborador[0].cb;
            this.formGroupAgregarCuponHospedaje.value.p_EP = this.Colaborador[0].ep;
            this.formGroupAgregarCuponHospedaje.value.p_DD = this.Colaborador[0].dd;
            this.formGroupAgregarCuponHospedaje.value.p_MID = this.Colaborador[0].mid;
            this.formGroupAgregarCuponHospedaje.value.p_LL = this.Colaborador[0].ll;
            $('#p_AL').prop('checked', this.Colaborador[0].al);
            $('#p_AB').prop('checked', this.Colaborador[0].ab);
            $('#p_FAP').prop('checked', this.Colaborador[0].fap);
            $('#p_SAI').prop('checked', this.Colaborador[0].sai);
            $('#p_BB').prop('checked', this.Colaborador[0].bb);
            $('#p_BL').prop('checked', this.Colaborador[0].bl);
            $('#p_BI').prop('checked', this.Colaborador[0].bi);
            if (this.Colaborador[0].mapComida == true) {
              $("#_MAPComida").show();
              $('#p_MAP').prop('checked', true);
            } else {
              $('#p_MAP').prop('checked', false);
              $("#_MAPComida").hide();
            }
            if (this.Colaborador[0].mapCena == true) {
              $('#p_MAP').prop('checked', true);
              $("#_MAPCena").show();
            } else {
              $('#p_MAP').prop('checked', false);
              $("#_MAPCena").hide();
            }
            $('#p_MAPComida').prop('checked', this.Colaborador[0].mapComida);
            $('#p_MAPCena').prop('checked', this.Colaborador[0].mapCena);
            $('#p_CB').prop('checked', this.Colaborador[0].cb);
            $('#p_EP').prop('checked', this.Colaborador[0].ep);
            $('#p_DD').prop('checked', this.Colaborador[0].dd);
            $('#p_MID').prop('checked', this.Colaborador[0].mid);
            $('#p_LL').prop('checked', this.Colaborador[0].ll);
            this.formGroupAgregarCuponHospedaje.value.p_Camaristas = this.Colaborador[0].camaristas;
            this.formGroupAgregarCuponHospedaje.value.p_BellBoys = this.Colaborador[0].bellBoys;
            this.formGroupAgregarCuponHospedaje.value.p_Meseros = this.Colaborador[0].meseros;
            this.formGroupAgregarCuponHospedaje.value.p_ImpuestoInc = this.Colaborador[0].impuestoInc;
            this.formGroupAgregarCuponHospedaje.value.p_extrasPagadosPasajero = this.Colaborador[0].extrasPagadosPasajero;
            $('#p_Camaristas').prop('checked', this.Colaborador[0].camaristas);
            $('#p_BellBoys').prop('checked', this.Colaborador[0].bellBoys);
            $('#p_Meseros').prop('checked', this.Colaborador[0].meseros);
            $('#p_ImpuestoInc').prop('checked', this.Colaborador[0].impuestoInc);
            $('#p_extrasPagadosPasajero').prop('checked', this.Colaborador[0].extrasPagadosPasajero);

            this.formGroupAgregarCuponHospedaje.value.p_ClaveHotel = this.Colaborador[0].claveHotel;
            this.formGroupAgregarCuponHospedaje.value.p_Comentarios = this.Colaborador[0].comentarios;
            this.formGroupAgregarCuponHospedaje.value.p_ComentariosKetzal = this.Colaborador[0].comentariosKetzal;
            this.formGroupAgregarCuponHospedaje.value.p_Elaboro = this.Colaborador[0].elaboro;
            this.formGroupAgregarCuponHospedaje.value.p_Reviso = this.Colaborador[0].reviso;
            this.formGroupAgregarCuponHospedaje.value.p_Autorizo = this.Colaborador[0].autorizo;

            this.formGroupAgregarCuponHospedaje.patchValue(this.formGroupAgregarCuponHospedaje.value);
          } else {
            this.listaCupones = [];
          }
          this.operacionRespuesta.EstaEjecutando = false;
        },
        async (err) => {
          this.operacionRespuesta.EstaEjecutando = false;
        }
      );
  }
  editarCuponHospedaje(idCatCupon) {
    this.funcionalidad = 'Editar';
    this._idCatCupon = idCatCupon;
    this.openModalFormCuponHospedaje(true);
    this.operacionRespuesta.EstaEjecutando = true;
    this.services.HttpGet(this.modelo_configuracion.serviciosOperaciones + "/Cupones/GetCupon_x_id_Hospedaje?p_idcupon=" + idCatCupon)
      .subscribe(
        (tempdate) => {
          if (tempdate) {
            this.Colaborador = tempdate.response as Cupon_hospedaje_modelo_response;
            this.formGroupAgregarCuponHospedaje.value.p_IDCupon = this.Colaborador[0].idCupon;
            const fechaCorta = this.Colaborador[0].fechaRegistro.split('T')[0];
            this.formGroupAgregarCuponHospedaje.value.p_FechaRegistro = fechaCorta;
            this.formGroupAgregarCuponHospedaje.value.p_IDTipoProveedor = this.Colaborador[0].idTipoProveedor;
            this.formGroupAgregarCuponHospedaje.value.p_File = this.Colaborador[0].file;
            this.formGroupAgregarCuponHospedaje.value.p_NombrePax = this.Colaborador[0].nombrePax;
            this.formGroupAgregarCuponHospedaje.value.p_NumAdulto = this.Colaborador[0].numAdulto;
            this.formGroupAgregarCuponHospedaje.value.p_NumMenores = this.Colaborador[0].numMenores;
            this.formGroupAgregarCuponHospedaje.value.p_NumBebes = this.Colaborador[0].numBebes;
            this.formGroupAgregarCuponHospedaje.value.p_EdadMenores = this.Colaborador[0].edadMenores;
            this.formGroupAgregarCuponHospedaje.value.p_IDProveedor = this.Colaborador[0].idProveedor;
            const fEntradaCorta = this.Colaborador[0].fEntrada.split('T')[0];
            const fSalidaCorta = this.Colaborador[0].fSalida.split('T')[0];
            this.formGroupAgregarCuponHospedaje.value.p_FEntrada = fEntradaCorta;
            this.formGroupAgregarCuponHospedaje.value.p_FSalida = fSalidaCorta;
            this.formGroupAgregarCuponHospedaje.value.p_IDTipoHabitacion = this.Colaborador[0].idTipoHabitacion;
            this.formGroupAgregarCuponHospedaje.value.p_totalPax = this.Colaborador[0].totalPax;
            this.formGroupAgregarCuponHospedaje.value.p_Noche = this.Colaborador[0].noche;
            this.formGroupAgregarCuponHospedaje.value.p_SGL = this.Colaborador[0].sgl;
            this.formGroupAgregarCuponHospedaje.value.p_DBL = this.Colaborador[0].dbl;
            this.formGroupAgregarCuponHospedaje.value.p_TPL = this.Colaborador[0].tpl;
            this.formGroupAgregarCuponHospedaje.value.p_TWIN = this.Colaborador[0].twin;
            this.formGroupAgregarCuponHospedaje.value.p_Otros = this.Colaborador[0].otros;
            this.formGroupAgregarCuponHospedaje.value.p_AL = this.Colaborador[0].al;
            this.formGroupAgregarCuponHospedaje.value.p_AB = this.Colaborador[0].ab;
            this.formGroupAgregarCuponHospedaje.value.p_FAP = this.Colaborador[0].fap;
            this.formGroupAgregarCuponHospedaje.value.p_SAI = this.Colaborador[0].sai;
            this.formGroupAgregarCuponHospedaje.value.p_BB = this.Colaborador[0].bb;
            this.formGroupAgregarCuponHospedaje.value.p_BL = this.Colaborador[0].bl;
            this.formGroupAgregarCuponHospedaje.value.p_BI = this.Colaborador[0].bi;
            this.formGroupAgregarCuponHospedaje.value.p_MAPComida = this.Colaborador[0].mapComida;
            this.formGroupAgregarCuponHospedaje.value.p_MAPCena = this.Colaborador[0].mapCena;
            if (this.Colaborador[0].mapComida == true) {
              $("#_MAPComida").show();
              $('#p_MAP').prop('checked', true);
            } else {
              $('#p_MAP').prop('checked', false);
              $("#_MAPComida").hide();
            }
            if (this.Colaborador[0].mapCena == true) {
              $('#p_MAP').prop('checked', true);
              $("#_MAPCena").show();
            } else {
              $('#p_MAP').prop('checked', false);
              $("#_MAPCena").hide();
            }
            $('#p_MAPComida').prop('checked', this.Colaborador[0].mapComida);
            $('#p_MAPCena').prop('checked', this.Colaborador[0].mapCena);
            this.formGroupAgregarCuponHospedaje.value.p_CB = this.Colaborador[0].cb;
            this.formGroupAgregarCuponHospedaje.value.p_EP = this.Colaborador[0].ep;
            this.formGroupAgregarCuponHospedaje.value.p_DD = this.Colaborador[0].dd;
            this.formGroupAgregarCuponHospedaje.value.p_MID = this.Colaborador[0].mid;
            this.formGroupAgregarCuponHospedaje.value.p_LL = this.Colaborador[0].ll;
            $('#p_AL').prop('checked', this.Colaborador[0].al);
            $('#p_AB').prop('checked', this.Colaborador[0].ab);
            $('#p_FAP').prop('checked', this.Colaborador[0].fap);
            $('#p_SAI').prop('checked', this.Colaborador[0].sai);
            $('#p_BB').prop('checked', this.Colaborador[0].bb);
            $('#p_BL').prop('checked', this.Colaborador[0].bl);
            $('#p_BI').prop('checked', this.Colaborador[0].bi);
            $('#p_CB').prop('checked', this.Colaborador[0].cb);
            $('#p_EP').prop('checked', this.Colaborador[0].ep);
            $('#p_DD').prop('checked', this.Colaborador[0].dd);
            $('#p_MID').prop('checked', this.Colaborador[0].mid);
            $('#p_LL').prop('checked', this.Colaborador[0].ll);
            this.formGroupAgregarCuponHospedaje.value.p_Camaristas = this.Colaborador[0].camaristas;
            this.formGroupAgregarCuponHospedaje.value.p_BellBoys = this.Colaborador[0].bellBoys;
            this.formGroupAgregarCuponHospedaje.value.p_Meseros = this.Colaborador[0].meseros;
            this.formGroupAgregarCuponHospedaje.value.p_ImpuestoInc = this.Colaborador[0].impuestoInc;
            this.formGroupAgregarCuponHospedaje.value.p_extrasPagadosPasajero = this.Colaborador[0].extrasPagadosPasajero;
            $('#p_Camaristas').prop('checked', this.Colaborador[0].camaristas);
            $('#p_BellBoys').prop('checked', this.Colaborador[0].bellBoys);
            $('#p_Meseros').prop('checked', this.Colaborador[0].meseros);
            $('#p_ImpuestoInc').prop('checked', this.Colaborador[0].impuestoInc);
            $('#p_extrasPagadosPasajero').prop('checked', this.Colaborador[0].extrasPagadosPasajero);

            this.formGroupAgregarCuponHospedaje.value.p_ClaveHotel = this.Colaborador[0].claveHotel;
            this.formGroupAgregarCuponHospedaje.value.p_Comentarios = this.Colaborador[0].comentarios;
            this.formGroupAgregarCuponHospedaje.value.p_ComentariosKetzal = this.Colaborador[0].comentariosKetzal;
            this.formGroupAgregarCuponHospedaje.value.p_Elaboro = this.Colaborador[0].elaboro;
            this.formGroupAgregarCuponHospedaje.value.p_Reviso = this.Colaborador[0].reviso;
            this.formGroupAgregarCuponHospedaje.value.p_Autorizo = this.Colaborador[0].autorizo;

            this.formGroupAgregarCuponHospedaje.patchValue(this.formGroupAgregarCuponHospedaje.value);
          } else {
            this.listaCupones = [];
          }
          this.operacionRespuesta.EstaEjecutando = false;
        },
        async (err) => {
          this.operacionRespuesta.EstaEjecutando = false;
        }
      );
  }
  eliminarCupon(idCatCupon) {
    this.operacionRespuesta.EstaEjecutando = true;
    this.services.HttpDelete(this.modelo_configuracion.serviciosOperaciones + "/Cupones/DeleteCupon?p_idcupon=" + idCatCupon)
      .subscribe(
        (tempdate) => {
          if (tempdate.response[0].success == 1) {
            this.openMensajes(tempdate.response[0].msg, false, '');
            this.obtenerCupones();
            this.modalEliminarCupon.close();
          } else {
            this.openMensajes(tempdate.response[0].msg, true, '');
          }
          this.operacionRespuesta.EstaEjecutando = false;
        },
        async (err) => {
          this.openMensajes("No se pudo realizar la acción", true, '');
          this.operacionRespuesta.EstaEjecutando = false;
        }
      );
  }

  inhabilitarCupon(idCatCupon) {
    this.operacionRespuesta.EstaEjecutando = true;
    this.services.HttpDelete(this.modelo_configuracion.serviciosOperaciones + "/Cupones/InhabilitarCupon?p_idcupon=" + idCatCupon)
      .subscribe(
        (tempdate) => {
          if (tempdate.response[0].success == 1) {
            this.openMensajes(tempdate.response[0].msg, false, '');
            this.obtenerCupones();
            this.modalInhabilitarCupon.close();
          } else {
            this.openMensajes(tempdate.response[0].msg, true, '');
          }
          this.operacionRespuesta.EstaEjecutando = false;
        },
        async (err) => {
          this.openMensajes("No se pudo realizar la acción", true, '');
          this.operacionRespuesta.EstaEjecutando = false;
        }
      );
  }

  ActivarCupon(idCatCupon) {
    this.operacionRespuesta.EstaEjecutando = true;
    this.services.HttpDelete(this.modelo_configuracion.serviciosOperaciones + "/Cupones/ActivarCupon?p_idcupon=" + idCatCupon)
      .subscribe(
        (tempdate) => {
          if (tempdate.response[0].success == 1) {
            this.openMensajes(tempdate.response[0].msg, false, '');
            this.obtenerCupones();
            this.modalActivarCupon.close();
          } else {
            this.openMensajes(tempdate.response[0].msg, true, '');
          }
          this.operacionRespuesta.EstaEjecutando = false;
        },
        async (err) => {
          this.openMensajes("No se pudo realizar la acción", true, '');
          this.operacionRespuesta.EstaEjecutando = false;
        }
      );
  }

  autorizarCupon(idCatCupon) {
    this.operacionRespuesta.EstaEjecutando = true;
    this.services.HttpDelete(this.modelo_configuracion.serviciosOperaciones + "/Cupones/AutorizarCupon?p_idcupon=" + idCatCupon + "&p_idUsuario=" + this.idUsuarioAutorizo)
      .subscribe(
        (tempdate) => {
          if (tempdate.response[0].success == 1) {
            this.openMensajes(tempdate.response[0].msg, false, '');
            this.obtenerCupones();
            this.modalAutorizarCupon.close();
          } else {
            this.openMensajes(tempdate.response[0].msg, true, '');
          }
          this.operacionRespuesta.EstaEjecutando = false;
        },
        async (err) => {
          this.openMensajes("No se pudo realizar la acción", true, '');
          this.operacionRespuesta.EstaEjecutando = false;
        }
      );
  }

  openModalFormCuponHospedaje(esEditable: boolean) {
    this.esEditable = esEditable;
    this.formGroupAgregarCuponHospedaje.reset({

      p_IDCupon: 0,
      p_File: "",
      p_FechaRegistro: "",
      p_NombrePax: "",
      p_NumAdulto: 0,
      p_NumMenores: 0,
      p_EdadMenores: "",
      p_NumBebes: 0,
      p_Comentarios: "",
      p_ComentariosKetzal: "",
      p_Elaboro: this.idUsuarioElabora,
      p_Reviso: this.idUsuarioReviso,
      p_Autorizo: this.idUsuarioAutorizo,
      p_IDProveedor: 0,
      p_FEntrada: "",
      p_FSalida: "",
      p_Noche: 0,
      p_IDTipoHabitacion: 0,
      p_SGL: 0,
      p_DBL: 0,
      p_TPL: 0,
      p_TWIN: 0,
      p_Otros: "",
      p_totalPax: 0,
      p_AL: false,
      p_AB: false,
      p_FAP: false,
      p_SAI: false,
      p_BB: false,
      p_BL: false,
      p_BI: false,
      p_MAPComida: false,
      p_MAPCena: false,
      p_CB: false,
      p_EP: false,
      p_DD: false,
      p_MID: false,
      p_LL: false,
      p_ClaveHotel: "",
      p_Camaristas: false,
      p_BellBoys: false,
      p_Meseros: false,
      p_ImpuestoInc: false,
      p_extrasPagadosPasajero: false,

    });

    this.modalAgregarCuponHospedaje = this.modalService.open(this.modalAddCouponHospedaje, { ariaLabelledBy: 'modal-basic-title', backdrop: 'static', size: 'lg' });
    $("#_MAPComida").hide();
    $("#_MAPCena").hide();
  }
  openEliminar(idCatCupon, nombreCat) {
    this._idCatCupon = idCatCupon;
    this._nombreCup = nombreCat;
    this.modalEliminarCupon = this.modalService.open(this.ModalDesactivar, { ariaLabelledBy: 'modal-basic-title' });
  }

  openInhabilitar(idCatCupon, nombreCat) {
    this._idCatCupon = idCatCupon;
    this._nombreCup = nombreCat;
    this.modalInhabilitarCupon = this.modalService.open(this.ModalInhabilitar, { ariaLabelledBy: 'modal-basic-title' });
  }

  openActivar(idCatCupon, nombreCat) {
    this._idCatCupon = idCatCupon;
    this._nombreCup = nombreCat;
    this.modalActivarCupon = this.modalService.open(this.ModalActivar, { ariaLabelledBy: 'modal-basic-title' });
  }

  openAutorizarCupon(idCatCupon, opcion) {
    this._idCatCupon = idCatCupon;
    if (opcion == true) {
      this._nombreCup = 'autorizar el cupón ' + idCatCupon;
    } else {
      this._nombreCup = 'quitar la autorización el cupón ' + idCatCupon;
    }

    this.modalAutorizarCupon = this.modalService.open(this.ModalAutorizar, { ariaLabelledBy: 'modal-basic-title' });
  }

  openCuponPDFHopspedaje(idCatCupon, nombreCat) {
    this.operacionRespuesta.EstaEjecutando = true;
    this.services.HttpGet(this.modelo_configuracion.serviciosOperaciones + "/Cupones/GetCupon_x_id_Hospedaje?p_idcupon=" + idCatCupon)
      .subscribe(
        (tempdate) => {
          if (tempdate) {
            this.pdfCupon = tempdate.response[0] as Cupon_hospedaje_modelo_response;

          } else {
            this.listaCupones = [];
          }
          this.operacionRespuesta.EstaEjecutando = false;
        },
        async (err) => {
          this.operacionRespuesta.EstaEjecutando = false;
        }
      );

    this._idCatCupon = idCatCupon;
    this._nombreCup = nombreCat;
    this.modalCuponPDFH = this.modalService.open(this.ModalCuponPDFH, { ariaLabelledBy: 'modal-basic-title', backdrop: 'static', size: 'lg' });
  }

  generarPDF(_idCatCupon) {
    const options = {
      filename: 'cupon_' + _idCatCupon + '.pdf',
      image: { type: 'jpeg', quality: 1.0 }, // Establece una calidad de imagen máxima (rango: 0.0 - 1.0)
      html2canvas: { scale: 2 }, // Configuración de rendering de HTML2Canvas
      jsPDF: { orientation: 'portrait' }
    };

    const element = document.getElementById('htmlPrint');

    html2pdf()
      .set(options)
      .from(element)
      .save();
  }

  imprimirPDF() {
    const modalContent = document.getElementById('htmlPrint');
    if (modalContent) {
      const ventanaImpresion = window.open('', '_blank');
      const estilos = Array.from(document.getElementsByTagName('link'));
      const estilosHTML = estilos.map((estilo) => estilo.outerHTML).join('');

      ventanaImpresion.document.write(`<html><head><title>Imprimir Cupón</title>${estilosHTML}</head><body><div class="row m-4"><div class="col-md-12"><div class="card px-2">`);
      ventanaImpresion.document.write(modalContent.innerHTML);
      ventanaImpresion.document.write('</div></div></div></body></html><script>window.print(); </script>');

    }
  }

  cerrarModalPDF() {
    this.modalCuponPDFH.close();
  }
  openMensajes(Mensaje: string, Error: boolean, titulo: string) {
    this.modalrefMsg = this.modalService.open(ModuloModalMensajeComponent, { ariaLabelledBy: "modal-basic-title" });
    this.modalrefMsg.componentInstance.mensajesExito = [];
    this.modalrefMsg.componentInstance.mensajesError = [];
    this.modalrefMsg.componentInstance.mensajeTitulo = 'Cupón Hospedaje';

    if (Error) {
      this.modalrefMsg.componentInstance.showErrors = true;
      this.modalrefMsg.componentInstance.mensajesError.push(Mensaje);
    } else {
      this.modalrefMsg.componentInstance.showExitos = true;
      this.modalrefMsg.componentInstance.mensajesExito.push(Mensaje);
    }
  }

  onCheckboxChangeCuponSelect(event: any, item: any) {
    const checkboxValue = item.idCupon; // Utiliza un identificador único del elemento
  
    if (!this.selectedCupones) {
      this.selectedCupones = [];
    }
  
    if (event.target.checked) {
      this.selectedCupones.push(checkboxValue);
    } else {
      this.selectedCupones = this.selectedCupones.filter(selectedItem => selectedItem !== checkboxValue);
    }

    if (this.selectedCupones.length > 0) {
      this.activarAutorizado = true;
    } else {
      this.activarAutorizado = false;
    }
  }
  

  ActivarCuponMasivo() {
    this.operacionRespuesta.EstaEjecutando = true;

    let model = {
      p_idcupon: this.selectedCupones,
      p_idUsuario: this.idUsuario
    };

    this.services.HttpPost(model, this.modelo_configuracion.serviciosOperaciones + "/Cupones/ActivarCuponMasivo")
    .subscribe(
      (tempdate) => {
        if (tempdate.response[0].success == 1) {
             this.obtenerCupones();
        } 
        this.operacionRespuesta.EstaEjecutando = false;
      },
      async (err) => {
        this.openMensajes("No se pudo realizar la acción", true, '');
        this.operacionRespuesta.EstaEjecutando = false;
      }
    );
  }
}

