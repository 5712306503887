import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { ThemeConstants } from 'src/app/@espire/shared/config/theme-constant';
import { RespuestaGenerica } from 'src/app/generales/RespuestaGenerica';
import { ServiciosRutas } from 'src/app/generales/ServiciosRutas';
import { AuthGuard } from 'src/app/guards/AuthGuard';
import { ModuloModalMensajeComponent } from 'src/app/shared/modulo-modal-mensaje/modulo-modal-mensaje.component';
import { Lista_tarifas_modelo_response } from 'src/model/Catalogos/Tarifa';
import { ServiceGenerico } from 'src/services/service-generico.service';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet; charset=UTF-8';
const EXCEL_EXT = '.xlsx';

@Component({
  selector: 'app-listado-tarifas',
  templateUrl: './listado-tarifas.component.html',
  styleUrls: ['./listado-tarifas.component.css'],
  providers: [ServiceGenerico]
})
export class ListadoTarifasComponent implements OnInit {
  // @ViewChild("modalAddTarifa", { static: false }) modalAddTarifa: TemplateRef<any>;
  modalAgregarTarifa: NgbModalRef;

  @Input() _IDProveedor: number;
  @Output() abrirModalEvent = new EventEmitter<number>();

  @ViewChild(DataTableDirective, { static: false })
  dtElementTH: DataTableDirective;
  dtOptionsTH: DataTables.Settings = {};
  dtTriggerTH: Subject<any> = new Subject();
  isDtInitializedTH: boolean = false;
  modelo_configuracion: ServiciosRutas;
  operacionRespuesta: RespuestaGenerica;
  listaTarifa: Lista_tarifas_modelo_response[];
  listadoTarifa: number;
  _IDTarifa: number = 0;
  txtProveedor: string = '';
  Estado: number = 5;

  modalrefMsg: NgbModalRef;
  @ViewChild("contentAutorizar", { static: false }) ModalAutorizar: TemplateRef<any>;
  modalAutorizarTarifa: NgbModalRef;
  _idTar: number;
  txtTituloMensaje: string;
  txtMensaje: string;
  _opcion: string;

  constructor(
    private auth: AuthGuard,
    private themeConstantsTH: ThemeConstants,
    private services: ServiceGenerico,
    private router: Router,
    private modalService: NgbModal,
    private fb: FormBuilder,
    private sanitizer: DomSanitizer,
  ) {
    this.modelo_configuracion = new ServiciosRutas();
    this.operacionRespuesta = new RespuestaGenerica();
   }

  ngOnInit() {
    this.inicializaTabla();
    this.obtenerProveedoresTH();
  }

  inicializaTabla() {
    this.dtOptionsTH = this.themeConstantsTH.dtOptions;

  }

  renderTablaTH() {
    if (!this.isDtInitializedTH) {
      if (this.dtElementTH && this.dtElementTH.dtInstance) {
        this.dtElementTH.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
          this.dtTriggerTH.next();
        });
      } else {
        this.dtTriggerTH.next();
      }
      this.isDtInitializedTH = true;
    }
}

obtenerProveedoresTH() {
  this.operacionRespuesta.EstaEjecutando = true;
  //Tipo proveedor 1 Hospedaje
  this.services
    .HttpGet(
      this.modelo_configuracion.serviciosOperaciones + "/Tarifas/GetListaTarifas?p_idProveedor=" + this._IDProveedor + "&p_Estado=" + this.Estado)
    .subscribe(
      (tempdate) => {
        if (tempdate) {
          this.listaTarifa = [] = tempdate.response as Lista_tarifas_modelo_response[];
          this.txtProveedor = this.listaTarifa[0].proveedor;
          this.renderTablaTH();
        } else {
          this.listaTarifa = [];
          this.renderTablaTH();
        }
        this.operacionRespuesta.EstaEjecutando = false;
      },
      async (err) => {
        this.operacionRespuesta.EstaEjecutando = false;
      }
    );
}

regresarListado(tipo: string) {
  this.listadoTarifa = 0;
}

modificarTarifa(IDTarifa, tipoTarifa) {
  this._IDTarifa = IDTarifa;
  this.abrirModalEvent.emit(this._IDTarifa);
}


openAutorizarTarifa(idTar, opcion) {
  this._opcion = opcion;
  this._idTar = idTar;
  
  if(opcion == 2) {
    this.txtTituloMensaje = "Autorización Tarifa";
    this.txtMensaje = "¿Está seguro que desea autotizar la tarifa "+ idTar +" ?";
  } else if (opcion == 5) {
    this.txtTituloMensaje = "Desactivar Tarifa";
    this.txtMensaje = "¿Está seguro que desea desactivar la tarifa "+ idTar +" ?";
  }


  this.modalAutorizarTarifa = this.modalService.open(this.ModalAutorizar, { ariaLabelledBy: 'modal-basic-title' });
}

autorizarTarifa(idtarifa, opcion) {
  this.operacionRespuesta.EstaEjecutando = true;
  this.services.HttpDelete(this.modelo_configuracion.serviciosOperaciones + "/Tarifas/modificarStatusTarifa?p_idTarifa=" + idtarifa + "&p_opcion=" + opcion)
    .subscribe(
      (tempdate) => {
        if (tempdate.response[0].success == 1) {
          this.openMensajes(tempdate.response[0].msg, false, '');
          this.obtenerProveedoresTH();
          this.modalAutorizarTarifa.close();
        } else {
          this.openMensajes(tempdate.response[0].msg, true, '');
        }
        this.operacionRespuesta.EstaEjecutando = false;
      },
      async (err) => {
        this.openMensajes("No se pudo realizar la acción", true, '');
        this.operacionRespuesta.EstaEjecutando = false;
      }
    );
}

// autorizarTarifa(IDTarifa, tipoTarifa) {
//   this._IDTarifa = IDTarifa;
//   this.abrirModalEvent.emit(this._IDTarifa);
// }

// eliminarTarifa(IDTarifa, tipoTarifa) {
//   this._IDTarifa = IDTarifa;
//   this.abrirModalEvent.emit(this._IDTarifa);
// }

actualizaConsulta(estadoTarifa: number){
  this.listaTarifa = [];
  this.Estado = estadoTarifa;

this.obtenerProveedoresTH();
}


openMensajes(Mensaje: string, Error: boolean, titulo: string) {
  this.modalrefMsg = this.modalService.open(ModuloModalMensajeComponent, { ariaLabelledBy: "modal-basic-title" });
  this.modalrefMsg.componentInstance.mensajesExito = [];
  this.modalrefMsg.componentInstance.mensajesError = [];
  this.modalrefMsg.componentInstance.mensajeTitulo = 'Cupón Hospedaje';

  if (Error) {
    this.modalrefMsg.componentInstance.showErrors = true;
    this.modalrefMsg.componentInstance.mensajesError.push(Mensaje);
  } else {
    this.modalrefMsg.componentInstance.showExitos = true;
    this.modalrefMsg.componentInstance.mensajesExito.push(Mensaje);
  }
}



}
