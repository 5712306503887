import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { ThemeConstants } from 'src/app/@espire/shared/config/theme-constant';
import { RespuestaGenerica } from 'src/app/generales/RespuestaGenerica';
import { ServiciosRutas } from 'src/app/generales/ServiciosRutas';
import { AuthGuard } from 'src/app/guards/AuthGuard';
import { ModuloModalMensajeComponent } from 'src/app/shared/modulo-modal-mensaje/modulo-modal-mensaje.component';
import { Lista_catalogo_corto_modelo_response, Lista_general_corto_modelo_response } from 'src/model/Catalogos/Catalogos';
import { Cpostal_response, Lista_proveedores_modelo_response, Lista_temp_contactos_modelo_response, Proveedor_modelo_response, Proveedor_Transporte_modelo_response } from 'src/model/Catalogos/Proveedores';
import { ServiceGenerico } from 'src/services/service-generico.service';

@Component({
  selector: 'app-proveedores',
  templateUrl: './proveedores.component.html',
  styleUrls: ['./proveedores.component.css'],
  providers: [ServiceGenerico]
})
export class ProveedoresComponent implements OnInit {
  @ViewChild("modalAddProveedor", { static: false }) modalAddProveedor: TemplateRef<any>;
  @ViewChild("modalAddProveedorTransporte", { static: false }) modalAddProveedorTransporte: TemplateRef<any>;
  @ViewChild("contentDesactivar", { static: false }) ModalDesactivar: TemplateRef<any>;
  @ViewChild("modalAddUsuario", { static: false }) modalAddUsuario: TemplateRef<any>;
  modalrefMsg: NgbModalRef;
  modalAgregarProveedor: NgbModalRef;
  modalAgregarProveedorTransporte: NgbModalRef;
  modalAgregarUsuario: NgbModalRef;
  modalEliminarProveedor: NgbModalRef;
  selectedCheckboxes: string[] = []; // Array para almacenar los valores seleccionados
  selectedCheckboxesCatTrans: string[] = []; // Array para almacenar los valores seleccionados

  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  dtOptionsProv: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  isDtInitialized: boolean = false;

  private modelo_configuracion: ServiciosRutas;
  public operacionRespuesta: RespuestaGenerica;
  listaProveedores: Lista_proveedores_modelo_response[];
  formGroupAgregarProveedor: FormGroup;
  formGroupAgregarProveedorTransporte: FormGroup;
  funcionalidad: string;
  private _idProveedor: number;
  esEditable: boolean;
  listaCatalogos: Lista_catalogo_corto_modelo_response[];
  listaSituacionProveedor: Lista_catalogo_corto_modelo_response[];
  listaGrupo: Lista_general_corto_modelo_response[];
  listaSubGrupo: Lista_general_corto_modelo_response[];
  listaFPI: Lista_general_corto_modelo_response[];
  listaFPG: Lista_general_corto_modelo_response[];
  listaIF: Lista_general_corto_modelo_response[];
  listaIISH: Lista_general_corto_modelo_response[];
  listaCS: Lista_general_corto_modelo_response[];
  listaBKP: Lista_general_corto_modelo_response[];
  listaBKD: Lista_general_corto_modelo_response[];
  listaSPH: Lista_general_corto_modelo_response[];
  Proveedor: Proveedor_modelo_response;
  ProveedorTransporte: Proveedor_Transporte_modelo_response;
  _nombreProveedor: string;
  listaProveedoresT: Lista_proveedores_modelo_response[];
  listaProveedoresO: Lista_proveedores_modelo_response[];
  _tipoProveedor: number = 1;
  listaSPT: Lista_general_corto_modelo_response[];
  listaSPO: Lista_general_corto_modelo_response[];
  listaColonias: Cpostal_response[];
  formGroupAgregarUsuario: FormGroup;
  _idUsuario: number;
  listaTCONTACTO: Lista_general_corto_modelo_response[];
  listContactosTemp: Lista_temp_contactos_modelo_response[] = [];
  accionUsuario: string = 'Nuevo';
  private _index: number;
  listaCPT: Lista_general_corto_modelo_response[];
  listaTI: Lista_general_corto_modelo_response[];
  listaCA: Lista_general_corto_modelo_response[];
  listaBIT: Lista_general_corto_modelo_response[];
  sinCP: boolean = false;
  categoriasSeleccionadas: number[] = []; // Aquí declaras la propiedad
  constructor(
    private auth: AuthGuard,
    private themeConstants: ThemeConstants,
    private services: ServiceGenerico,
    private router: Router,
    private modalService: NgbModal,
    private fb: FormBuilder,
    private sanitizer: DomSanitizer,
  ) {
    this.modelo_configuracion = new ServiciosRutas();
    this.operacionRespuesta = new RespuestaGenerica();
  }

  ngOnInit() {

    this.inicializaTabla();
    this.obtenerProveedores(1);

    this.inicializarFormAgregarProveedor();
    this.inicializarFormAgregarProveedorTransporte();
    this.inicializarFormAgregarUsuario();
    // catalogo categoría
    this.obtenerCatalogosGenericos(12);
    // catalogo sitacion proveedor
    this.obtenerCatalogosGenericos(13);
    // catalogo grupo
    this.obtenerCatalogosCortos('Grupo', 0);
    this.obtenerCatalogosCortos('FPI', 0);
    this.obtenerCatalogosCortos('FPG', 0);
    this.obtenerCatalogosCortos('IF', 0);
    this.obtenerCatalogosCortos('IISH', 0);
    this.obtenerCatalogosCortos('CS', 0);
    this.obtenerCatalogosCortos('BKP', 0);
    this.obtenerCatalogosCortos('BKD', 0);
    this.obtenerCatalogosCortos('SPH', 0);
    this.obtenerCatalogosCortos('SPT', 0);
    this.obtenerCatalogosCortos('SPO', 0);
    this.obtenerCatalogosCortos('TCONTACTO', 0);
    this.obtenerCatalogosCortos('CPT', 0);
    this.obtenerCatalogosCortos('TI', 0);
    this.obtenerCatalogosCortos('CA', 0);
    this.obtenerCatalogosCortos('BIT', 0);

  }

  inicializaTabla() {
    this.dtOptionsProv = this.themeConstants.dtOptionsProv;
  }

  renderTabla() {
    if (!this.isDtInitialized) {
      if (this.dtElement && this.dtElement.dtInstance) {
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
          this.dtTrigger.next();
        });
      } else {
        this.dtTrigger.next();
      }
      this.isDtInitialized = true;
    }
  }

  inicializarFormAgregarProveedor() {
    this.formGroupAgregarProveedor = this.fb.group({
      p_Nombre: ["", Validators.required],
      p_RazonSocial: [""],
      p_RFC: [""],
      p_IDCategoria: [0],
      p_IDSProveedor: [0],
      p_IDGrupo: [0],
      p_IDSubGrupo: [0],
      p_LADA1: ["", this.numericValidator(3)],
      p_Tel1: ["", this.numericValidator(7)],
      p_Ext1: [""],
      p_LADA2: ["", this.numericValidator(3)],
      p_Tel2: ["", this.numericValidator(7)],
      p_Ext2: [""],
      p_Correo1: ["", Validators.email],
      p_Correo2: ["", Validators.email],
      p_Web: [""],
      p_Calle: [""],
      p_NExt: [""],
      p_NInt: [""],
      p_CPostal: [""],
      p_Colonia: [""],
      p_Ciudad: [""],
      p_CiudadTarifario: [""],
      p_Estado: [""],
      p_Pais: [""],
      p_Referencia: [""],
      p_CodigoKetzal: [""],
      p_NumHabitaciones: [0],
      p_Comentario: [""],
      p_IDFormaPagoI: [0],
      p_IDFPagoGrupo: [0],
      p_IDImpFederal: [0],
      p_IDImpuestoISH: [0],
      p_IDCargoServicio: [0],
      p_IDBancoPesos: [0],
      p_NumCtaPesos: ["", this.numericValidator(18)],
      p_IDBancoDolar: [0],
      p_NumCtaDolares: ["", this.numericValidator(18)],
      p_InformacionBancoP: [""],
      p_InformacionBancoD: [""],
      p_InfoFPagoGrupo: [""],
      p_IVA: [0, this.numericValidator(3)],
      p_ClabeP: ["", this.numericValidator(18)],
      p_ClabeD: ["", this.numericValidator(18)],
      p_Penalizaciones: [""],
      p_InfoTarifas: [""],
      p_Restricciones: [""]
    });
  }

  inicializarFormAgregarProveedorTransporte() {
    this.formGroupAgregarProveedorTransporte = this.fb.group({
      p_Nombre: ["", Validators.required],
      p_RazonSocial: [""],
      p_RFC: [""],
      p_CodigoKetzal: [""],
      p_IDCategoria: [0],
      p_LADA1: ["", this.numericValidator(3)],
      p_Tel1: ["", this.numericValidator(7)],
      p_Ext1: [""],
      p_Tel2: ["", this.numericValidator(7)],
      p_Correo1: ["", Validators.email],
      p_Correo2: ["", Validators.email],
      p_Web: [""],
      p_CreditoIndividual: [0],
      p_CreditoGrupal: [0],
      p_LimiteCreditoInd: [0],
      p_LimiteCreditoGrup: [0],
      p_IDMonedaInd: [0],
      p_IDMonedaGrup: [0],
      p_DiasCreditoInd: [0],
      p_DiasCreditoGrup: [0],
      p_Calle: [""],
      p_NExt: [""],
      p_NInt: [""],
      p_CPostal: [""],
      p_Colonia: [""],
      p_Ciudad: [""],
      p_Estado: [""],
      p_Pais: [""],
      p_Destino: [""],
      p_IDBancoPesos: [0],
      p_IDBancoDolar: [0],
      p_NumCtaPesos: ["", this.numericValidator(18)],
      p_NumCtaDolares: ["", this.numericValidator(18)],
      p_ClabeP: ["", this.numericValidator(18)],
      p_ClabeD: ["", this.numericValidator(18)],
      p_Swift: [""],
      p_ABA: [""],
      p_Bic: [""],
      p_IDBancoIntermediario: [0],
      p_URLPortal: [""],
      p_Usuario: [""],
      p_Contrasena: [""],
      p_NumeroPoliza: [""],
      p_IDAseguradora: [0],
      p_FechaInicio: [""],
      p_FechaFin: [""],
      p_FechaIngreso: [""],
      p_Observaciones: [""],
      p_RutaLocal: [""],
      p_Url: [""],
      p_Impuesto: [""],
      p_VigenciaDesde: [""],
      p_VigenciaHasta: [""],
      p_FechaIngresoImp: [""],
      p_TipoImpuesto: [0],
      p_Monto: [0],
      p_contactos: [""],
      // p_selectedCheckboxes: [""]
      });
  }

  inicializarFormAgregarUsuario() {
    this.formGroupAgregarUsuario = this.fb.group({
      p_tipoContacto: [0],
      p_nombre: ["", Validators.required],
      p_aPaterno: ["", Validators.required],
      p_aMaterno: [""],
      p_Email1: ["", Validators.required],
      p_Email2: [""],
      p_LADA1: ["", this.numericValidator(3)],
      p_Tel1: ["", this.numericValidator(7)],
      p_Ext1: [""],
      p_LADA2: ["", this.numericValidator(3)],
      p_Tel2: ["", this.numericValidator(7)],
      p_Ext2: [""],
      p_Observaciones: [""]
    });

  }

  agregarProveedor() {
    this.funcionalidad = 'Nuevo';
    this._idProveedor = 0;

    if (this._tipoProveedor == 2) {
    this.openModalFormProveedorTransporte(true);
    } else {
      this.openModalFormProveedor(true);
    }
  }

  // Validador personalizado para aceptar solo números con una longitud específica
  numericValidator(maxLength: number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = control.value;
      const numericPattern = /^[0-9]+$/;

      if (value.length != "") {
        if (maxLength == 3) {
          if (!numericPattern.test(value) || value.length < 1 || value.length > 3) {
            return { numericLength: true };
          }
        } else if (maxLength == 7) {
          if (!numericPattern.test(value) || value.length < 1 || value.length > 7) {
            return { numericLength: true };
          }
        } else if (maxLength == 18) {
          if (!numericPattern.test(value) || value.length < 1 || value.length > 18) {
            return { numericLength: true };
          }
        }
      }


      return null;
    };
  }

  GuardarProveedor() {
    if (this._tipoProveedor == 2) {
      this.GuardarProveedorTransporte();
      } else {
        this.GuardarProveedorHospedaje();
      }
  }

  GuardarProveedorHospedaje() {
    this.operacionRespuesta.EstaEjecutando = true;
    let model = this.formGroupAgregarProveedor.value;
    model.p_IDProveedor = this._idProveedor;
    model.p_idTipoProveedor = this._tipoProveedor;
    model.p_contactos = this.listContactosTemp;

    this.selectedCheckboxes = [];

    const checkboxes = document.querySelectorAll('input[type="checkbox"]');
    
    checkboxes.forEach((checkbox: HTMLInputElement) => {
      if (checkbox.checked) {
        this.selectedCheckboxes.push(checkbox.value);
      }
      model.p_idServicio = this.selectedCheckboxes;
    });

    if (this._tipoProveedor == 1) {
      if (this.funcionalidad == 'Nuevo') {
        this.services.HttpPost(model, this.modelo_configuracion.serviciosOperaciones + "/Proveedores/PostProveedorHospedaje")
          .subscribe(
            (tempdate) => {
              if (tempdate.response[0].success == 1) {
                this.openMensajes(tempdate.response[0].msg, false, '');
                this.obtenerProveedores(1);
                this.modalAgregarProveedor.close();
              } else {
                this.openMensajes(tempdate.response[0].msg, true, '');
              }
              this.operacionRespuesta.EstaEjecutando = false;
            },
            async (err) => {
              this.openMensajes("No se pudo realizar la acción", true, '');
              this.operacionRespuesta.EstaEjecutando = false;
            }
          );
      } else {
        this.services.HttpPut(model, this.modelo_configuracion.serviciosOperaciones + "/Proveedores/PutProveedor")
          .subscribe(
            (tempdate) => {
              if (tempdate.response[0].success == 1) {
                this.openMensajes(tempdate.response[0].msg, false, '');
                this.obtenerProveedores(1);
                this.modalAgregarProveedor.close();
              } else {
                this.openMensajes(tempdate.response[0].msg, true, '');
              }
              this.operacionRespuesta.EstaEjecutando = false;
            },
            async (err) => {
              this.openMensajes("No se pudo realizar la acción", true, '');
              this.operacionRespuesta.EstaEjecutando = false;
            }
          );
      }
    } else if (this._tipoProveedor == 2) {
      if (this.funcionalidad == 'Nuevo') {
        this.services.HttpPost(model, this.modelo_configuracion.serviciosOperaciones + "/Proveedores/PostProveedorTransporte")
          .subscribe(
            (tempdate) => {
              if (tempdate.response[0].success == 1) {
                this.openMensajes(tempdate.response[0].msg, false, '');
                this.obtenerProveedores(2);
                this.modalAgregarProveedor.close();
              } else {
                this.openMensajes(tempdate.response[0].msg, true, '');
              }
              this.operacionRespuesta.EstaEjecutando = false;
            },
            async (err) => {
              this.openMensajes("No se pudo realizar la acción", true, '');
              this.operacionRespuesta.EstaEjecutando = false;
            }
          );
      } else {
        this.services.HttpPut(model, this.modelo_configuracion.serviciosOperaciones + "/Proveedores/PutProveedor")
          .subscribe(
            (tempdate) => {
              if (tempdate.response[0].success == 1) {
                this.openMensajes(tempdate.response[0].msg, false, '');
                this.obtenerProveedores(2);
                this.modalAgregarProveedor.close();
              } else {
                this.openMensajes(tempdate.response[0].msg, true, '');
              }
              this.operacionRespuesta.EstaEjecutando = false;
            },
            async (err) => {
              this.openMensajes("No se pudo realizar la acción", true, '');
              this.operacionRespuesta.EstaEjecutando = false;
            }
          );
      }
    } else if (this._tipoProveedor == 3) {
      if (this.funcionalidad == 'Nuevo') {
        this.services.HttpPost(model, this.modelo_configuracion.serviciosOperaciones + "/Proveedores/PostProveedorOtros")
          .subscribe(
            (tempdate) => {
              if (tempdate.response[0].success == 1) {
                this.openMensajes(tempdate.response[0].msg, false, '');
                this.obtenerProveedores(3);
                this.modalAgregarProveedor.close();
              } else {
                this.openMensajes(tempdate.response[0].msg, true, '');
              }
              this.operacionRespuesta.EstaEjecutando = false;
            },
            async (err) => {
              this.openMensajes("No se pudo realizar la acción", true, '');
              this.operacionRespuesta.EstaEjecutando = false;
            }
          );
      } else {
        this.services.HttpPut(model, this.modelo_configuracion.serviciosOperaciones + "/Proveedores/PutProveedor")
          .subscribe(
            (tempdate) => {
              if (tempdate.response[0].success == 1) {
                this.openMensajes(tempdate.response[0].msg, false, '');
                this.obtenerProveedores(3);
                this.modalAgregarProveedor.close();
              } else {
                this.openMensajes(tempdate.response[0].msg, true, '');
              }
              this.operacionRespuesta.EstaEjecutando = false;
            },
            async (err) => {
              this.openMensajes("No se pudo realizar la acción", true, '');
              this.operacionRespuesta.EstaEjecutando = false;
            }
          );
      }

    }


    this.selectedCheckboxes = [];

  }

  GuardarProveedorTransporte() {
    this.operacionRespuesta.EstaEjecutando = true;
    let model = this.formGroupAgregarProveedorTransporte.value;
    model.p_IDProveedor = this._idProveedor;
    model.p_idTipoProveedor = this._tipoProveedor;
    model.p_contactos = this.listContactosTemp;

    this.selectedCheckboxesCatTrans = [];

    const checkboxes = document.querySelectorAll('input[type="checkbox"]');

    checkboxes.forEach((checkbox: HTMLInputElement) => {
      if (checkbox.checked) {
        this.selectedCheckboxesCatTrans.push(checkbox.value);
      }
      model.p_selectedCheckboxesCatTrans = this.selectedCheckboxesCatTrans;
    });

    if (this.funcionalidad == 'Nuevo') {
      this.services.HttpPost(model, this.modelo_configuracion.serviciosOperaciones + "/Proveedores/PostProveedorTransporte")
        .subscribe(
          (tempdate) => {
            if (tempdate.response[0].success == 1) {
              this.openMensajes(tempdate.response[0].msg, false, '');
              this.obtenerProveedores(2);
              this.modalAgregarProveedorTransporte.close();
            } else {
              this.openMensajes(tempdate.response[0].msg, true, '');
            }
            this.operacionRespuesta.EstaEjecutando = false;
          },
          async (err) => {
            this.openMensajes("No se pudo realizar la acción", true, '');
            this.operacionRespuesta.EstaEjecutando = false;
          }
        );
    } else {
      this.services.HttpPut(model, this.modelo_configuracion.serviciosOperaciones + "/Proveedores/PutProveedorTransporte")
        .subscribe(
          (tempdate) => {
            if (tempdate.response[0].success == 1) {
              this.openMensajes(tempdate.response[0].msg, false, '');
              this.obtenerProveedores(2);
              this.modalAgregarProveedorTransporte.close();
            } else {
              this.openMensajes(tempdate.response[0].msg, true, '');
            }
            this.operacionRespuesta.EstaEjecutando = false;
          },
          async (err) => {
            this.openMensajes("No se pudo realizar la acción", true, '');
            this.operacionRespuesta.EstaEjecutando = false;
          }
        );
    }
    this.selectedCheckboxesCatTrans = [];
  }

  obtenerProveedores(_tipoProveedor: number) {
    this._tipoProveedor = _tipoProveedor;

    this.operacionRespuesta.EstaEjecutando = true;
    //Tipo proveedor 1 Hospedaje
    this.services
      .HttpGet(
        this.modelo_configuracion.serviciosOperaciones + "/Proveedores/GetListaProveedores?p_idTipoProveedor=" + _tipoProveedor)
      .subscribe(
        (tempdate) => {
          if (tempdate) {
            this.listaProveedores = [] = tempdate.response as Lista_proveedores_modelo_response[];
            this.isDtInitialized = false;
            this.renderTabla();
          } else {
            this.listaProveedores = [];
            this.renderTabla();
          }
          this.operacionRespuesta.EstaEjecutando = false;
        },
        async (err) => {
          this.operacionRespuesta.EstaEjecutando = false;
        }
      );
  }


  verDetalleProveedor(idProveedor, opcion) {
    if (this._tipoProveedor == 2) {
      this.verDetalleProveedorTransporte(idProveedor, opcion)
    } else {
      this.verDetalleProveedorHospedaje(idProveedor, opcion)
    }
  }

  verDetalleProveedorHospedaje(idProveedor, opcion) {
    if (opcion == 'Editar') {
      this.funcionalidad = 'Editar';
      this.esEditable = true;
      this._idProveedor = idProveedor;
    } else {
      this.esEditable = false;
    }

    this.openModalFormProveedor(this.esEditable);
    this.operacionRespuesta.EstaEjecutando = true;
    this.services.HttpGet(this.modelo_configuracion.serviciosOperaciones + "/Proveedores/GetProveedor_x_id?p_opcion=1&p_IDProveedor=" + idProveedor)
      .subscribe(
        (tempdate) => {
          if (tempdate) {
            this.Proveedor = tempdate.response as Proveedor_modelo_response;
            this.formGroupAgregarProveedor.value.p_Nombre = this.Proveedor[0].nombre;
            this.formGroupAgregarProveedor.value.p_RazonSocial = this.Proveedor[0].razonSocial;
            this.formGroupAgregarProveedor.value.p_RFC = this.Proveedor[0].rfc;
            this.formGroupAgregarProveedor.value.p_IDCategoria = this.Proveedor[0].idCategoria;
            this.formGroupAgregarProveedor.value.p_IDSProveedor = this.Proveedor[0].idsProveedor;
            this.formGroupAgregarProveedor.value.p_IDGrupo = this.Proveedor[0].idGrupo;
            this.formGroupAgregarProveedor.value.p_IDSubGrupo = this.Proveedor[0].idSubGrupo;
            this.formGroupAgregarProveedor.value.p_LADA1 = this.Proveedor[0].ladA1;
            this.formGroupAgregarProveedor.value.p_Tel1 = this.Proveedor[0].tel1;
            this.formGroupAgregarProveedor.value.p_Ext1 = this.Proveedor[0].ext1;
            this.formGroupAgregarProveedor.value.p_LADA2 = this.Proveedor[0].ladA2;
            this.formGroupAgregarProveedor.value.p_Tel2 = this.Proveedor[0].tel2;
            this.formGroupAgregarProveedor.value.p_Ext2 = this.Proveedor[0].ext2;
            this.formGroupAgregarProveedor.value.p_Correo1 = this.Proveedor[0].correo1;
            this.formGroupAgregarProveedor.value.p_Correo2 = this.Proveedor[0].correo2;
            this.formGroupAgregarProveedor.value.p_Web = this.Proveedor[0].web;
            this.formGroupAgregarProveedor.value.p_Calle = this.Proveedor[0].calle;
            this.formGroupAgregarProveedor.value.p_NExt = this.Proveedor[0].nExt;
            this.formGroupAgregarProveedor.value.p_NInt = this.Proveedor[0].nInt;
            this.formGroupAgregarProveedor.value.p_CPostal = this.Proveedor[0].cPostal;
            this.formGroupAgregarProveedor.value.p_Colonia = this.Proveedor[0].colonia;
            this.formGroupAgregarProveedor.value.p_Ciudad = this.Proveedor[0].ciudad;
            this.formGroupAgregarProveedor.value.p_CiudadTarifario = this.Proveedor[0].ciudadTarifario;
            this.formGroupAgregarProveedor.value.p_Estado = this.Proveedor[0].estado;
            this.formGroupAgregarProveedor.value.p_Pais = this.Proveedor[0].pais;
            this.formGroupAgregarProveedor.value.p_Referencia = this.Proveedor[0].referencia;
            this.formGroupAgregarProveedor.value.p_CodigoKetzal = this.Proveedor[0].codigoKetzal;
            this.formGroupAgregarProveedor.value.p_NumHabitaciones = this.Proveedor[0].numHabitaciones;
            this.formGroupAgregarProveedor.value.p_Comentario = this.Proveedor[0].comentario;
            this.formGroupAgregarProveedor.value.p_IDFormaPagoI = this.Proveedor[0].idFormaPagoI;
            this.formGroupAgregarProveedor.value.p_IDFPagoGrupo = this.Proveedor[0].idfPagoGrupo;
            this.formGroupAgregarProveedor.value.p_IDImpFederal = this.Proveedor[0].idImpFederal;
            this.formGroupAgregarProveedor.value.p_IDImpuestoISH = this.Proveedor[0].idImpuestoISH;
            this.formGroupAgregarProveedor.value.p_IDCargoServicio = this.Proveedor[0].idCargoServicio;
            this.formGroupAgregarProveedor.value.p_IDBancoPesos = this.Proveedor[0].idBancoPesos;
            this.formGroupAgregarProveedor.value.p_NumCtaPesos = this.Proveedor[0].numCtaPesos;
            this.formGroupAgregarProveedor.value.p_IDBancoDolar = this.Proveedor[0].idBancoDolar;
            this.formGroupAgregarProveedor.value.p_NumCtaDolares = this.Proveedor[0].numCtaDolares;
            this.formGroupAgregarProveedor.value.p_InformacionBancoP = this.Proveedor[0].informacionBancoP;
            this.formGroupAgregarProveedor.value.p_InformacionBancoD = this.Proveedor[0].informacionBancoD;
            this.formGroupAgregarProveedor.value.p_InfoFPagoGrupo = this.Proveedor[0].infoFPagoGrupo;
            this.formGroupAgregarProveedor.value.p_IVA = this.Proveedor[0].iva;
            this.formGroupAgregarProveedor.value.p_ClabeP = this.Proveedor[0].clabeP;
            this.formGroupAgregarProveedor.value.p_ClabeD = this.Proveedor[0].clabeD;
            this.formGroupAgregarProveedor.value.p_Penalizaciones = this.Proveedor[0].penalizaciones;
            this.formGroupAgregarProveedor.value.p_InfoTarifas = this.Proveedor[0].infoTarifas;
            this.formGroupAgregarProveedor.value.p_Restricciones = this.Proveedor[0].restricciones;
            this.formGroupAgregarProveedor.patchValue(this.formGroupAgregarProveedor.value);

            this.getCPostal(this.formGroupAgregarProveedor.value.p_CPostal, 2);
          } else {
            this.listaCatalogos = [];
          }
          this.operacionRespuesta.EstaEjecutando = false;
        },
        async (err) => {
          this.operacionRespuesta.EstaEjecutando = false;
        }
      );

    this.services.HttpGet(this.modelo_configuracion.serviciosOperaciones + "/Proveedores/GetServicio_x_Proveedores?p_IDProveedor=" + idProveedor)
      .subscribe(
        (tempdate) => {
          if (tempdate) {

            // Obtener todos los elementos de tipo checkbox en la página
            const checkboxes = document.querySelectorAll('input[type="checkbox"]');

            // Recorrer los checkboxes y comparar sus ids con los de this.listaNegocios
            checkboxes.forEach((checkbox: HTMLInputElement) => {
              if (checkbox.id.startsWith('checkbox_')) {
                const checkboxId = parseInt(checkbox.id.split('_')[1]); // Convertir el id del checkbox a número

                // Verificar si el id del checkbox está presente en this.listaNegocios
                const servicioEncontrado = tempdate.response.find(servicio => servicio.idServicio === checkboxId);

                if (servicioEncontrado) {
                  checkbox.checked = true; // Marcar el checkbox como "checked"
                }
              }
            });
          }
          this.operacionRespuesta.EstaEjecutando = false;
        },
        async (err) => {
          this.operacionRespuesta.EstaEjecutando = false;
        }
      );

    this.services.HttpGet(this.modelo_configuracion.serviciosOperaciones + "/Proveedores/GetContactosProveedor?p_IDProveedor=" + idProveedor)
      .subscribe(
        (tempdate) => {
          if (tempdate) {
            this.listContactosTemp = tempdate.response as Lista_temp_contactos_modelo_response[];
          } else {
            this.listContactosTemp = [];
          }
          this.operacionRespuesta.EstaEjecutando = false;
        },
        async (err) => {
          this.operacionRespuesta.EstaEjecutando = false;
        }
      );
  }

  verDetalleProveedorTransporte(idProveedor, opcion) {
    if (opcion == 'Editar') {
      this.funcionalidad = 'Editar';
      this.esEditable = true;
      this._idProveedor = idProveedor;
    } else {
      this.esEditable = false;
    }

    this.openModalFormProveedorTransporte(this.esEditable);
    this.operacionRespuesta.EstaEjecutando = true;
    this.services.HttpGet(this.modelo_configuracion.serviciosOperaciones + "/Proveedores/GetProveedor_transporte_x_id?p_opcion=1&p_IDProveedor=" + idProveedor)
      .subscribe(
        (tempdate) => {
          if (tempdate) {
            this.ProveedorTransporte = tempdate.response as Proveedor_Transporte_modelo_response;
            // this.formGroupAgregarProveedorTransporte.value.idProveedor = this.ProveedorTransporte[0].idProveedor;
            // this.formGroupAgregarProveedorTransporte.value.idTipoProveedor = this.ProveedorTransporte[0].idTipoProveedor;
            this.formGroupAgregarProveedorTransporte.get('p_Nombre').setValue(this.ProveedorTransporte[0].nombre);
            this.formGroupAgregarProveedorTransporte.get('p_RazonSocial').setValue(this.ProveedorTransporte[0].razonSocial);
            this.formGroupAgregarProveedorTransporte.get('p_RFC').setValue(this.ProveedorTransporte[0].rfc);
            this.formGroupAgregarProveedorTransporte.get('p_CodigoKetzal').setValue(this.ProveedorTransporte[0].codigoKetzal);
            this.formGroupAgregarProveedorTransporte.get('p_LADA1').setValue(this.ProveedorTransporte[0].ladA1);
            this.formGroupAgregarProveedorTransporte.get('p_Tel1').setValue(this.ProveedorTransporte[0].tel1);
            this.formGroupAgregarProveedorTransporte.get('p_Ext1').setValue(this.ProveedorTransporte[0].ext1);
            this.formGroupAgregarProveedorTransporte.get('p_Correo1').setValue(this.ProveedorTransporte[0].correo1);
            this.formGroupAgregarProveedorTransporte.get('p_Correo2').setValue(this.ProveedorTransporte[0].correo2);
            this.formGroupAgregarProveedorTransporte.get('p_Web').setValue(this.ProveedorTransporte[0].web);
            const categoriasString = this.ProveedorTransporte[0].categorias;
            this.categoriasSeleccionadas = categoriasString ? categoriasString.split(',').map(Number) : [];

            this.formGroupAgregarProveedorTransporte.get('p_Calle').setValue(this.ProveedorTransporte[0].calle);
            this.formGroupAgregarProveedorTransporte.get('p_NExt').setValue(this.ProveedorTransporte[0].nExt);
            this.formGroupAgregarProveedorTransporte.get('p_NInt').setValue(this.ProveedorTransporte[0].nInt);
            this.formGroupAgregarProveedorTransporte.get('p_CPostal').setValue(this.ProveedorTransporte[0].cPostal);
            this.formGroupAgregarProveedorTransporte.get('p_Colonia').setValue(this.ProveedorTransporte[0].colonia);
            this.formGroupAgregarProveedorTransporte.get('p_Ciudad').setValue(this.ProveedorTransporte[0].ciudad);
            this.formGroupAgregarProveedorTransporte.get('p_Estado').setValue(this.ProveedorTransporte[0].estado);
            this.formGroupAgregarProveedorTransporte.get('p_Pais').setValue(this.ProveedorTransporte[0].pais);
            this.formGroupAgregarProveedorTransporte.get('p_Destino').setValue(this.ProveedorTransporte[0].destino);
            this.formGroupAgregarProveedorTransporte.get('p_URLPortal').setValue(this.ProveedorTransporte[0].urlPortal);
            this.formGroupAgregarProveedorTransporte.get('p_Usuario').setValue(this.ProveedorTransporte[0].usuario);
            this.formGroupAgregarProveedorTransporte.get('p_Contrasena').setValue(this.ProveedorTransporte[0].contrasena);
            this.formGroupAgregarProveedorTransporte.get('p_IDAseguradora').setValue(this.ProveedorTransporte[0].idAseguradora);
            const fechaInicio = this.convertirFecha(this.ProveedorTransporte[0].fechaInicio);
            const fechaFin = this.convertirFecha(this.ProveedorTransporte[0].fechaFin);
            const fechaIngreso = this.convertirFecha(this.ProveedorTransporte[0].fechaIngreso);
            this.formGroupAgregarProveedorTransporte.get('p_FechaInicio').setValue(fechaInicio);
            this.formGroupAgregarProveedorTransporte.get('p_FechaFin').setValue(fechaFin);
            this.formGroupAgregarProveedorTransporte.get('p_FechaIngreso').setValue(fechaIngreso);

            this.formGroupAgregarProveedorTransporte.get('p_Observaciones').setValue(this.ProveedorTransporte[0].observaciones);
            this.formGroupAgregarProveedorTransporte.get('p_NumeroPoliza').setValue(this.ProveedorTransporte[0].numeroPoliza);
            this.formGroupAgregarProveedorTransporte.get('p_Impuesto').setValue(this.ProveedorTransporte[0].impuesto);
            
            const vigenciaDesde = this.convertirFecha(this.ProveedorTransporte[0].vigenciaDesde);
            const vigenciaHasta = this.convertirFecha(this.ProveedorTransporte[0].vigenciaHasta);
            const fechaIngresoImp = this.convertirFecha(this.ProveedorTransporte[0].fechaIngresoImp);
            this.formGroupAgregarProveedorTransporte.get('p_VigenciaDesde').setValue(vigenciaDesde);
            this.formGroupAgregarProveedorTransporte.get('p_VigenciaHasta').setValue(vigenciaHasta);
            this.formGroupAgregarProveedorTransporte.get('p_FechaIngresoImp').setValue(fechaIngresoImp);

            this.formGroupAgregarProveedorTransporte.get('p_TipoImpuesto').setValue(this.ProveedorTransporte[0].tipoImpuesto);
            this.formGroupAgregarProveedorTransporte.get('p_Monto').setValue(this.ProveedorTransporte[0].monto);
            this.formGroupAgregarProveedorTransporte.get('p_Swift').setValue(this.ProveedorTransporte[0].swift);
            this.formGroupAgregarProveedorTransporte.get('p_ABA').setValue(this.ProveedorTransporte[0].aba);
            this.formGroupAgregarProveedorTransporte.get('p_Bic').setValue(this.ProveedorTransporte[0].bic);
            this.formGroupAgregarProveedorTransporte.get('p_IDBancoIntermediario').setValue(this.ProveedorTransporte[0].idBancoIntermediario);
            this.formGroupAgregarProveedorTransporte.get('p_IDBancoPesos').setValue(this.ProveedorTransporte[0].idBancoPesos);
            this.formGroupAgregarProveedorTransporte.get('p_NumCtaPesos').setValue(this.ProveedorTransporte[0].numCtaPesos);
            this.formGroupAgregarProveedorTransporte.get('p_ClabeP').setValue(this.ProveedorTransporte[0].clabeP);
            this.formGroupAgregarProveedorTransporte.get('p_IDBancoDolar').setValue(this.ProveedorTransporte[0].idBancoDolar);
            this.formGroupAgregarProveedorTransporte.get('p_NumCtaDolares').setValue(this.ProveedorTransporte[0].numCtaDolares);
            this.formGroupAgregarProveedorTransporte.get('p_ClabeD').setValue(this.ProveedorTransporte[0].clabeD);

            this.getCPostal(this.formGroupAgregarProveedorTransporte.value.p_CPostal, 2);
          } else {
            this.listaCatalogos = [];
          }
          this.operacionRespuesta.EstaEjecutando = false;
        },
        async (err) => {
          this.operacionRespuesta.EstaEjecutando = false;
        }
      );

    // this.services.HttpGet(this.modelo_configuracion.serviciosOperaciones + "/Proveedores/GetServicio_x_Proveedores?p_IDProveedor=" + idProveedor)
    //   .subscribe(
    //     (tempdate) => {
    //       if (tempdate) {

    //         // Obtener todos los elementos de tipo checkbox en la página
    //         const checkboxes = document.querySelectorAll('input[type="checkbox"]');

    //         // Recorrer los checkboxes y comparar sus ids con los de this.listaNegocios
    //         checkboxes.forEach((checkbox: HTMLInputElement) => {
    //           if (checkbox.id.startsWith('checkbox_')) {
    //             const checkboxId = parseInt(checkbox.id.split('_')[1]); // Convertir el id del checkbox a número

    //             // Verificar si el id del checkbox está presente en this.listaNegocios
    //             const servicioEncontrado = tempdate.response.find(servicio => servicio.idServicio === checkboxId);

    //             if (servicioEncontrado) {
    //               checkbox.checked = true; // Marcar el checkbox como "checked"
    //             }
    //           }
    //         });
    //       }
    //       this.operacionRespuesta.EstaEjecutando = false;
    //     },
    //     async (err) => {
    //       this.operacionRespuesta.EstaEjecutando = false;
    //     }
    //   );

    this.services.HttpGet(this.modelo_configuracion.serviciosOperaciones + "/Proveedores/GetContactosProveedor?p_IDProveedor=" + idProveedor)
      .subscribe(
        (tempdate) => {
          if (tempdate) {
            this.listContactosTemp = tempdate.response as Lista_temp_contactos_modelo_response[];
          } else {
            this.listContactosTemp = [];
          }
          this.operacionRespuesta.EstaEjecutando = false;
        },
        async (err) => {
          this.operacionRespuesta.EstaEjecutando = false;
        }
      );
  }

  convertirFecha(fecha: string | Date): string {
    const nuevaFecha = new Date(fecha); // Convertir a objeto Date si es cadena
    const year = nuevaFecha.getFullYear();
    const month = (nuevaFecha.getMonth() + 1).toString().padStart(2, '0'); // Mes (0 indexado) y con 2 dígitos
    const day = nuevaFecha.getDate().toString().padStart(2, '0'); // Día con 2 dígitos
    return `${year}-${month}-${day}`;
  }

  obtenerCatalogosGenericos(idCatMaestro) {
    this.operacionRespuesta.EstaEjecutando = true;
    this.services
      .HttpGet(
        this.modelo_configuracion.serviciosOperaciones + "/Catalogos/GetListaCatalogoCorto?p_idCatMaestro=" + idCatMaestro)
      .subscribe(
        (tempdate) => {
          if (tempdate) {

            switch (idCatMaestro) {
              case 12:
                this.listaCatalogos = [] = tempdate.response as Lista_catalogo_corto_modelo_response[];
                break;
              case 13:
                this.listaSituacionProveedor = [] = tempdate.response as Lista_catalogo_corto_modelo_response[];
                break;
            }

          } else {
            this.listaCatalogos = [];
          }
          this.operacionRespuesta.EstaEjecutando = false;
        },
        async (err) => {
          this.operacionRespuesta.EstaEjecutando = false;
        }
      );
  }

  obtenerCatalogosCortos(p_catalogo, p_idcatologo) {
    this.operacionRespuesta.EstaEjecutando = true;
    this.services
      .HttpGet(
        this.modelo_configuracion.serviciosOperaciones + "/Catalogos/GetListaGeneralCorto?p_catalogo=" + p_catalogo + "&p_idcatalogo=" + p_idcatologo)
      .subscribe(
        (tempdate) => {
          if (tempdate) {

            switch (p_catalogo) {
              case 'Grupo':
                this.listaGrupo = [] = tempdate.response as Lista_general_corto_modelo_response[];
                break;
              case 'SubGrupo':
                this.listaSubGrupo = [] = tempdate.response as Lista_general_corto_modelo_response[];
                break;
              case 'FPI':
                this.listaFPI = [] = tempdate.response as Lista_general_corto_modelo_response[];
                break;
              case 'FPG':
                this.listaFPG = [] = tempdate.response as Lista_general_corto_modelo_response[];
                break;
              case 'IF':
                this.listaIF = [] = tempdate.response as Lista_general_corto_modelo_response[];
                break;
              case 'IISH':
                this.listaIISH = [] = tempdate.response as Lista_general_corto_modelo_response[];
                break;
              case 'CS':
                this.listaCS = [] = tempdate.response as Lista_general_corto_modelo_response[];
                break;
              case 'BKP':
                this.listaBKP = [] = tempdate.response as Lista_general_corto_modelo_response[];
                break;
              case 'BKD':
                this.listaBKD = [] = tempdate.response as Lista_general_corto_modelo_response[];
                break;
              case 'SPH':
                this.listaSPH = [] = tempdate.response as Lista_general_corto_modelo_response[];
                break;
              case 'SPT':
                this.listaSPT = [] = tempdate.response as Lista_general_corto_modelo_response[];
                break;
              case 'SPO':
                this.listaSPO = [] = tempdate.response as Lista_general_corto_modelo_response[];
                break;
              case 'TCONTACTO':
                this.listaTCONTACTO = [] = tempdate.response as Lista_general_corto_modelo_response[];
                break;
              case 'CPT':
                this.listaCPT = [] = tempdate.response as Lista_general_corto_modelo_response[];
                break;
              case 'TI':
                this.listaTI = [] = tempdate.response as Lista_general_corto_modelo_response[];
                break;
              case 'CA':
                this.listaCA = [] = tempdate.response as Lista_general_corto_modelo_response[];
                break;
              case 'BIT':
                this.listaBIT = [] = tempdate.response as Lista_general_corto_modelo_response[];
                break;
            }

          }
          this.operacionRespuesta.EstaEjecutando = false;
        },
        async (err) => {
          this.operacionRespuesta.EstaEjecutando = false;
        }
      );
  }

  openModalFormProveedor(esEditable: boolean) {
    this.esEditable = esEditable;
    this.formGroupAgregarProveedor.reset({
      p_Nombre: '',
      p_RazonSocial: '',
      p_RFC: '',
      p_IDCategoria: 0,
      p_IDSProveedor: 0,
      p_IDGrupo: 0,
      p_IDSubGrupo: 0,
      p_LADA1: '',
      p_Tel1: '',
      p_Ext1: '',
      p_LADA2: '',
      p_Tel2: '',
      p_Ext2: '',
      p_Correo1: '',
      p_Correo2: '',
      p_Web: '',
      p_Calle: '',
      p_NExt: '',
      p_NInt: '',
      p_CPostal: '',
      p_Colonia: '',
      p_Ciudad: '',
      p_CiudadTarifario: '',
      p_Estado: '',
      p_Pais: 'México',
      p_Referencia: '',
      p_CodigoKetzal: '',
      p_NumHabitaciones: 0,
      p_Comentario: '',
      p_IDFormaPagoI: 0,
      p_IDFPagoGrupo: 0,
      p_IDImpFederal: 0,
      p_IDImpuestoISH: 0,
      p_IDCargoServicio: 0,
      p_IDBancoPesos: 0,
      p_NumCtaPesos: '',
      p_IDBancoDolar: 0,
      p_NumCtaDolares: '',
      p_InformacionBancoP: '',
      p_InformacionBancoD: '',
      p_InfoFPagoGrupo: '',
      p_IVA: 0,
      p_ClabeP: '',
      p_ClabeD: '',
      p_Penalizaciones: '',
      p_InfoTarifas: '',
      p_Restricciones: ''
    });

    this.modalAgregarProveedor = this.modalService.open(this.modalAddProveedor, { ariaLabelledBy: 'modal-basic-title', backdrop: 'static', size: 'lg' });
  }

  openModalFormProveedorTransporte(esEditable: boolean) {
    this.esEditable = esEditable;
    this.formGroupAgregarProveedorTransporte.reset({
      p_Nombre: '',
      p_RazonSocial: '',
      p_RFC: '',
      p_CodigoKetzal: '',
      p_IDCategoria: 0,
      p_LADA1:  '',
      p_Tel1:  '',
      p_Ext1:  '',
      p_Tel2:  '',
      p_Correo1:  '',
      p_Correo2:  '',
      p_Web:  '',
      p_CreditoIndividual: 0,
      p_CreditoGrupal: 0,
      p_LimiteCreditoInd: 0,
      p_LimiteCreditoGrup: 0,
      p_IDMonedaInd: 0,
      p_IDMonedaGrup: 0,
      p_DiasCreditoInd: 0,
      p_DiasCreditoGrup: 0,
      p_Calle: '',
      p_NExt: '',
      p_NInt: '',
      p_CPostal: '',
      p_Colonia: '',
      p_Ciudad: '',
      p_Estado: '',
      p_Pais: '',
      p_Destino: '',
      p_IDBancoPesos: 0,
      p_IDBancoDolar: 0,
      p_NumCtaPesos: '',
      p_NumCtaDolares: '',
      p_ClabeP: '',
      p_ClabeD: '',
      p_Swift: '',
      p_ABA: '',
      p_Bic: '',
      p_IDBancoIntermediario: 0,
      p_URLPortal: '',
      p_Usuario: '',
      p_Contrasena: '',
      p_NumeroPoliza: '',
      p_IDAseguradora: 0,
      p_FechaInicio: '2000-01-01',
      p_FechaFin: '2000-01-01',
      p_FechaIngreso: '2000-01-01',
      p_Observaciones: '',
      p_RutaLocal: '',
      p_Url: '',
      p_Impuesto: '',
      p_VigenciaDesde: '2000-01-01',
      p_VigenciaHasta: '2000-01-01',
      p_FechaIngresoImp: '2000-01-01',
      p_TipoImpuesto: 0,
      p_Monto: 0,
      p_contactos: '',
    
    });

    this.modalAgregarProveedorTransporte = this.modalService.open(this.modalAddProveedorTransporte, { ariaLabelledBy: 'modal-basic-title', backdrop: 'static', size: 'lg' });
  }

  openDesactivar(idProveedor, nombreProveedor) {
    this._idProveedor = idProveedor;
    this._nombreProveedor = nombreProveedor;
    this.modalEliminarProveedor = this.modalService.open(this.ModalDesactivar, { ariaLabelledBy: 'modal-basic-title' });
  }

  openMensajes(Mensaje: string, Error: boolean, titulo: string) {
    this.modalrefMsg = this.modalService.open(ModuloModalMensajeComponent, { ariaLabelledBy: "modal-basic-title" });
    this.modalrefMsg.componentInstance.mensajesExito = [];
    this.modalrefMsg.componentInstance.mensajesError = [];
    this.modalrefMsg.componentInstance.mensajeTitulo = 'Proveedores';

    if (Error) {
      this.modalrefMsg.componentInstance.showErrors = true;
      this.modalrefMsg.componentInstance.mensajesError.push(Mensaje);
    } else {
      this.modalrefMsg.componentInstance.showExitos = true;
      this.modalrefMsg.componentInstance.mensajesExito.push(Mensaje);
    }
  }

  onCheckboxChange(event: any) {
    const checkboxValue = event.target.value;
    if (event.target.checked) {
      // Agregar el valor al array de checkboxes seleccionados
      this.selectedCheckboxes.push(checkboxValue);
    } else {
      // Eliminar el valor del array de checkboxes seleccionados
      const index = this.selectedCheckboxes.indexOf(checkboxValue);
      if (index > -1) {
        this.selectedCheckboxes.splice(index, 1);
      }
    }
  }

  onCheckboxChangeCatTrans(event: any) {
    const checkboxValue = event.target.value;
    if (event.target.checked) {
      // Agregar el valor al array de checkboxes seleccionados
      this.selectedCheckboxesCatTrans.push(checkboxValue);
    } else {
      // Eliminar el valor del array de checkboxes seleccionados
      const index = this.selectedCheckboxesCatTrans.indexOf(checkboxValue);
      if (index > -1) {
        this.selectedCheckboxesCatTrans.splice(index, 1);
      }
    }
  }

  eliminarProveedor(idProveedor) {
    this.operacionRespuesta.EstaEjecutando = true;
    this.services.HttpDelete(this.modelo_configuracion.serviciosOperaciones + "/Proveedores/DeleteProveedor?p_idProveedor=" + idProveedor)
      .subscribe(
        (tempdate) => {
          if (tempdate.response[0].success == 1) {
            this.openMensajes(tempdate.response[0].msg, false, '');

            this.obtenerProveedores(this._tipoProveedor);

            this.modalEliminarProveedor.close();
          } else {
            this.openMensajes(tempdate.response[0].msg, true, '');
          }
          this.operacionRespuesta.EstaEjecutando = false;
        },
        async (err) => {
          this.openMensajes("No se pudo realizar la acción", true, '');
          this.operacionRespuesta.EstaEjecutando = false;
        }
      );
  }

  getCPostal(_cpostal: any, opcion) {
    this.operacionRespuesta.EstaEjecutando = true;

    var cpostal;
    if (opcion == 1) {
      cpostal = _cpostal.target.value;
    } else {
      cpostal = _cpostal;
    }
    // const cpostal = _cpostal.target.value;

    this.services
      .HttpGet(
        this.modelo_configuracion.serviciosOperaciones + "/Proveedores/GetListaColonias?p_CPostal=" + cpostal)
      .subscribe(
        (tempdate) => {
          if (tempdate) {
            this.listaColonias = tempdate.response as Cpostal_response[];
            this.formGroupAgregarProveedor.value.p_Ciudad = this.listaColonias[0].municipio;
            this.formGroupAgregarProveedor.value.p_Estado = this.listaColonias[0].estado;
            this.formGroupAgregarProveedor.patchValue(this.formGroupAgregarProveedor.value);
            this.formGroupAgregarProveedorTransporte.value.p_Ciudad = this.listaColonias[0].municipio;
            this.formGroupAgregarProveedorTransporte.value.p_Estado = this.listaColonias[0].estado;
            this.formGroupAgregarProveedorTransporte.patchValue(this.formGroupAgregarProveedorTransporte.value);
            this.sinCP = false;
            this.operacionRespuesta.EstaEjecutando = false;
          } else {
            this.listaColonias = [];
            this.formGroupAgregarProveedor.value.p_Ciudad = '';
            this.formGroupAgregarProveedor.value.p_Estado = '';
            this.formGroupAgregarProveedor.patchValue(this.formGroupAgregarProveedor.value);
            this.formGroupAgregarProveedorTransporte.value.p_Ciudad = '';
            this.formGroupAgregarProveedorTransporte.value.p_Estado = '';
            this.formGroupAgregarProveedorTransporte.patchValue(this.formGroupAgregarProveedorTransporte.value);
            this.sinCP = true;
          }
          this.operacionRespuesta.EstaEjecutando = false;
        },
        async (err) => {
          this.operacionRespuesta.EstaEjecutando = false;
        }
      );
  }

  agregarContacto() {
    if (this.funcionalidad != 'Editar') {
      this.funcionalidad = 'Nuevo';
    }
    this._idUsuario = 0;
    this.openModalFormUsuario(true);
  }

  openModalFormUsuario(esEditable: boolean) {
    this.esEditable = esEditable;
    this.accionUsuario = 'Nuevo';
    // this.listContactosTemp = [];
    this.formGroupAgregarUsuario.reset({
      p_tipoContacto: 0, p_nombre: '', p_aPaterno: '', p_aMaterno: '', p_Email1: '', p_Email2: '',
      p_LADA1: '', p_Tel1: '', p_Ext1: '', p_LADA2: '', p_Tel2: '', p_Ext2: '', p_observaciones: ''
    });

    this.modalAgregarUsuario = this.modalService.open(this.modalAddUsuario, { ariaLabelledBy: 'modal-basic-title', backdrop: 'static', size: 'lg' });
  }

  GuardarUsuario() {
    const nuevoContacto: Lista_temp_contactos_modelo_response = {
      p_IDContacto: 0,
      p_tipoContacto: this.formGroupAgregarUsuario.value.p_tipoContacto,
      p_nombre: this.formGroupAgregarUsuario.value.p_nombre,
      p_aPaterno: this.formGroupAgregarUsuario.value.p_aPaterno,
      p_aMaterno: this.formGroupAgregarUsuario.value.p_aMaterno,
      p_Email1: this.formGroupAgregarUsuario.value.p_Email1,
      p_Email2: this.formGroupAgregarUsuario.value.p_Email2,
      p_LADA1: this.formGroupAgregarUsuario.value.p_LADA1,
      p_Tel1: this.formGroupAgregarUsuario.value.p_Tel1,
      p_Ext1: this.formGroupAgregarUsuario.value.p_Ext1,
      p_LADA2: this.formGroupAgregarUsuario.value.p_LADA2,
      p_Tel2: this.formGroupAgregarUsuario.value.p_Tel2,
      p_Ext2: this.formGroupAgregarUsuario.value.p_Ext2,
      p_Observaciones: this.formGroupAgregarUsuario.value.p_Observaciones
    };

    this.listContactosTemp.push(nuevoContacto);

    this.formGroupAgregarUsuario.reset({

      p_tipoContacto: 0,
      p_nombre: "",
      p_aPaterno: "",
      p_aMaterno: "",
      p_Email1: "",
      p_Email2: "",
      p_LADA1: 0,
      p_Tel1: 0,
      p_Ext1: 0,
      p_LADA2: 0,
      p_Tel2: 0,
      p_Ext2: 0,
      p_Observaciones: "",

    });

    this.modalAgregarUsuario.close();

  }

  editarServicioOtros(index: number) {
    this.accionUsuario = 'Editar';
    this._index = index;
    this.modalAgregarUsuario = this.modalService.open(this.modalAddUsuario, { ariaLabelledBy: 'modal-basic-title', backdrop: 'static', size: 'lg' });

    this.formGroupAgregarUsuario.get('p_tipoContacto').setValue(this.listContactosTemp[index].p_tipoContacto);
    this.formGroupAgregarUsuario.get('p_nombre').setValue(this.listContactosTemp[index].p_nombre);
    this.formGroupAgregarUsuario.get('p_aPaterno').setValue(this.listContactosTemp[index].p_aPaterno);
    this.formGroupAgregarUsuario.get('p_aMaterno').setValue(this.listContactosTemp[index].p_aMaterno);
    this.formGroupAgregarUsuario.get('p_Email1').setValue(this.listContactosTemp[index].p_Email1);
    this.formGroupAgregarUsuario.get('p_Email2').setValue(this.listContactosTemp[index].p_Email2);
    this.formGroupAgregarUsuario.get('p_LADA1').setValue(this.listContactosTemp[index].p_LADA1);
    this.formGroupAgregarUsuario.get('p_Tel1').setValue(this.listContactosTemp[index].p_Tel1);
    this.formGroupAgregarUsuario.get('p_Ext1').setValue(this.listContactosTemp[index].p_Ext1);
    this.formGroupAgregarUsuario.get('p_LADA2').setValue(this.listContactosTemp[index].p_LADA2);
    this.formGroupAgregarUsuario.get('p_Tel2').setValue(this.listContactosTemp[index].p_Tel2);
    this.formGroupAgregarUsuario.get('p_Ext2').setValue(this.listContactosTemp[index].p_Ext2);
    this.formGroupAgregarUsuario.get('p_Observaciones').setValue(this.listContactosTemp[index].p_Observaciones);

  }

  _editarServicioOtros() {
    this.listContactosTemp[this._index].p_tipoContacto = this.formGroupAgregarUsuario.value.p_tipoContacto;
    this.listContactosTemp[this._index].p_nombre = this.formGroupAgregarUsuario.value.p_nombre;
    this.listContactosTemp[this._index].p_aPaterno = this.formGroupAgregarUsuario.value.p_aPaterno;
    this.listContactosTemp[this._index].p_aMaterno = this.formGroupAgregarUsuario.value.p_aMaterno;
    this.listContactosTemp[this._index].p_Email1 = this.formGroupAgregarUsuario.value.p_Email1;
    this.listContactosTemp[this._index].p_Email2 = this.formGroupAgregarUsuario.value.p_Email2;
    this.listContactosTemp[this._index].p_LADA1 = this.formGroupAgregarUsuario.value.p_LADA1;
    this.listContactosTemp[this._index].p_Tel1 = this.formGroupAgregarUsuario.value.p_Tel1;
    this.listContactosTemp[this._index].p_Ext1 = this.formGroupAgregarUsuario.value.p_Ext1;
    this.listContactosTemp[this._index].p_LADA2 = this.formGroupAgregarUsuario.value.p_LADA2;
    this.listContactosTemp[this._index].p_Tel2 = this.formGroupAgregarUsuario.value.p_Tel2;
    this.listContactosTemp[this._index].p_Ext2 = this.formGroupAgregarUsuario.value.p_Ext2;
    this.listContactosTemp[this._index].p_Observaciones = this.formGroupAgregarUsuario.value.p_Observaciones;

    this.formGroupAgregarUsuario.reset({

      p_tipoContacto: 0,
      p_nombre: "",
      p_aPaterno: "",
      p_aMaterno: "",
      p_Email1: "",
      p_Email2: "",
      p_LADA1: '',
      p_Tel1: '',
      p_Ext1: '',
      p_LADA2: '',
      p_Tel2: '',
      p_Ext2: '',
      p_Observaciones: "",

    });

    this.modalAgregarUsuario.close();

  }

  elimnarServicioOtros(index) {
    this.listContactosTemp.splice(index, 1);
  }

}
