import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { ThemeConstants } from 'src/app/@espire/shared/config/theme-constant';
import { RespuestaGenerica } from 'src/app/generales/RespuestaGenerica';
import { ServiciosRutas } from 'src/app/generales/ServiciosRutas';
import { AuthGuard } from 'src/app/guards/AuthGuard';
import { ModuloModalMensajeComponent } from 'src/app/shared/modulo-modal-mensaje/modulo-modal-mensaje.component';
import { Catalogo_modelo_response, Lista_catalogos_modelo_response, Lista_sub_catalogos_modelo_response } from 'src/model/Catalogos/Colaboradores';
import { ServiceGenerico } from 'src/services/service-generico.service';

@Component({
  selector: 'app-maestros',
  templateUrl: './maestros.component.html',
  styleUrls: ['./maestros.component.css'],
  providers: [ServiceGenerico]
})
export class MaestrosComponent implements OnInit {
  @ViewChild("modalAddColaborador", { static: false }) modalAddColaborador: TemplateRef<any>;
  @ViewChild("modalAddSubCatalogo", { static: false }) modalAddSubCatalogo: TemplateRef<any>;
  @ViewChild("modalConfHorarioColaborador", { static: false }) modalConfHorarioColaborador: TemplateRef<any>;
  @ViewChild("contentDesactivar", { static: false }) ModalDesactivar: TemplateRef<any>;
  @ViewChild("contentDesactivarSubCat", { static: false }) ModalDesactivarSubCat: TemplateRef<any>;
  modalrefMsg: NgbModalRef;
  modalAgregarCatalogo: NgbModalRef;
  modalAgregarSubCatalogo: NgbModalRef;
  modalEliminarCatalogo: NgbModalRef;

  formGroupAgregarCatalogoMaestro: FormGroup;
  formGroupAgregarSubCatalogoMaestro: FormGroup;

  dtOptionsProv: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  isDtInitialized: boolean = false;

  dtOptions2: DataTables.Settings = {};
  dtTrigger2: Subject<any> = new Subject();
  @ViewChild(DataTableDirective, { static: false })
  dtElement2: DataTableDirective;
  isDtInitialized2: boolean = false;


  public archivos: any = [];
  public usuarioId: string = "";

  private modelo_configuracion: ServiciosRutas;
  listaCatalogos: Lista_catalogos_modelo_response[];
  Colaborador: Catalogo_modelo_response;
  public operacionRespuesta: RespuestaGenerica;
  esEditable: boolean = true;
  funcionalidad: string;
  _idCatMaestro: number;
  private _nombreCat: any;
  listaSubCatalogoMaestro: Lista_sub_catalogos_modelo_response[];
  private _idCatSubCatMaestro: number;
  nombreSubCat: string;
  modalEliminarSubCatalogo: NgbModalRef;
  private _nombreSubCat: string;

  constructor(
    private auth: AuthGuard,
    private themeConstants: ThemeConstants,
    private themeConstants2: ThemeConstants,
    private services: ServiceGenerico,
    private router: Router,
    private modalService: NgbModal,
    private fb: FormBuilder,
    private sanitizer: DomSanitizer,
  ) {
    this.modelo_configuracion = new ServiciosRutas();
    this.operacionRespuesta = new RespuestaGenerica();
  }

  ngOnInit() {
    this.inicializaTabla();
    this.obtenerCatalogosMaestros();
    this.inicializarFormAgregarCatalogoMaestro();
    this.inicializarFormAgregarSubCatalogoMaestro();

  }

  inicializaTabla() {
    this.dtOptionsProv = this.themeConstants.dtOptionsProv;
    this.dtTrigger = new Subject();
    this.dtOptions2 = this.themeConstants2.dtOptions;
    this.dtTrigger2 = new Subject();
  }

  renderTabla() {
    if ("dtInstance" in this.dtElement) {
      this.dtElement.dtInstance.then((instancia: DataTables.Api) => {
        instancia.destroy();
        this.dtTrigger.next();
      });
    } else {
      this.dtTrigger.next();
    }
  }
  
  renderTabla2() {
    if ("dtInstance" in this.dtElement2) {
      this.dtElement2.dtInstance.then((instancia2: DataTables.Api) => {
        instancia2.destroy();
        this.dtTrigger2.next();
      });
    } else {
      this.dtTrigger2.next();
    }
  }
  
  obtenerCatalogosMaestros() {
    this.operacionRespuesta.EstaEjecutando = true;
    this.services
      .HttpGet(
        this.modelo_configuracion.serviciosOperaciones + "/Catalogos/GetListaCatalogos?p_estatus=1")
      .subscribe(
        (tempdate) => {
          if (tempdate) {
            this.listaCatalogos = [] = tempdate.response as Lista_catalogos_modelo_response[];
            this.renderTabla();
          } else {
            this.listaCatalogos = [];
            this.renderTabla();
          }
          this.operacionRespuesta.EstaEjecutando = false;
        },
        async (err) => {
          this.operacionRespuesta.EstaEjecutando = false;
        }
      );
  }

  obtenerSubCatalogosMaestros(idCatMaestro) {
    this.operacionRespuesta.EstaEjecutando = true;
    this.services
      .HttpGet(
        this.modelo_configuracion.serviciosOperaciones + "/Catalogos/GetListaCatalogoCorto?p_idCatMaestro="+idCatMaestro)
      .subscribe(
        (tempdate) => {
          if (tempdate) {
            this.listaSubCatalogoMaestro = [] = tempdate.response as Lista_sub_catalogos_modelo_response[];
            // this.renderTabla2();
          } else {
            this.listaSubCatalogoMaestro = [];
            // this.renderTabla2();
          }
          this.operacionRespuesta.EstaEjecutando = false;
        },
        async (err) => {
          this.operacionRespuesta.EstaEjecutando = false;
        }
      );
  }

  inicializarFormAgregarCatalogoMaestro() {
    this.formGroupAgregarCatalogoMaestro = this.fb.group({
      p_clave: ["", Validators.required], 
      p_nombre: ["", Validators.required], 
      p_descripcion: [""]
    });
  }
  inicializarFormAgregarSubCatalogoMaestro() {
    this.formGroupAgregarSubCatalogoMaestro = this.fb.group({
      p_clave: ["", Validators.required], 
      p_nombre: ["", Validators.required], 
    });
  }

  agregarCatalogoMaestro() {
    this.funcionalidad = 'Nuevo';
    this._idCatMaestro = 0;
    this.openModalFormCatalogoMaestro(true);
  }
  
  agregarSubCatalogoMaestro() {
    this.funcionalidad = 'Nuevo';
    this._idCatSubCatMaestro = 0;
    this.openModalFormSubCatalogoMaestro(true);
  }

  GuardarCatalogoMaestro() {
    this.operacionRespuesta.EstaEjecutando = true;
    let model = this.formGroupAgregarCatalogoMaestro.value;
    model.p_idCatMaestro = this._idCatMaestro;

    if (this.funcionalidad == 'Nuevo') {
      this.services.HttpPost(model, this.modelo_configuracion.serviciosOperaciones + "/Catalogos/PostCatalogo")
        .subscribe(
          (tempdate) => {
            if (tempdate.response[0].success == 1) {
              this.openMensajes(tempdate.response[0].msg, false, '');
              this.obtenerCatalogosMaestros();
              this.modalAgregarCatalogo.close();
            } else {
              this.openMensajes(tempdate.response[0].msg, true, '');
            }
            this.operacionRespuesta.EstaEjecutando = false;
          },
          async (err) => {
            this.openMensajes("No se pudo realizar la acción", true, '');
            this.operacionRespuesta.EstaEjecutando = false;
          }
        );
    } else {
      this.services.HttpPut(model, this.modelo_configuracion.serviciosOperaciones + "/Catalogos/PutCatalogo")
        .subscribe(
          (tempdate) => {
            if (tempdate.response[0].success == 1) {
              this.openMensajes(tempdate.response[0].msg, false, '');
              this.obtenerCatalogosMaestros();
              this.modalAgregarCatalogo.close();
            } else {
              this.openMensajes(tempdate.response[0].msg, true, '');
            }
            this.operacionRespuesta.EstaEjecutando = false;
          },
          async (err) => {
            this.openMensajes("No se pudo realizar la acción", true, '');
            this.operacionRespuesta.EstaEjecutando = false;
          }
        );
    }
  }

  verDetalleCatalogoMaestro(idCatMaestro) {
    this.esEditable = false;
    this.openModalFormCatalogoMaestro(false);
    this.operacionRespuesta.EstaEjecutando = true;
    this.services.HttpGet(this.modelo_configuracion.serviciosOperaciones + "/Catalogos/GetCatalogo_x_id?p_idcatmaestro=" + idCatMaestro)
      .subscribe(
        (tempdate) => {
          if (tempdate) {
            this.Colaborador = tempdate.response as Catalogo_modelo_response;
            this.formGroupAgregarCatalogoMaestro.value.p_clave = this.Colaborador[0].clave;
            this.formGroupAgregarCatalogoMaestro.value.p_nombre = this.Colaborador[0].nombre;
            this.formGroupAgregarCatalogoMaestro.value.p_descripcion = this.Colaborador[0].descripcion;
            this.formGroupAgregarCatalogoMaestro.patchValue(this.formGroupAgregarCatalogoMaestro.value);
          } else {
            this.listaCatalogos = [];
          }
          this.operacionRespuesta.EstaEjecutando = false;
        },
        async (err) => {
          this.operacionRespuesta.EstaEjecutando = false;
        }
      );

      this.obtenerSubCatalogosMaestros(idCatMaestro);
  }

  editarCatalogoMaestro(idCatMaestro) {
    this.funcionalidad = 'Editar';
    this._idCatMaestro = idCatMaestro;
    this.openModalFormCatalogoMaestro(true);
    this.operacionRespuesta.EstaEjecutando = true;
    this.services.HttpGet(this.modelo_configuracion.serviciosOperaciones + "/Catalogos/GetCatalogo_x_id?p_idcatmaestro=" + idCatMaestro)
      .subscribe(
        (tempdate) => {
          if (tempdate) {
            this.Colaborador = tempdate.response as Catalogo_modelo_response;
            this.formGroupAgregarCatalogoMaestro.value.p_clave = this.Colaborador[0].clave;
            this.formGroupAgregarCatalogoMaestro.value.p_nombre = this.Colaborador[0].nombre;
            this.formGroupAgregarCatalogoMaestro.value.p_descripcion = this.Colaborador[0].descripcion;
            this.formGroupAgregarCatalogoMaestro.patchValue(this.formGroupAgregarCatalogoMaestro.value);
          } else {
            this.listaCatalogos = [];
          }
          this.operacionRespuesta.EstaEjecutando = false;
        },
        async (err) => {
          this.operacionRespuesta.EstaEjecutando = false;
        }
      );
      this.obtenerSubCatalogosMaestros(idCatMaestro);
      
  }
 
  editarSubCatalogoMaestro(idCatSubCatMaestro) {
    this.funcionalidad = 'Editar';
    this._idCatSubCatMaestro = idCatSubCatMaestro;
    this.openModalFormSubCatalogoMaestro(true);
    this.operacionRespuesta.EstaEjecutando = true;
    this.services.HttpGet(this.modelo_configuracion.serviciosOperaciones + "/Catalogos/GetSubCatalogo_x_id?p_idcatsubcatmaestro=" + idCatSubCatMaestro)
      .subscribe(
        (tempdate) => {
          if (tempdate) {
            this.Colaborador = tempdate.response as Catalogo_modelo_response;
            this.formGroupAgregarSubCatalogoMaestro.value.p_clave = this.Colaborador[0].clave;
            this.formGroupAgregarSubCatalogoMaestro.value.p_nombre = this.Colaborador[0].nombre;
            this.formGroupAgregarSubCatalogoMaestro.patchValue(this.formGroupAgregarSubCatalogoMaestro.value);
          } else {
            this.listaSubCatalogoMaestro = [];
          }
          this.operacionRespuesta.EstaEjecutando = false;
        },
        async (err) => {
          this.operacionRespuesta.EstaEjecutando = false;
        }
      );
      
  }

  GuardarSubCatalogoMaestro() {
    this.operacionRespuesta.EstaEjecutando = true;
    let model = this.formGroupAgregarSubCatalogoMaestro.value;
    model.p_idCatSubCatMaestro = this._idCatSubCatMaestro;
    model.p_idCatMaestro = this._idCatMaestro;
    if (this.funcionalidad == 'Nuevo') {
      this.services.HttpPost(model, this.modelo_configuracion.serviciosOperaciones + "/Catalogos/PostSubCatalogo")
        .subscribe(
          (tempdate) => {
            if (tempdate.response[0].success == 1) {
              this.openMensajes(tempdate.response[0].msg, false, '');
              this.obtenerSubCatalogosMaestros(this._idCatMaestro);

              this.modalAgregarSubCatalogo.close();
            } else {
              this.openMensajes(tempdate.response[0].msg, true, '');
            }
            this.operacionRespuesta.EstaEjecutando = false;
          },
          async (err) => {
            this.openMensajes("No se pudo realizar la acción", true, '');
            this.operacionRespuesta.EstaEjecutando = false;
          }
        );
    } else {
      this.services.HttpPut(model, this.modelo_configuracion.serviciosOperaciones + "/Catalogos/PutSubCatalogo")
        .subscribe(
          (tempdate) => {
            if (tempdate.response[0].success == 1) {
              this.openMensajes(tempdate.response[0].msg, false, '');
              this.obtenerSubCatalogosMaestros(this._idCatMaestro);
              this.modalAgregarSubCatalogo.close();
            } else {
              this.openMensajes(tempdate.response[0].msg, true, '');
            }
            this.operacionRespuesta.EstaEjecutando = false;
          },
          async (err) => {
            this.openMensajes("No se pudo realizar la acción", true, '');
            this.operacionRespuesta.EstaEjecutando = false;
          }
        );
    }
  }


  eliminarCatalogoMaestro(idCatMaestro) {
    this.operacionRespuesta.EstaEjecutando = true;
    this.services.HttpDelete(this.modelo_configuracion.serviciosOperaciones + "/Catalogos/DeleteCatalogo?p_idcatmaestro=" + idCatMaestro)
      .subscribe(
        (tempdate) => {
          if (tempdate.response[0].success == 1) {
            this.openMensajes(tempdate.response[0].msg, false, '');
            this.obtenerCatalogosMaestros();
            this.modalEliminarCatalogo.close();
          } else {
            this.openMensajes(tempdate.response[0].msg, true, '');
          }
          this.operacionRespuesta.EstaEjecutando = false;
        },
        async (err) => {
          this.openMensajes("No se pudo realizar la acción", true, '');
          this.operacionRespuesta.EstaEjecutando = false;
        }
      );
  }

  eliminarSubCatalogoMaestro(idCatSubCatMaestro) {
    this.operacionRespuesta.EstaEjecutando = true;
    this.services.HttpDelete(this.modelo_configuracion.serviciosOperaciones + "/Catalogos/DeleteSubCatalogo?p_idcatsubcatmaestro=" + idCatSubCatMaestro)
      .subscribe(
        (tempdate) => {
          if (tempdate.response[0].success == 1) {
            this.openMensajes(tempdate.response[0].msg, false, '');
            this.obtenerSubCatalogosMaestros(this._idCatMaestro);
            this.modalEliminarSubCatalogo.close();
          } else {
            this.openMensajes(tempdate.response[0].msg, true, '');
          }
          this.operacionRespuesta.EstaEjecutando = false;
        },
        async (err) => {
          this.openMensajes("No se pudo realizar la acción", true, '');
          this.operacionRespuesta.EstaEjecutando = false;
        }
      );
  }

  openModalFormCatalogoMaestro(esEditable: boolean) {
    this.esEditable = esEditable;
    this.formGroupAgregarCatalogoMaestro.reset({ p_clave: '', p_nombre: '', p_descripcion: '' });

    this.modalAgregarCatalogo = this.modalService.open(this.modalAddColaborador, { ariaLabelledBy: 'modal-basic-title', backdrop: 'static', size: 'lg' });
  }

  openModalFormSubCatalogoMaestro(esEditable: boolean) {
    this.esEditable = esEditable;
    this.formGroupAgregarSubCatalogoMaestro.reset({ p_clave: '', p_nombre: '' });

    this.modalAgregarSubCatalogo = this.modalService.open(this.modalAddSubCatalogo, { ariaLabelledBy: 'modal-basic-title', backdrop: 'static', size: 'lg' });
  }

  openDesactivar(idCatMaestro, nombreCat) {
    this._idCatMaestro = idCatMaestro;
    this._nombreCat = nombreCat;
    this.modalEliminarCatalogo = this.modalService.open(this.ModalDesactivar, { ariaLabelledBy: 'modal-basic-title' });
  }

  openDesactivarSubCatalogo(idCatSubCatMaestro, nombreSubCat) {
    this._idCatSubCatMaestro = idCatSubCatMaestro;
    this._nombreSubCat = nombreSubCat;
    this.modalEliminarSubCatalogo = this.modalService.open(this.ModalDesactivarSubCat, { ariaLabelledBy: 'modal-basic-title' });
  }

  openMensajes(Mensaje: string, Error: boolean, titulo: string) {
    this.modalrefMsg = this.modalService.open(ModuloModalMensajeComponent, { ariaLabelledBy: "modal-basic-title" });
    this.modalrefMsg.componentInstance.mensajesExito = [];
    this.modalrefMsg.componentInstance.mensajesError = [];
    this.modalrefMsg.componentInstance.mensajeTitulo = 'Catálogo Maestro';

    if (Error) {
      this.modalrefMsg.componentInstance.showErrors = true;
      this.modalrefMsg.componentInstance.mensajesError.push(Mensaje);
    } else {
      this.modalrefMsg.componentInstance.showExitos = true;
      this.modalrefMsg.componentInstance.mensajesExito.push(Mensaje);
    }
  }
}
