import { CurrencyPipe } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { ThemeConstants } from 'src/app/@espire/shared/config/theme-constant';
import { RespuestaGenerica } from 'src/app/generales/RespuestaGenerica';
import { ServiciosRutas } from 'src/app/generales/ServiciosRutas';
import { AuthGuard } from 'src/app/guards/AuthGuard';
import { ModuloModalMensajeComponent } from 'src/app/shared/modulo-modal-mensaje/modulo-modal-mensaje.component';
import { Lista_general_corto_modelo_response } from 'src/model/Catalogos/Catalogos';
import { Lista_perfiles_modelo_response } from 'src/model/Catalogos/Generales';
import { Tarifa_modelo_response } from 'src/model/Catalogos/Tarifa';
import { Lista_usuarios_sistema_modelo_response, Usuario_sistema_modelo_response } from 'src/model/Catalogos/Usuarios';
import { ServiceGenerico } from 'src/services/service-generico.service';

@Component({
  selector: 'app-agregar-tarifas',
  templateUrl: './agregar-tarifas.component.html',
  styleUrls: ['./agregar-tarifas.component.css'],
  providers: [ServiceGenerico]
})
export class AgregarTarifasComponent implements OnInit {
  @Input() esEditable: boolean;
  @Input() iva: number;
  @Input() impuestoISH: number;
  @Input() _IDProveedor: number;
  @Input() IDTarifa: number;
  @Output() cerrarModal = new EventEmitter<boolean>();

  formGroupTarifaH: FormGroup;
  modelo_configuracion: ServiciosRutas;
  operacionRespuesta: RespuestaGenerica;
  listaST: Lista_general_corto_modelo_response[];
  listaTTAR: Lista_general_corto_modelo_response[];
  listaMON: Lista_general_corto_modelo_response[];
  listaPorPro: Lista_general_corto_modelo_response[];
  listaTIPOHAB: Lista_general_corto_modelo_response[];
  listaDA: Lista_general_corto_modelo_response[];
  listaTA_TAR: Lista_general_corto_modelo_response[];
  _idTarifa: number = 0;
  funcionalidad: string = 'Nuevo';
  modalrefMsg: NgbModalRef;
  Tarifa: Tarifa_modelo_response;

  constructor(
    private auth: AuthGuard,
    private themeConstants: ThemeConstants,
    private services: ServiceGenerico,
    private router: Router,
    private modalService: NgbModal,
    private fb: FormBuilder,
    private sanitizer: DomSanitizer,
  ) {
    this.modelo_configuracion = new ServiciosRutas();
    this.operacionRespuesta = new RespuestaGenerica();
   }

  //  ngOnChanges(changes: SimpleChanges) {
  //   // Verifica si la propiedad iva cambió
  //   if (changes.iva) {
  //     const nuevoValor = changes.iva.currentValue;
  //     this.formGroupTarifaH.get('p_taxMeal').setValue(nuevoValor);
  //   }
  // }

  ngOnInit() {
    this.inicializarFormTarifa();
    this.obtenerCatalogosCortos('ST', 0);
    this.obtenerCatalogosCortos('TTAR', 0);
    this.obtenerCatalogosCortos('MON', 0);
    this.obtenerCatalogosCortos('PORPRO', 0);
    this.obtenerCatalogosCortos('TIPOHAB', 0);
    this.obtenerCatalogosCortos('DA', 0);
    this.obtenerCatalogosCortos('TA_TAR', 0);
    this.calculaImpuestos();
    if (this.IDTarifa != 0) {
      this.verDatosTarifa();
    }
 }

 inicializarFormTarifa() {
    this.formGroupTarifaH = this.fb.group({
      p_tipoTarifa: [0, Validators.required],
      p_moneda: [0, Validators.required],
      p_situacionTarifa: [65],
      // p_comisionTotal: [0, [Validators.required, Validators.pattern(/^[0-9.]*$/)]],
      // p_comisionKetzal: [0, [Validators.required, Validators.pattern(/^[0-9.]*$/)]],
      p_vigenciaDesde: [""],
      p_vigenciaHasta: [""],
      p_tipoHabitacion: [0],
      p_EP_SGL: [0],
      p_EP_DBL: [0],
      p_EP_TPL: [0],
      p_EP_CDP: [0],
      p_EP_CHD: [0],
      p_EP_RR: [0],
      p_CB_SGL: [0],
      p_CB_DBL: [0],
      p_CB_TPL: [0],
      p_CB_CDP: [0],
      p_CB_CHD: [0],
      p_CB_RR: [0],
      p_AB_SGL: [0],
      p_AB_DBL: [0],
      p_AB_TPL: [0],
      p_AB_CDP: [0],
      p_AB_CHD: [0],
      p_AB_RR: [0],
      p_BB_SGL: [0],
      p_BB_DBL: [0],
      p_BB_TPL: [0],
      p_BB_CDP: [0],
      p_BB_CHD: [0],
      p_BB_RR: [0],
      p_MAP_SGL: [0],
      p_MAP_DBL: [0],
      p_MAP_TPL: [0],
      p_MAP_CDP: [0],
      p_MAP_CHD: [0],
      p_MAP_RR: [0],
      p_FAP_SGL: [0],
      p_FAP_DBL: [0],
      p_FAP_TPL: [0],
      p_FAP_CDP: [0],
      p_FAP_CHD: [0],
      p_FAP_RR: [0],
      p_SAI_SGL: [0],
      p_SAI_DBL: [0],
      p_SAI_TPL: [0],
      p_SAI_CDP: [0],
      p_SAI_CHD: [0],
      p_SAI_RR: [0],
      p_AI_SGL: [0],
      p_AI_DBL: [0],
      p_AI_TPL: [0],
      p_AI_CDP: [0],
      p_AI_CHD: [0],
      p_AI_RR: [0],
      p_menoresEdadD: [0],
      p_menoresEdadH: [0],
      p_bell: [0],
      p_maid: [0],
      p_diasAplica: [0],
      p_taxRoom: [0],
      p_impuestoIncluido: [0],
      // p_tcPreferencial: [0],
      p_IVA: [0],
      p_servicio: [0],
      // p_impuesto: [0],
      // p_descuento: [0],
      p_costoCB: [0],
      p_menorCB: [0],
      p_costoAB: [0],
      p_menorAB: [0],
      p_costoBB: [0],
      p_menorBB: [0],
      p_costoMAP: [0],
      p_menorMAP: [0],
      p_costoDD: [0],
      p_menorDD: [0],
      p_costoFAP: [0],
      p_menorFAP: [0],
      p_costoLL: [0],
      p_menorLL: [0],
      p_porPropina: [0],
      p_taxMeal: [0],
      p_impuestoIncluidoAlimentos: [0],
      p_tarifaPromocion: [0],
      p_codigoPromo: [""],
      p_vigenciaDesdeOtros: [null],
      p_vigenciaHastaOtros: [null],
      p_tarifaGrupal: [0],
      p_NP: [0],
      p_incluye: [""],
      p_excepto: [""],
      p_aplica: [""],
      p_nombreGrupo: [""]
    });

  }

  GuardarTarifaHospedaje(tipoDeGuardado: string) {
    this.operacionRespuesta.EstaEjecutando = true;
    let model = this.formGroupTarifaH.value;
    model.p_idTarifa = this.IDTarifa;
    model.p_idProveedor = this._IDProveedor;

    const _IVA = document.querySelector('input[id="p_IVA"]') as HTMLInputElement;
    if (_IVA.checked) {
      model.p_IVA = 1;
    } else {
      model.p_IVA = 0;
    }

    const _servicio = document.querySelector('input[id="p_servicio"]') as HTMLInputElement;
    if (_servicio.checked) {
      model.p_servicio = 1;
    } else {
      model.p_servicio = 0;
    }
    // const _impuesto = document.querySelector('input[id="p_impuesto"]') as HTMLInputElement;
    // if (_impuesto.checked) {
    //   model.p_impuesto = 1;
    // } else {
    //   model.p_impuesto = 0;
    // }

  //   const _impuestoIncluido = document.querySelector('input[id="p_impuestoIncluido"]') as HTMLInputElement;
  //  if (_impuestoIncluido.checked) {
  //     model.p_impuestoIncluido = 1;
  //   } else {
  //     model.p_impuestoIncluido = 0;
  //   }

  //   const _impuestoIncluidoAlimentos = document.querySelector('input[id="p_impuestoIncluidoAlimentos"]') as HTMLInputElement;
  //  if (_impuestoIncluidoAlimentos.checked) {
  //     model.p_impuestoIncluidoAlimentos = 1;
  //   } else {
  //     model.p_impuestoIncluidoAlimentos = 0;
  //   }
    const _tarifaPromocion = document.querySelector('input[id="p_tarifaPromocion"]') as HTMLInputElement;
   if (_tarifaPromocion.checked) {
      model.p_tarifaPromocion = 1;
    } else {
      model.p_tarifaPromocion = 0;
    }
    const _tarifaGrupal = document.querySelector('input[id="p_tarifaGrupal"]') as HTMLInputElement;
   if (_tarifaGrupal.checked) {
      model.p_tarifaGrupal = 1;
    } else {
      model.p_tarifaGrupal = 0;
    }
    const _NP = document.querySelector('input[id="p_NP"]') as HTMLInputElement;
   if (_NP.checked) {
      model.p_NP = 1;
    } else {
      model.p_NP = 0;
    }


    if (this.funcionalidad == 'Nuevo') {
      this.services.HttpPost(model, this.modelo_configuracion.serviciosOperaciones + "/Tarifas/PostTarifa")
        .subscribe(
          (tempdate) => {
            if (tempdate.response[0].success == 1) {
              this.openMensajes(tempdate.response[0].msg, false, '');
              if (tipoDeGuardado == 'GyS') {
                this._cerrarModal(true)
              }
            } else {
              this.openMensajes(tempdate.response[0].msg, true, '');
            }
            this.operacionRespuesta.EstaEjecutando = false;
          },
          async (err) => {
            this.openMensajes("No se pudo realizar la acción", true, '');
            this.operacionRespuesta.EstaEjecutando = false;
          }
        );
    } else {
      this.services.HttpPut(model, this.modelo_configuracion.serviciosOperaciones + "/Tarifas/PutTarifa")
        .subscribe(
          (tempdate) => {
            if (tempdate.response[0].success == 1) {
              this.openMensajes(tempdate.response[0].msg, false, '');
              this._cerrarModal(true)
              // this.modalAgregarCuponHospedaje.close();
            } else {
              this.openMensajes(tempdate.response[0].msg, true, '');
            }
            this.operacionRespuesta.EstaEjecutando = false;
          },
          async (err) => {
            this.openMensajes("No se pudo realizar la acción", true, '');
            this.operacionRespuesta.EstaEjecutando = false;
          }
        );
    }
  }

  obtenerCatalogosCortos(p_catalogo, p_idcatologo) {
    this.operacionRespuesta.EstaEjecutando = true;
    this.services
      .HttpGet(
        this.modelo_configuracion.serviciosOperaciones + "/Catalogos/GetListaGeneralCorto?p_catalogo="+ p_catalogo +"&p_idcatalogo="+ p_idcatologo)
      .subscribe(
        (tempdate) => {
          if (tempdate) {

            switch (p_catalogo) {
              case 'ST':
                this.listaST = [] = tempdate.response as Lista_general_corto_modelo_response[];
                break;
              case 'TTAR':
                this.listaTTAR = [] = tempdate.response as Lista_general_corto_modelo_response[];
                break;
              case 'MON':
                this.listaMON = [] = tempdate.response as Lista_general_corto_modelo_response[];
                break;
              case 'PORPRO':
                this.listaPorPro = [] = tempdate.response as Lista_general_corto_modelo_response[];
                break;
              case 'TIPOHAB':
                this.listaTIPOHAB = [] = tempdate.response as Lista_general_corto_modelo_response[];
                break;
              case 'DA':
                this.listaDA = [] = tempdate.response as Lista_general_corto_modelo_response[];
                break;
              case 'TA_TAR':
                this.listaTA_TAR = [] = tempdate.response as Lista_general_corto_modelo_response[];
                break;
            }

          }
          this.operacionRespuesta.EstaEjecutando = false;
        },
        async (err) => {
          this.operacionRespuesta.EstaEjecutando = false;
        }
      );
  }

  calculaImpuestos(){
    this.formGroupTarifaH.get('p_taxRoom').setValue(this.iva + this.impuestoISH);
    this.formGroupTarifaH.get('p_taxMeal').setValue(this.iva);
  }

  calculaImpyPro(event :any) {
    const _taxMeal = parseInt(event.target.value, 10);
    this.formGroupTarifaH.get('p_taxMeal').setValue(_taxMeal + this.iva);
  }

  selectImpuestoIncluido(event :any) {
    const checkboxValue = event.target.value;

    if (event.target.checked) {
      $('#p_impuestoIncluido').prop('checked', true);
      this.formGroupTarifaH.get('p_impuestoIncluido').setValue(1); 
      // this.formGroupTarifaH.get('p_taxRoom').setValue(0); 

    } else {
      $('#p_impuestoIncluido').prop('checked', false);
      this.formGroupTarifaH.get('p_impuestoIncluido').setValue(0);
      this.formGroupTarifaH.get('p_taxRoom').setValue(this.iva + this.impuestoISH); 
       }
  }
  selectImpuestoIncluidoAlimentos(event :any) {
    const checkboxValue = event.target.value;

    if (event.target.checked) {
      $('#p_impuestoIncluidoAlimentos').prop('checked', true);
      this.formGroupTarifaH.get('p_impuestoIncluidoAlimentos').setValue(1); 
      this.formGroupTarifaH.get('p_porPropina').setValue(0); 
      this.formGroupTarifaH.get('p_taxMeal').setValue(0); 

    } else {
      $('#p_impuestoIncluidoAlimentos').prop('checked', false);
      this.formGroupTarifaH.get('p_impuestoIncluidoAlimentos').setValue(0);
      this.formGroupTarifaH.get('p_taxMeal').setValue(this.iva); 
       }
  }
  selectNP(event :any) {
    const checkboxValue = event.target.value;

    if (event.target.checked) {
      $('#p_NP').prop('checked', true);
      this.formGroupTarifaH.value.p_tarifaGrupal = 1;
      this.formGroupTarifaH.value.p_NP = 1;
      this.formGroupTarifaH.patchValue(this.formGroupTarifaH.value);

      let valor = this.formGroupTarifaH.value.p_nombreGrupo;

      if (!valor.includes("NP - ")) {
        this.formGroupTarifaH.get('p_nombreGrupo').setValue("NP - " + this.formGroupTarifaH.value.p_nombreGrupo);
      }

    } else {
      $('#p_NP').prop('checked', false);
      this.formGroupTarifaH.value.p_tarifaGrupal = 0;
      this.formGroupTarifaH.value.p_NP = 0;
      this.formGroupTarifaH.patchValue(this.formGroupTarifaH.value);

      let valor = this.formGroupTarifaH.value.p_nombreGrupo;

      if (valor.includes("NP - ")) {
        this.formGroupTarifaH.get('p_nombreGrupo').setValue(valor = valor.replace("NP - ", ""));
      }
    }
  }
  soloNP(event :any) {
    const checkboxValue = event.target.value;

    if (event.target.checked) {
      this.formGroupTarifaH.value.p_NP = 1;
      this.formGroupTarifaH.patchValue(this.formGroupTarifaH.value);
     
      let valor = this.formGroupTarifaH.value.p_nombreGrupo;

      if (!valor.includes("NP - ")) {
        this.formGroupTarifaH.get('p_nombreGrupo').setValue("NP - " + this.formGroupTarifaH.value.p_nombreGrupo);
      }
    } else {
      this.formGroupTarifaH.value.p_NP = 0;
      this.formGroupTarifaH.patchValue(this.formGroupTarifaH.value);

      let valor = this.formGroupTarifaH.value.p_nombreGrupo;

      if (valor.includes("NP - ")) {
        this.formGroupTarifaH.get('p_nombreGrupo').setValue(valor = valor.replace("NP - ", ""));
      }
       }
  }

  _cerrarModal(estadoRegistroTarifa: boolean) {
    this.cerrarModal.emit(estadoRegistroTarifa);
  }

  verDatosTarifa() {
    this.funcionalidad = 'Editar';
    this.operacionRespuesta.EstaEjecutando = true;
    this.services.HttpGet(this.modelo_configuracion.serviciosOperaciones + "/Tarifas/GetTarifa_x_id?p_idTarifa=" + this.IDTarifa)
      .subscribe(
        (tempdate) => {
          if (tempdate) {
            this.Tarifa = tempdate.response as Tarifa_modelo_response;
            this.formGroupTarifaH.value.p_idTarifa = this.Tarifa[0].idTblTarifa;
            this.formGroupTarifaH.value.p_tipoTarifa = this.Tarifa[0].tipoTarifa;
            this.formGroupTarifaH.value.p_moneda = this.Tarifa[0].moneda;
            this.formGroupTarifaH.value.p_situacionTarifa = this.Tarifa[0].situacionTarifa;
            // this.formGroupTarifaH.value.p_comisionTotal = this.Tarifa[0].comision;
            // this.formGroupTarifaH.value.p_comisionKetzal = this.Tarifa[0].comisionKetzal;
            this.formGroupTarifaH.value.p_tarifaPromocion = this.Tarifa[0].tarifaPromo;
            const _tarifaPromocion = document.querySelector('input[id="p_tarifaPromocion"]') as HTMLInputElement;
            if (this.formGroupTarifaH.value.p_tarifaPromocion === 1) {
              _tarifaPromocion.checked = true;
            } else {
              _tarifaPromocion.checked = false;
            }
            this.formGroupTarifaH.value.p_codigoPromo = this.Tarifa[0].codigoPromo;
            this.formGroupTarifaH.value.p_incluye = this.Tarifa[0].incluye;
            this.formGroupTarifaH.value.p_excepto = this.Tarifa[0].excepto;
            this.formGroupTarifaH.value.p_aplica = this.Tarifa[0].aplica;
            const fechavigenciaDesde = this.Tarifa[0].vigenciaDesde.split('T')[0];
            const fechavigenciaHasta = this.Tarifa[0].vigenciaHasta.split('T')[0];
            this.formGroupTarifaH.value.p_vigenciaDesde = fechavigenciaDesde;
            this.formGroupTarifaH.value.p_vigenciaHasta = fechavigenciaHasta;
            this.formGroupTarifaH.value.p_tipoHabitacion = this.Tarifa[0].tipoHabitacion;
            this.formGroupTarifaH.value.p_nombreGrupo = this.Tarifa[0].txtGrupo;
            this.formGroupTarifaH.value.p_EP_SGL = this.Tarifa[0].eP_SGL;
            this.formGroupTarifaH.value.p_EP_DBL = this.Tarifa[0].eP_DBL;
            this.formGroupTarifaH.value.p_EP_TPL = this.Tarifa[0].eP_TPL;
            this.formGroupTarifaH.value.p_EP_CDP = this.Tarifa[0].eP_CDP;
            this.formGroupTarifaH.value.p_EP_CHD = this.Tarifa[0].eP_CHD;
            this.formGroupTarifaH.value.p_EP_RR = this.Tarifa[0].eP_RR;
            this.formGroupTarifaH.value.p_CB_SGL = this.Tarifa[0].cB_SGL;
            this.formGroupTarifaH.value.p_CB_DBL = this.Tarifa[0].cB_DBL;
            this.formGroupTarifaH.value.p_CB_TPL = this.Tarifa[0].cB_TPL;
            this.formGroupTarifaH.value.p_CB_CDP = this.Tarifa[0].cB_CDP;
            this.formGroupTarifaH.value.p_CB_CHD = this.Tarifa[0].cB_CHD;
            this.formGroupTarifaH.value.p_CB_RR = this.Tarifa[0].cB_RR;
            this.formGroupTarifaH.value.p_AB_SGL = this.Tarifa[0].aB_SGL;
            this.formGroupTarifaH.value.p_AB_DBL = this.Tarifa[0].aB_DBL;
            this.formGroupTarifaH.value.p_AB_TPL = this.Tarifa[0].aB_TPL;
            this.formGroupTarifaH.value.p_AB_CDP = this.Tarifa[0].aB_CDP;
            this.formGroupTarifaH.value.p_AB_CHD = this.Tarifa[0].aB_CHD;
            this.formGroupTarifaH.value.p_AB_RR = this.Tarifa[0].aB_RR;
            this.formGroupTarifaH.value.p_BB_SGL = this.Tarifa[0].bB_SGL;
            this.formGroupTarifaH.value.p_BB_DBL = this.Tarifa[0].bB_DBL;
            this.formGroupTarifaH.value.p_BB_TPL = this.Tarifa[0].bB_TPL;
            this.formGroupTarifaH.value.p_BB_CDP = this.Tarifa[0].bB_CDP;
            this.formGroupTarifaH.value.p_BB_CHD = this.Tarifa[0].bB_CHD;
            this.formGroupTarifaH.value.p_BB_CHD = this.Tarifa[0].bB_RR;
            this.formGroupTarifaH.value.p_MAP_SGL = this.Tarifa[0].maP_SGL;
            this.formGroupTarifaH.value.p_MAP_DBL = this.Tarifa[0].maP_DBL;
            this.formGroupTarifaH.value.p_MAP_TPL = this.Tarifa[0].maP_TPL;
            this.formGroupTarifaH.value.p_MAP_CDP = this.Tarifa[0].maP_CDP;
            this.formGroupTarifaH.value.p_MAP_CHD = this.Tarifa[0].maP_CHD;
            this.formGroupTarifaH.value.p_MAP_RR = this.Tarifa[0].maP_RR;
            this.formGroupTarifaH.value.p_FAP_SGL = this.Tarifa[0].faP_SGL;
            this.formGroupTarifaH.value.p_FAP_DBL = this.Tarifa[0].faP_DBL;
            this.formGroupTarifaH.value.p_FAP_TPL = this.Tarifa[0].faP_TPL;
            this.formGroupTarifaH.value.p_FAP_CDP = this.Tarifa[0].faP_CDP;
            this.formGroupTarifaH.value.p_FAP_CHD = this.Tarifa[0].faP_CHD;
            this.formGroupTarifaH.value.p_FAP_RR = this.Tarifa[0].faP_RR;
            this.formGroupTarifaH.value.p_SAI_SGL = this.Tarifa[0].saI_SGL;
            this.formGroupTarifaH.value.p_SAI_DBL = this.Tarifa[0].saI_DBL;
            this.formGroupTarifaH.value.p_SAI_TPL = this.Tarifa[0].saI_TPL;
            this.formGroupTarifaH.value.p_SAI_CDP = this.Tarifa[0].saI_CDP;
            this.formGroupTarifaH.value.p_SAI_CHD = this.Tarifa[0].saI_CHD;
            this.formGroupTarifaH.value.p_SAI_RR = this.Tarifa[0].saI_RR;
            this.formGroupTarifaH.value.p_AI_SGL = this.Tarifa[0].aI_SGL;
            this.formGroupTarifaH.value.p_AI_DBL= this.Tarifa[0].aI_DBL;
            this.formGroupTarifaH.value.p_AI_TPL = this.Tarifa[0].aI_TPL;
            this.formGroupTarifaH.value.p_AI_CDP = this.Tarifa[0].aI_CDP;
            this.formGroupTarifaH.value.p_AI_CHD = this.Tarifa[0].aI_CHD;
            this.formGroupTarifaH.value.p_AI_RR = this.Tarifa[0].aI_RR;
            this.formGroupTarifaH.value.p_menoresEdadD = this.Tarifa[0].menoresEdadD;
            this.formGroupTarifaH.value.p_menoresEdadH = this.Tarifa[0].menoresEdadH;
            this.formGroupTarifaH.value.p_bell = this.Tarifa[0].bell;
            this.formGroupTarifaH.value.p_maid = this.Tarifa[0].maid;
            this.formGroupTarifaH.value.p_diasAplica = this.Tarifa[0].diasAplica;
            this.formGroupTarifaH.value.p_taxRoom = this.Tarifa[0].taxRoom;
            // this.formGroupTarifaH.value.p_tcPreferencial = this.Tarifa[0].tcPreferencial;
            this.formGroupTarifaH.value.p_IVA = this.Tarifa[0].iva;
            this.formGroupTarifaH.value.p_servicio = this.Tarifa[0].servicio;
            // this.formGroupTarifaH.value.p_impuesto = this.Tarifa[0].impuesto;
            this.formGroupTarifaH.value.p_impuestoIncluido = this.Tarifa[0].impuestoIncluido;
            const _IVA = document.querySelector('input[id="p_IVA"]') as HTMLInputElement;
            if (this.formGroupTarifaH.value.p_IVA === 1) {
              _IVA.checked = true;
            } else {
              _IVA.checked = false;
            }
            const _servicio = document.querySelector('input[id="p_servicio"]') as HTMLInputElement;
            if (this.formGroupTarifaH.value.p_servicio === 1) {
              _servicio.checked = true;
            } else {
              _servicio.checked = false;
            }
            // const _impuesto = document.querySelector('input[id="p_impuesto"]') as HTMLInputElement;
            // if (this.formGroupTarifaH.value.p_impuesto === 1) {
            //   _impuesto.checked = true;
            // } else {
            //   _impuesto.checked = false;
            // }
            const _impuestoIncluido = document.querySelector('input[id="p_impuestoIncluido"]') as HTMLInputElement;
            if (this.formGroupTarifaH.value.p_impuestoIncluido === 1) {
              _impuestoIncluido.checked = true;
            } else {
              _impuestoIncluido.checked = false;
            }

            // this.formGroupTarifaH.value.p_descuento = this.Tarifa[0].descuento;
            this.formGroupTarifaH.value.p_costoCB = this.Tarifa[0].costoCB;
            this.formGroupTarifaH.value.p_menorCB = this.Tarifa[0].menorCB;
            this.formGroupTarifaH.value.p_costoAB = this.Tarifa[0].costoAB;
            this.formGroupTarifaH.value.p_menorAB = this.Tarifa[0].menorAB;
            this.formGroupTarifaH.value.p_costoBB = this.Tarifa[0].costoBB;
            this.formGroupTarifaH.value.p_menorBB = this.Tarifa[0].menorBB;
            this.formGroupTarifaH.value.p_costoMAP = this.Tarifa[0].costoMAP;
            this.formGroupTarifaH.value.p_menorMAP = this.Tarifa[0].menorMAP;
            this.formGroupTarifaH.value.p_costoDD = this.Tarifa[0].costoDD;
            this.formGroupTarifaH.value.p_menorDD = this.Tarifa[0].menorDD;
            this.formGroupTarifaH.value.p_costoFAP = this.Tarifa[0].costoFAP;
            this.formGroupTarifaH.value.p_menorFAP = this.Tarifa[0].menorFAP;
            this.formGroupTarifaH.value.p_porPropina = this.Tarifa[0].porPropina;
            this.formGroupTarifaH.value.p_taxMeal = this.Tarifa[0].taxMeal;
            this.formGroupTarifaH.value.p_impuestoIncluidoAlimentos = this.Tarifa[0].impuestoIncluidoAlimentos;
            const _impuestoIncluidoAlimentos = document.querySelector('input[id="p_impuestoIncluidoAlimentos"]') as HTMLInputElement;
            if (this.formGroupTarifaH.value.p_impuestoIncluidoAlimentos === 1) {
              _impuestoIncluidoAlimentos.checked = true;
            } else {
              _impuestoIncluidoAlimentos.checked = false;
            }
            if (this.Tarifa[0].vigenciaDesdeOtros !== null) {
              const fechavigenciaDesdeOtros = this.Tarifa[0].vigenciaDesdeOtros.split('T')[0];
              const fechavigenciaHastaOtros = this.Tarifa[0].vigenciaHastaOtros.split('T')[0];
              this.formGroupTarifaH.value.p_vigenciaDesdeOtros = fechavigenciaDesdeOtros;
              this.formGroupTarifaH.value.p_vigenciaHastaOtros = fechavigenciaHastaOtros;
             }
    
           
            this.formGroupTarifaH.value.p_tarifaGrupal = this.Tarifa[0].tarifaGrupal;
            const _tarifaGrupal = document.querySelector('input[id="p_tarifaGrupal"]') as HTMLInputElement;
            if (this.formGroupTarifaH.value.p_tarifaGrupal === 1) {
              _tarifaGrupal.checked = true;
            } else {
              _tarifaGrupal.checked = false;
            }
            this.formGroupTarifaH.value.p_NP = this.Tarifa[0].np;
            const _NP = document.querySelector('input[id="p_NP"]') as HTMLInputElement;
            if (this.formGroupTarifaH.value.p_NP === 1) {
              _NP.checked = true;
            } else {
              _NP.checked = false;
            }
            this.formGroupTarifaH.patchValue(this.formGroupTarifaH.value);
          } else {
            // this.listaCupones = [];
          }
          this.operacionRespuesta.EstaEjecutando = false;
        },
        async (err) => {
          this.operacionRespuesta.EstaEjecutando = false;
        }
      );
  }
  openMensajes(Mensaje: string, Error: boolean, titulo: string) {
  this.modalrefMsg = this.modalService.open(ModuloModalMensajeComponent, { ariaLabelledBy: "modal-basic-title" });
  this.modalrefMsg.componentInstance.mensajesExito = [];
  this.modalrefMsg.componentInstance.mensajesError = [];
  this.modalrefMsg.componentInstance.mensajeTitulo = 'Cupón';

  if (Error) {
    this.modalrefMsg.componentInstance.showErrors = true;
    this.modalrefMsg.componentInstance.mensajesError.push(Mensaje);
  } else {
    this.modalrefMsg.componentInstance.showExitos = true;
    this.modalrefMsg.componentInstance.mensajesExito.push(Mensaje);
  }
}

}
