import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { ThemeConstants } from 'src/app/@espire/shared/config/theme-constant';
import { RespuestaGenerica } from 'src/app/generales/RespuestaGenerica';
import { ServiciosRutas } from 'src/app/generales/ServiciosRutas';
import { AuthGuard } from 'src/app/guards/AuthGuard';
import { ModuloModalMensajeComponent } from 'src/app/shared/modulo-modal-mensaje/modulo-modal-mensaje.component';
import { Lista_catalogo_corto_modelo_response, Lista_general_corto_modelo_response } from 'src/model/Catalogos/Catalogos';
import { Lista_proveedores_modelo_response, Proveedor_modelo_response } from 'src/model/Catalogos/Proveedores';
import { ServiceGenerico } from 'src/services/service-generico.service';
import { AgregarTarifasComponent } from './agregar-tarifas/agregar-tarifas.component';

@Component({
  selector: 'app-capturatarifas',
  templateUrl: './capturatarifas.component.html',
  styleUrls: ['./capturatarifas.component.css'],
  providers: [ServiceGenerico]
})
export class CapturatarifasComponent implements OnInit {
  @ViewChild("modalAddTarifa", { static: false }) modalAddTarifa: TemplateRef<any>;
  @ViewChild(AgregarTarifasComponent , { static: false }) agregarTarifasComponent: AgregarTarifasComponent;

  modalAgregarTarifa: NgbModalRef;

  private modelo_configuracion: ServiciosRutas;
  public operacionRespuesta: RespuestaGenerica;
  listaProveedores: Lista_proveedores_modelo_response[];
  esEditable: boolean;
  listadoTarifa: number = 0;
  funcionalidad: string;
  _bndEsEditable: boolean;
  IDProveedor: number = 0;
  IDTarifa: number = 0;
  impuestosProveedor: any;
  iva: number = 0;
  impuestoISH: number = 0;

  constructor(
    private auth: AuthGuard,
    private services: ServiceGenerico,
    private router: Router,
    private modalService: NgbModal,
    private fb: FormBuilder,
    private sanitizer: DomSanitizer,
  ) { 
    this.modelo_configuracion = new ServiciosRutas();
    this.operacionRespuesta = new RespuestaGenerica();
  }

  ngOnInit() {

  }
 
regresarListado() {
  this.listadoTarifa = 0;
}

agregarTarifa(IDProveedor) {
  this.funcionalidad = 'Nuevo';
  this._bndEsEditable = true;
this.IDTarifa = 0;
  this.consultaImpuestosProveedor();

  this.modalAgregarTarifa = this.modalService.open(this.modalAddTarifa, { ariaLabelledBy: 'modal-basic-title', backdrop: 'static', size: 'xl' });

}

consultaImpuestosProveedor() {
  this.services
  .HttpGet(
    this.modelo_configuracion.serviciosOperaciones + "/Proveedores/GetImpuestos_x_Proveedores?p_IDProveedor=" + this.IDProveedor)
  .subscribe(
    (tempdate) => {
      if (tempdate) {
        this.impuestosProveedor = tempdate.response[0];
        this.iva = this.impuestosProveedor.iva;
        this.impuestoISH = this.impuestosProveedor.impuestoISH;
      } else {
        this.impuestosProveedor = [];
      }
      this.operacionRespuesta.EstaEjecutando = false;
    },
    async (err) => {
      this.operacionRespuesta.EstaEjecutando = false;
    }
  );

}



habilitarCampo(valor: number): void {
  this.listadoTarifa = 1;
  this.IDProveedor = valor;
}
closeModal(valor: boolean): void {
  this.modalAgregarTarifa.close();

  this.listadoTarifa = 2;
  setTimeout(() => {
    this.listadoTarifa = 1; 
  }, 50);
}

modificarTarifa(IDTarifa: number) {
  this.funcionalidad = 'Editar';
  this._bndEsEditable = true;
  this.IDTarifa = IDTarifa;
  this.modalAgregarTarifa = this.modalService.open(this.modalAddTarifa, { ariaLabelledBy: 'modal-basic-title', backdrop: 'static', size: 'xl' });
}



}
