import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { DataTablesModule } from 'angular-datatables';
import { AppComponent } from './app.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { HomeComponent } from './home/home.component';
import { CounterComponent } from './counter/counter.component';
import { FetchDataComponent } from './fetch-data/fetch-data.component';
import { LoginComponent } from './login/login/login.component';
import { MenuComponent } from './menu/menu.component';
import { AppRoutingModule } from './app.routing';
import { AuthGuard } from "../app/guards/AuthGuard";
import { JwtModule } from '@auth0/angular-jwt';
import { ModuloModalMensajeComponent } from './shared/modulo-modal-mensaje/modulo-modal-mensaje.component';
import { ModuloModalAdvertenciaComponent } from './shared/modulo-modal-advertencia/modulo-modal-advertencia.component';
import { AuthService } from 'src/services/auth.service';
import { ThemeConstants } from './@espire/shared/config/theme-constant';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ModuloSpinerLoginComponent } from './shared/modulo-spiner-login/modulo-spiner-login.component';
import { ModuloMensajesComponent } from './shared/modulo-mensajes/modulo-mensajes.component';
import { BodyComponent } from './body/body.component';
import { MaestrosComponent } from './catalogos/maestros/maestros.component';
import { GruposComponent } from './catalogos/grupos/grupos.component';
import { ProveedoresComponent } from './catalogos/proveedores/proveedores.component';
import { PaisesComponent } from './catalogos/paises/paises.component';
import { EstadosComponent } from './catalogos/estados/estados.component';
import { CiudadesComponent } from './catalogos/ciudades/ciudades.component';
import { CategoriasComponent } from './catalogos/categorias/categorias.component';
import { ClientesComponent } from './catalogos/clientes/clientes.component';
import { CapturatarifasComponent } from './operaciones/capturatarifas/capturatarifas.component';
import { AutorizartarifahospComponent } from './operaciones/autorizartarifahosp/autorizartarifahosp.component';
import { GenerartarifariohospComponent } from './operaciones/generartarifariohosp/generartarifariohosp.component';
import { HospedajeComponent } from './cupones/hospedaje/hospedaje.component';
import { TransportadoraComponent } from './cupones/transportadora/transportadora.component';
import { OtrosComponent } from './cupones/otros/otros.component';
import { ReportesComponent } from './cupones/reportes/reportes.component';
import { UsuariosComponent } from './usuarios/usuarios.component';
import { ModuloCambioContrasenaComponent } from './login/modulo-cambio-contrasena/modulo-cambio-contrasena.component';
import { TodosComponent } from './cupones/todos/todos.component';
import { ListadoTarifasComponent } from './operaciones/capturatarifas/listado-tarifas/listado-tarifas.component';
import { AgregarTarifasComponent } from './operaciones/capturatarifas/agregar-tarifas/agregar-tarifas.component';
import { registerLocaleData } from '@angular/common';
import localeEsMX from '@angular/common/locales/es-MX'; // Importa los datos de localización para es-MX
import { ListadoProveedoresComponent } from './operaciones/capturatarifas/listado-proveedores/listado-proveedores.component';

/* jwt getToken */
export function obtenerToken(): string {
  return localStorage.getItem("jwt");
}

@NgModule({
  declarations: [
    AppComponent,
    NavMenuComponent,
    BodyComponent,
    CounterComponent,
    FetchDataComponent,
    LoginComponent,
    MenuComponent,
    ModuloSpinerLoginComponent,
    ModuloModalMensajeComponent,
    ModuloModalAdvertenciaComponent,
    ModuloMensajesComponent,
    MaestrosComponent,
    HomeComponent,
    GruposComponent,
    ProveedoresComponent,
    PaisesComponent,
    EstadosComponent,
    CiudadesComponent,
    CategoriasComponent,
    ClientesComponent,
    CapturatarifasComponent,
    AutorizartarifahospComponent,
    GenerartarifariohospComponent,
    HospedajeComponent,
    TransportadoraComponent,
    OtrosComponent,
    ReportesComponent,
    UsuariosComponent,
    ModuloCambioContrasenaComponent,
    TodosComponent,
    ListadoTarifasComponent,
    AgregarTarifasComponent,
    ListadoProveedoresComponent
   ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    FormsModule,
    AppRoutingModule,
    ReactiveFormsModule,
    DataTablesModule,
    NgbModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: obtenerToken,
        // whitelistedDomains: ["localhost:44394"],
        whitelistedDomains: ["ketzaltour.api.duranm.me"],
      },
    }),
  ],
  entryComponents: [
    ModuloModalMensajeComponent
  ],
  providers: [AuthGuard, ThemeConstants, AuthService],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor() {
    registerLocaleData(localeEsMX, 'es-MX');

  }
 }

