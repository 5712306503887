import { NgModule } from "@angular/core";

import { Routes, RouterModule } from "@angular/router";
import { CounterComponent } from "./counter/counter.component";
import { FetchDataComponent } from "./fetch-data/fetch-data.component";

// Layouts

import { AuthGuard } from "./guards/AuthGuard";
import { HomeComponent } from "./home/home.component";
import { MenuComponent } from "./menu/menu.component";
import { LoginComponent } from "./login/login/login.component";
import { ModuloCambioContrasenaComponent } from "./login/modulo-cambio-contrasena/modulo-cambio-contrasena.component";
// import { LoginComponent } from "./login/login.component";
// import { ModuloCambioContrasenaComponent } from "./login/modulo-cambio-contrasena/modulo-cambio-contrasena.component";

const routes: Routes = [
  { path: 'login', component: LoginComponent, pathMatch: 'full' },
  { path: '', component: MenuComponent },
  { path: 'recuperar-contrasena/:token', component: ModuloCambioContrasenaComponent },
  { path: "**", redirectTo: "/" },
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
