import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-modulo-spiner-login',
  templateUrl: './modulo-spiner-login.component.html',
  styleUrls: ['./modulo-spiner-login.component.css']
})
export class ModuloSpinerLoginComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
